'use client';

import * as ModalPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';

import { cn } from '../../utilities';
import { Icon } from '../icon';

const ModalRoot = ModalPrimitive.Root;

const ModalTrigger = ModalPrimitive.Trigger;

const ModalPortal = ModalPrimitive.Portal;

const ModalClose = ModalPrimitive.Close;

const ModalOverlay = React.forwardRef<
	React.ElementRef<typeof ModalPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			'fixed inset-0 z-50 bg-[#0000007a] backdrop-blur-[3px] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
			className
		)}
		{...props}
	/>
));
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName;

const ModalContent = React.forwardRef<
	React.ElementRef<typeof ModalPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof ModalPrimitive.Content> & {
		noCrossButton?: boolean;
	}
>(({ className, children, noCrossButton, ...props }, ref) => (
	<ModalPortal>
		<ModalOverlay />
		<ModalPrimitive.Content
			ref={ref}
			className={cn(
				`fixed
				left-[50%]
				top-[50%]
				z-50
				grid
				w-full
				translate-x-[-50%]
				translate-y-[-50%]
				gap-4
				bg-background
				p-6
				shadow-lg
				rounded-lg
				duration-200
				data-[state=open]:animate-in
				data-[state=closed]:animate-out
				data-[state=closed]:fade-out-0
				data-[state=open]:fade-in-0
				data-[state=closed]:zoom-out-95
				data-[state=open]:zoom-in-95
				data-[state=closed]:slide-out-to-left-1/2
				data-[state=closed]:slide-out-to-top-[48%]
				data-[state=open]:slide-in-from-left-1/2
				data-[state=open]:slide-in-from-top-[48%]
				sm:rounded-lg',
				${className}`
			)}
			{...props}
		>
			{children as React.ReactElement}
			{!noCrossButton && (
				<ModalPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-neutrals-text-primary">
					<Icon name="Cross" />
					<span className="sr-only">Close</span>
				</ModalPrimitive.Close>
			)}
		</ModalPrimitive.Content>
	</ModalPortal>
));
ModalContent.displayName = ModalPrimitive.Content.displayName;

const ModalHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn('flex flex-col space-y-1.5 text-center sm:text-left', className)} {...props} />
);
ModalHeader.displayName = 'ModalHeader';

const ModalFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />
);
ModalFooter.displayName = 'ModalFooter';

const ModalTitle = React.forwardRef<
	React.ElementRef<typeof ModalPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ className, ...props }, ref) => (
	<ModalPrimitive.Title
		ref={ref}
		className={cn('text-lg font-bold leading-none tracking-tight text-neutrals-text-primary', className)}
		{...props}
	/>
));
ModalTitle.displayName = ModalPrimitive.Title.displayName;

const ModalDescription = React.forwardRef<
	React.ElementRef<typeof ModalPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ className, ...props }, ref) => (
	<ModalPrimitive.Description
		ref={ref}
		className={cn('text-base text-neutrals-text-secondary', className)}
		{...props}
	/>
));
ModalDescription.displayName = ModalPrimitive.Description.displayName;

export const Modal = Object.assign(ModalRoot, {
	Portal: ModalPortal,
	Overlay: ModalOverlay,
	Close: ModalClose,
	Trigger: ModalTrigger,
	Content: ModalContent,
	Header: ModalHeader,
	Footer: ModalFooter,
	Title: ModalTitle,
	Description: ModalDescription,
});

export {
	ModalPortal,
	ModalOverlay,
	ModalClose,
	ModalTrigger,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalTitle,
	ModalDescription,
};
