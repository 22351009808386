import { LegacyStackSynthesizer } from 'aws-cdk-lib';
import * as jwt from 'jsonwebtoken';
import { v4 as uuid } from 'uuid';

import { AppError, AttributionChecker } from '@voyage-lab/core-common';
import { Constants, DatabaseEntity } from '@voyage-lab/db';
import { ShopifyTypes } from '@voyage-lab/shopify-api';
import { Helpers } from '@voyage-lab/util';

import { BaseIntegrationProvider } from '../../base';
import type { CommonArgs, EventArgs, IdentifyArgs, IdentifyReturn, ResourceArgs } from '../../provider';
import { BigCommerceResource } from './resource';
import { AuthParams, LoadJwtPayload, LoadParams, LoginEntity, Store, WebhookPayload } from './types';

export class BigCommerce extends BaseIntegrationProvider {
	static override id = Constants.Integration.BigCommerceIntegrationId;
	static override type = 'bigcommerce' as const;

	API_URL = 'https://api.bigcommerce.com';
	LOGIN_URL = 'https://login.bigcommerce.com';
	API_VERSION = 'v3' as const;

	/* This is defined from BigCommerce Dev Portal */
	static APP_SCOPES = [] as const;
	static WEBHOOK_TOPICS = ['store/cart/*', 'store/order/*', 'store/customer/*'] as const;

	override async auth({ params }: { params: AuthParams }) {
		const request: RequestInit = {
			method: 'POST',
			headers: {
				'User-Agent': 'vyg.ai/1.0',
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip, deflate',
			},
			body: JSON.stringify({
				client_id: this.credentials.clientId,
				client_secret: this.credentials.clientSecret,
				redirect_uri: this.credentials.authCallbackUrl,
				grant_type: 'authorization_code',
				code: params.code,
				scope: params.scope,
				context: params.context,
			}),
		};

		const response = await fetch(`${this.LOGIN_URL}/oauth2/token`, request);
		const session: LoginEntity = await response.json();

		console.info(`BigCommerce -> auth -> session \n`, JSON.stringify({ session }));

		if (!session.context) throw new Error('No store hash found');
		if (!session.access_token) throw new Error('No access token found');

		const store = await fetch(`${this.API_URL}/${session.context}/v2/store`, {
			headers: {
				'User-Agent': 'vyg.ai/1.0',
				'X-Auth-Token': session.access_token,
				Accept: 'application/json',
			},
		});

		const storeData = (await store.json()) as Store;
		console.info(`BigCommerce -> auth -> store \n`, JSON.stringify({ storeData }));

		return await super.auth({
			data: {
				brand: {
					integrationId: BigCommerce.id,
					lookupId: session.context,
				},
				lead: {
					email: session.owner?.email || session.user.email,
					first_name: storeData.first_name,
					last_name: storeData.last_name,
					// phone: storeData.phone,
					id: session.store_hash || '',
					annual_revenue: storeData.industry?.toLowerCase() === 'retail' ? 1000000 : 500000,
					source_name: 'bigcommerce_store',
					source_type: BigCommerce.type,
					tenant_id: null,
					updated_at: new Date().toISOString(),
					user_id: null,
					store_type: BigCommerce.type,
					store_url: storeData.domain,
					support_email: storeData.admin_email,
					support_phone: storeData.phone,
					company_name: storeData.name,
					company_website: storeData.domain,
					billing_email: storeData.admin_email,
					brand_id: storeData.logo.url,
					created_at: new Date().toISOString(),
					extra_data: {
						installation_source: 'bigcommerce',
						bigcommerce: {
							store: storeData,
							session: session,
						},
					},
				},
			},
		});
	}

	override async open(props: CommonArgs & { params: LoadParams }) {
		// Validation
		const jwtData = jwt.verify(props.params.signed_payload_jwt, this.credentials.clientSecret) as LoadJwtPayload;

		const providerUser = await this.accountData.getProviderUser({
			provider: 'bigcommerce',
			integrationId: BigCommerce.id,
			lookupId: jwtData.sub,
			userId: jwtData.user.id.toString(),
		});

		// if (!providerUser.data) throw new Error('Failed to find the user: ' + JSON.stringify(providerUser));
		// Create the user if it doesn't exist
		if (!providerUser.data) {
			throw new Error('Failed to find the user: ' + JSON.stringify(providerUser));
			// const userCreateRes = await this.accountData.createUser({
			// 	data: {
			// 		email: jwtData.user.email,
			// 	},
			// });
		}

		return await super.open({
			data: {
				brand: {
					lookupId: jwtData.sub,
					integrationId: BigCommerce.id,
				},
				user: providerUser.data,
			},
		});
	}

	override async handleEvent(props: EventArgs) {
		// Initializations
		const event: WebhookPayload = props.event;
		const lookupId = event.producer;
		const eventDataId = event.data.id ?? null;
		console.log(`BigCommerce -> handleEvent -> ${event.data.type} -> ${eventDataId} \n`, JSON.stringify({ props }));

		// Validations
		if (!lookupId || !eventDataId) {
			const error = new Error(`BigCommerce -> handleEvent -> invalid event \n`);
			console.error(error, JSON.stringify({ props }));
			throw error;
		}

		switch (event.data.type) {
			case 'cart': {
				// Initializations
				const checkoutRes = await this.resource({ integration: props.integration }).getCheckout({
					id: eventDataId,
				});

				// Validations
				if (!checkoutRes.checkout) throw new Error('Checkout not found');

				// Identifcation
				const contacts = Helpers.Contact.dedupe(
					Helpers.Object.sortKeys(Helpers.Contact.capture(checkoutRes), 'len_asc')
				);
				const cleanedCheckoutRes = Helpers.Contact.clean(checkoutRes);
				let initiatedContact: Awaited<ReturnType<typeof this.contactData.initiate>> | undefined;

				try {
					initiatedContact = await this.contactData.initiate({
						channels: Object.entries(contacts).map(([key, value]) => ({
							username: value,
							extra_data: {
								source: key,
								timezone: '',
							},
						})),
						contact: {
							family_name:
								checkoutRes.checkout.customer?.last_name ||
								checkoutRes.checkout.billing_address?.last_name ||
								checkoutRes.checkout.shipping_address?.last_name,
							given_name:
								checkoutRes.checkout.customer?.first_name ||
								checkoutRes.checkout.billing_address?.first_name ||
								checkoutRes.checkout.shipping_address?.first_name,
							brand_id: props.integration.brand_id,
							external_id: String(checkoutRes.checkout.customer?.id || ''),
							source: 'bigcommerce',
						},
					});
				} catch (error) {
					if (error instanceof AppError) {
						console.warn(
							`BigCommerce -> handleEvent -> cart -> initiate contact -> warning \n`,
							JSON.stringify({ error })
						);
					} else {
						console.error(
							new Error(`BigCommerce -> handleEvent -> cart -> initiate contact -> error \n`),
							JSON.stringify({ error })
						);
					}
				}

				const cartEvent = {
					detail: {
						payload: cleanedCheckoutRes.checkout,
					},
				} as DatabaseEntity['checkouts']['cleaned_raw_data'];

				const abandonedCartUrl = '';
				const orderId = cartEvent?.detail?.payload?.order?.id;
				let cartState: DatabaseEntity['carts']['state'] = 'pending';

				if (orderId) {
					const orderRes = await this.resource({ integration: props.integration }).getOrder({
						id: String(orderId),
					});

					const orderStatusId = +(
						orderRes.order.note_attributes?.find((note) => note?.name === 'status_id')?.value || 0
					);
					cartState = orderStatusId >= 10 ? 'complete' : 'pending';
				}

				const upsertedCartRes = await this.dataClient
					.from('carts')
					.upsert(
						{
							external_id: eventDataId,
							brand_integration_id: props.integration.id,
							updated_at: new Date().toISOString(),
							state: cartState,
							extra_data: {
								from_webhook: true,
								abandoned_checkout_url: abandonedCartUrl,
							},
							total: +(checkoutRes.checkout.total_price ?? 0),
							contact_id: initiatedContact?.contact?.id,
							cleaned_raw_data: cartEvent as unknown as DatabaseEntity['carts']['cleaned_raw_data'],
						},
						{ onConflict: 'brand_integration_id,external_id' }
					)
					.select('id')
					.maybeSingle();

				console.info(
					`BigCommerce -> handleEvent -> cart -> upsertedCart \n`,
					JSON.stringify({ upsertedCartRes })
				);
				console.debug(
					`BigCommerce -> handleEvent -> cart -> upserted \n`,
					JSON.stringify({ cart: upsertedCartRes.data })
				);
				return super.handleEvent(props);
			}
			case 'order':
				{
					// Initializations
					const orderRes = await this.resource({ integration: props.integration }).getOrder({
						id: eventDataId,
					});

					// Validations
					if (!orderRes.order) throw new Error('Order not found');

					// Identifcation
					const contacts = Helpers.Contact.dedupe(
						Helpers.Object.sortKeys(Helpers.Contact.capture(orderRes), 'len_asc')
					);
					const cleanedOrderRes = Helpers.Contact.clean(orderRes);

					const initiateContactRes = await this.contactData.initiate({
						channels: Object.entries(contacts).map(([key, value]) => ({
							username: value,
							extra_data: {
								source: key,
								timezone: '',
							},
						})),
						contact: {
							family_name:
								orderRes.order.customer?.last_name ||
								orderRes.order.billing_address?.last_name ||
								orderRes.order.shipping_address?.last_name,
							given_name:
								orderRes.order.customer?.first_name ||
								orderRes.order.billing_address?.first_name ||
								orderRes.order.shipping_address?.first_name,
							external_id: String(orderRes.order.customer?.id || ''),
							brand_id: props.integration.brand_id,
							source: 'bigcommerce',
						},
					});

					const externalCartId = orderRes.order.cart_token;
					if (!externalCartId) throw new Error('Cart token not found');

					const orderStatusId = +(
						cleanedOrderRes.order?.note_attributes?.find((note) => note?.name === 'status_id')?.value || 0
					);
					const isOrderComplete = orderStatusId >= 10;

					const updatedCartRes = await this.dataClient
						.from('carts')
						.update({
							state: isOrderComplete ? 'complete' : 'pending',
						})
						.eq('external_id', externalCartId)
						.eq('brand_integration_id', props.integration.id)
						.select('*')
						.maybeSingle();

					console.info(
						`BigCommerce -> handleEvent -> order -> updatedCart \n`,
						JSON.stringify({ updatedCartRes })
					);

					const cartObj = updatedCartRes?.data?.cleaned_raw_data.detail
						?.payload as unknown as ShopifyTypes.ICheckout;
					const cartDiscountCodes = cartObj?.discount_codes;
					if (cartDiscountCodes && cleanedOrderRes.order)
						cleanedOrderRes.order.discount_codes = cartDiscountCodes;

					const orderEvent = {
						detail: {
							payload: cleanedOrderRes.order,
						},
					} as DatabaseEntity['orders']['cleaned_raw_data'];

					const orderToUpsert: DatabaseEntity<'insert'>['orders'] = {
						id: uuid(),
						brand_integration_id: props.integration.id,
						cart_id: updatedCartRes?.data?.id || null,
						cleaned_raw_data: orderEvent as unknown as DatabaseEntity['orders']['cleaned_raw_data'],
						contact_id: initiateContactRes?.contact?.id,
						created_at: new Date().toISOString(),
						external_id: String(eventDataId),
						number: eventDataId,
						state: isOrderComplete ? 'complete' : 'pending',
						total: +(orderRes.order.total_price ?? 0),
						updated_at: new Date().toISOString(),
					};

					if (orderToUpsert.state === 'complete') {
						const attributionChecker = new AttributionChecker({ dataClient: this.dataClient });
						const attribution = await attributionChecker.check({
							event: orderToUpsert,
							integration: props.integration,
							channels: initiateContactRes.channels || [],
							cart: updatedCartRes?.data,
							brand: props.integration.brands,
						});

						const goal = attribution?.goal;
						const conversation = attribution?.conversation;

						if (goal) {
							// Insert the workflow goal
							orderToUpsert.billing_status = 'billable';
							orderToUpsert.recovered_at = new Date().toISOString();
							const flowId = conversation?.workflow_id; // TODO: Get flow id from conversation
							const conversationId = conversation?.id; // TODO: Get conversation id from conversation

							if (flowId && conversationId) {
								await this.dataClient
									.from('workflow_goals')
									.insert({
										...attribution,
										brand_id: props.integration.brand_id,
										created_at: new Date().toISOString(),
										updated_at: new Date().toISOString(),
										id: uuid(),
										workflow_id: flowId,
										conversation_id: conversationId,
									})
									.select('id')
									.maybeSingle();

								// Add workflow event
								await this.dataClient
									.from('workflow_goal_state_change_events')
									.insert({
										created_at: new Date().toISOString(),
										updated_at: new Date().toISOString(),
										id: uuid(),
										state: 'converted',
										cart_id: updatedCartRes?.data?.id,
										order_id: orderToUpsert.id,
										workflow_id: flowId,
										conversation_id: conversationId,
									})
									.select('id')
									.maybeSingle();
							}
						}
					}

					const upsertedOrderRes = await this.dataClient
						.from('orders')
						.upsert(orderToUpsert, { onConflict: 'brand_integration_id,external_id' })
						.select('id')
						.maybeSingle();

					console.debug(
						`BigCommerce -> handleEvent -> order -> upserted \n`,
						JSON.stringify({ order: upsertedOrderRes.data })
					);
				}
				break;
			case 'customer':
				break;
		}
		return super.handleEvent(props);
	}
	override resource(args: ResourceArgs) {
		if (args.integration.integration_id !== BigCommerce.id) {
			throw new Error(
				`Invalid integration id, got ${args.integration.integration_id} expected ${BigCommerce.id}`
			);
		}

		const storeHash = args.integration.settings?.credentials?.store_hash;
		if (!storeHash) throw new Error('Store hash not found');
		const accessToken = args.integration.settings?.credentials?.access_token;
		if (!accessToken) throw new Error('Access token not found');

		return new BigCommerceResource({
			provider: this,
			fetch: BaseIntegrationProvider.createFetch({
				headers: {
					'X-Auth-Token': accessToken,
					'User-Agent': 'vyg.ai/1.0',
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				url: `${this.API_URL}/${storeHash}/${args.apiVersion ?? this.API_VERSION}`,
			}),
		});
	}

	override async identify(args: IdentifyArgs): Promise<IdentifyReturn> {
		const eventData = args.event as
			| DatabaseEntity['orders']['cleaned_raw_data']
			| DatabaseEntity['carts']['cleaned_raw_data'];

		if (!eventData) throw new Error('Event data not found');

		// return await super.identify({ data: eventData })
		throw new Error('Not implemented');
	}
}
