import { Array } from './array';
import { browserCookieStorage } from './browser-cookie-storage';
import { browserLocalStorage } from './browser-local-storage';
import { browserSessionStorage } from './browser-session-storage';
import { Calcs } from './calcs';
import { Contact } from './contact';
import { Csv } from './csv';
import { Number } from './number';
import { Object } from './object';
import { Phone } from './phone';
import { String } from './string';
import { Time } from './time';

export const Helpers = {
	Array,
	browserCookieStorage: browserCookieStorage,
	browserLocalStorage: browserLocalStorage,
	browserSessionStorage: browserSessionStorage,
	Calcs,
	Contact,
	Csv,
	Number,
	Object,
	Phone,
	String,
	Time,
};
