import { Helpers } from '@voyage-lab/util';

import { DATE_RANGE_OPTIONS } from '../constants/date';

export const rangePicker = (dateRange: (typeof DATE_RANGE_OPTIONS)[number]['label']) => {
	const range = DATE_RANGE_OPTIONS.find((d) => d.label === dateRange);
	const arr = range?.range() || DATE_RANGE_OPTIONS[0].range();
	return {
		from: arr[0] || null,
		to: arr[1] || null,
	};
};

export const formatReadableTime = (dateStr: string) => {
	const date = new Date(dateStr);

	const isToday = Helpers.Time.isToday(date);
	if (isToday) {
		const time = Helpers.Time.format(date, 'h:mm a');
		return `Today ${time}`;
	}

	const isYesterday = Helpers.Time.isYesterday(date);
	if (isYesterday) {
		const time = Helpers.Time.format(date, 'h:mm a');
		return `Yesterday ${time}`;
	}

	const isThisYear = Helpers.Time.isThisYear(date);
	if (isThisYear) {
		const time = Helpers.Time.format(date, 'MMM d');
		return time;
	}

	return Helpers.Time.format(date, 'MMM d, yyyy');
};
