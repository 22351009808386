'use client';
import { createRealtimeClient } from '@voyage-lab/core-common';
import type { MqttClient, MqttClientEventCallbacks } from 'mqtt/build/mqtt';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ulid } from 'ulid';
import type { ZodSchema } from 'zod';
import { useAuth } from '../auth';

type RealtimeClientStatus = 'connected' | 'connecting' | 'disconnected' | 'disconnecting';
type RealtimeClientContextValue = {
	client: MqttClient | null;
	status: RealtimeClientStatus;
	reconnectCount: number;
	subscriptions: Map<string, number>;
	setStatus: (status: RealtimeClientStatus) => void;
	addSubscription: (topic: string) => void;
	removeSubscription: (topic: string) => void;
};

/** Context. */
const RealtimeClientContext = createContext<RealtimeClientContextValue>({
	client: null,
	status: 'disconnected',
	reconnectCount: 0,
	subscriptions: new Map(),
	setStatus: () => null,
	addSubscription: () => null,
	removeSubscription: () => null,
});

/** Provider */
export const RealtimeContextProvider = ({ children, url }: { children: React.ReactNode; url: string }) => {
	const session = useAuthSession();
	const [status, setStatus] = useState<RealtimeClientStatus>('disconnected');
	const [subscriptions, setSubscriptions] = useState<Map<string, number>>(new Map());
	const prevSubscriptionsRef = useRef<Map<string, number>>(new Map());

	if (!url) throw new Error('[realtime] URL is required');

	const client = useMemo(() => {
		if (!session) return null;

		const uri = new URL(url);
		uri.searchParams.set('token', session.token);
		uri.searchParams.set('clientId', `web:users.id:${session.userId}`);
		const identifiedUrl = uri.toString();

		return createRealtimeClient(identifiedUrl);
	}, [session]);

	const addSubscription = useCallback((topic: string) => {
		setSubscriptions((prev) => {
			const newSubs = new Map(prev);
			const count = newSubs.get(topic) || 0;
			newSubs.set(topic, count + 1);
			return newSubs;
		});
	}, []);

	const removeSubscription = useCallback((topic: string) => {
		setSubscriptions((prev) => {
			const newSubs = new Map(prev);
			const count = newSubs.get(topic);
			if (count !== undefined) {
				if (count <= 1) {
					newSubs.delete(topic);
				} else {
					newSubs.set(topic, count - 1);
				}
			}
			return newSubs;
		});
	}, []);

	// Connect or disconnect the client based on subscriptions
	useEffect(() => {
		if (!client) return;

		if (subscriptions.size > 0 && status === 'disconnected') {
			console.debug('[realtime] connecting client');
			setStatus('connecting');
			client.connect();
		} else if (subscriptions.size === 0 && client.connected) {
			console.debug('[realtime] disconnecting client');
			setStatus('disconnecting');
			client.end();
		}
	}, [client, subscriptions.size, status]);

	// Handle subscriptions updates
	useEffect(() => {
		if (!client || status !== 'connected') return;

		const prevSubs = prevSubscriptionsRef.current;
		const currentSubs = subscriptions;

		// Subscribe to new topics
		for (const [topic, count] of currentSubs.entries()) {
			if (!prevSubs.has(topic)) {
				client.subscribe(topic, { qos: 1 }, (err) => {
					if (err) console.error(`[realtime] subscribing to topic ${topic}:`, err);
					else console.debug(`[realtime] subscribed to topic ${topic}`);
				});
			}
		}

		// Unsubscribe from topics that are no longer subscribed
		for (const topic of prevSubs.keys()) {
			if (!currentSubs.has(topic)) {
				client.unsubscribe(topic, (err) => {
					if (err && !err.message.includes('client disconnecting'))
						console.error(`[realtime] unsubscribing from topic ${topic}:`, err);
					else console.debug(`[realtime] unsubscribed from topic ${topic}`);
				});
			}
		}

		// Update the previous subscriptions
		prevSubscriptionsRef.current = new Map(currentSubs);
	}, [client, status, subscriptions]);

	// Retain EVENT_TO_STATUS mapping
	useEffect(() => {
		if (!client) return;

		const handleEvent = (eventName: keyof MqttClientEventCallbacks) => () => {
			const status = EVENT_TO_STATUS[eventName];
			if (status) {
				console.debug('[realtime] event', eventName, status);
				setStatus(status);
			}
		};

		// Attach event listeners
		for (const event in EVENT_TO_STATUS) {
			const eventName = event as keyof MqttClientEventCallbacks;
			client.on(eventName, handleEvent(eventName));
		}

		// Cleanup event listeners on unmount
		return () => {
			for (const event in EVENT_TO_STATUS) {
				const eventName = event as keyof MqttClientEventCallbacks;
				client.off(eventName, handleEvent(eventName));
			}
		};
	}, [client]);

	return (
		<RealtimeClientContext.Provider
			value={{
				client,
				status,
				reconnectCount: client?._reconnectCount ?? 0,
				subscriptions,
				setStatus,
				addSubscription,
				removeSubscription,
			}}
		>
			{children}
		</RealtimeClientContext.Provider>
	);
};

type PublishFunction<T> = (message: T, options?: { topic?: string }) => Promise<number | null>;

type MessageDetails<T> = {
	topic: string;
	id?: string;
	message?: T | null;
};

type UseRealtimeMessageReturn<T> = [MessageDetails<T>, PublishFunction<T>, RealtimeClientContextValue];

/**
 * A custom hook for subscribing to and handling real-time messages from a specific topic.
 *
 * @template T - The shape of the message data
 * @param options - Configuration options for the hook
 * @param options.topic - The topic to subscribe to
 * @param [options.schema] - Optional Zod schema for message validation
 *
 * @returns A tuple containing:
 * 1. The last received message (or null if none received)
 * 2. A function to publish messages
 * 3. Details about the last received message
 *
 * @example
 * // Without schema
 * const [message, publish, details] = useRealtimeMessage<{ content: string }>({
 *   topic: 'chat/messages'
 * });
 *
 * // With schema
 * const messageSchema = z.object({ content: z.string(), timestamp: z.number() });
 * const [message, publish, details] = useRealtimeMessage({
 *   topic: 'chat/messages',
 *   schema: messageSchema
 * });
 *
 * // Usage
 * useEffect(() => {
 *   if (message) {
 *     console.log(`New message received: ${message.content}`);
 *   }
 * }, [message]);
 *
 * const sendMessage = () => {
 *   publish({ content: 'Hello, world!', timestamp: Date.now() });
 * };
 */
export function useRealtime<T = unknown>({
	topic,
	schema,
}: {
	topic: string;
	schema?: ZodSchema<T>;
}): UseRealtimeMessageReturn<T> {
	const ctx = useRealtimeCtx();
	const { client } = ctx;

	const [message, setMessage] = useState<MessageDetails<T>>({
		topic,
	});

	const [isSubscribed, setIsSubscribed] = useState(false);
	const hasAccessedMessageRef = useRef(false);

	// Subscribe
	useEffect(() => {
		if (isSubscribed) {
			ctx.addSubscription(topic);
			return () => {
				ctx.removeSubscription(topic);
			};
		}
	}, [topic, isSubscribed]);

	// Handle incoming messages
	useEffect(() => {
		if (!client || !isSubscribed) return;

		const handleMessage = (receivedTopic: string, payload: Buffer) => {
			// Use the custom topicMatches function to check if the received topic matches the subscribed topic
			if (!topicMatches(topic, receivedTopic)) return;

			const messageId = ulid();
			let parsedMessage: T;
			const rawMessage = payload.toString('utf8');
			console.debug('[realtime] message received on topic:', receivedTopic, rawMessage);

			try {
				parsedMessage = JSON.parse(rawMessage);
				if (schema) parsedMessage = schema.parse(parsedMessage);
			} catch (error) {
				console.warn('[realtime] invalid message format', error);
				return;
			}

			setMessage({ message: parsedMessage, topic: receivedTopic, id: messageId });
		};

		client.on('message', handleMessage);

		return () => {
			client.off('message', handleMessage);
		};
	}, [client, topic, schema, isSubscribed]);

	const publish: PublishFunction<T> = useCallback(
		async (message, options) => {
			console.log('[realtime] publish', { message, ctx });
			if (schema) {
				const parseResult = schema.safeParse(message);
				if (!parseResult.success) {
					console.warn('[realtime] message is in invalid format', parseResult.error);
					return null;
				}
				message = parseResult.data;
			}

			if (ctx.status !== 'connected') {
				console.warn('[realtime] client is not connected, status:', ctx.status);
				return null;
			}

			const payload = JSON.stringify(message);
			const publishedMessage = await client?.publishAsync(options?.topic ?? topic, payload, { qos: 1 });
			return publishedMessage?.messageId ?? null;
		},
		[ctx.status, topic, client]
	);

	// Use an effect to check if the message has been accessed
	useEffect(() => {
		if (hasAccessedMessageRef.current && !isSubscribed) {
			setIsSubscribed(true);
		}
	}, [isSubscribed]);

	// Wrap the returned message in a Proxy to detect access
	const messageProxy = useMemo(() => {
		return new Proxy(message, {
			get(target, prop) {
				if (!hasAccessedMessageRef.current) {
					hasAccessedMessageRef.current = true;
					// Schedule a state update to trigger a re-render
					setTimeout(() => setIsSubscribed(true), 0);
				}
				return target[prop as keyof MessageDetails<T>];
			},
		});
	}, [message]);

	return [messageProxy, publish, ctx];
}

/********* Helper Hooks ************/
const useAuthSession = () => {
	const auth = useAuth({ optional: true });
	const authToken = auth.data?.token || '';

	return useMemo(() => auth.data, [authToken]);
};

/**
 * A hook for accessing the realtime client context.
 * @returns The realtime client context
 * @throws If the hook is used outside of a realtime
 */
export function useRealtimeCtx() {
	const ctx = useContext(RealtimeClientContext);
	if (!ctx) throw new Error('[realtime] `useRealtimeCtx` must be used within a `RealtimeContextProvider`');

	return ctx;
}

// Update the EVENT_TO_STATUS mapping
const EVENT_TO_STATUS: Record<keyof MqttClientEventCallbacks, RealtimeClientStatus | undefined> = {
	connect: 'connected',
	reconnect: 'connecting',
	close: 'disconnected',
	offline: 'disconnected',
	end: 'disconnected',
	error: undefined,
	disconnect: 'disconnecting',
	message: undefined,
	packetsend: undefined,
	packetreceive: undefined,
	outgoingEmpty: undefined,
};

// Add this helper function at the end of the file or in a separate utilities file
function topicMatches(subscribedTopic: string, receivedTopic: string): boolean {
	const subParts = subscribedTopic.split('/');
	const recParts = receivedTopic.split('/');

	if (subParts.length > recParts.length) return false;

	for (let i = 0; i < subParts.length; i++) {
		if (subParts[i] === '#') return true;
		if (subParts[i] !== '+' && subParts[i] !== recParts[i]) return false;
	}

	return subParts.length === recParts.length;
}
