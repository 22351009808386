'use client';

import { Helpers } from '@voyage-lab/util';

import { rangePicker } from '../utils/date';

export const DATE_RANGE_OPTIONS = [
	{
		label: 'Today' as const,
		range: () => {
			const now = new Date();
			return [Helpers.Time.startOfDay(now), Helpers.Time.endOfDay(now)];
		},
	},
	{
		label: 'Yesterday' as const,
		range: () => {
			const yesterday = Helpers.Time.getYesterday();
			return [Helpers.Time.startOfDay(yesterday), Helpers.Time.endOfDay(yesterday)];
		},
	},
	{
		label: 'Last 7 Days' as const,
		range: () => {
			const today = new Date();
			return [
				Helpers.Time.startOfDay(Helpers.Time.addDays(today, -7)),
				Helpers.Time.endOfDay(Helpers.Time.addDays(today, -1)),
			];
		},
	},
	{
		label: 'Last 30 Days' as const,
		range: () => {
			const today = new Date();
			return [
				Helpers.Time.startOfDay(Helpers.Time.addDays(today, -30)),
				Helpers.Time.endOfDay(Helpers.Time.addDays(today, -1)),
			];
		},
	},
	{
		label: 'This Month' as const,
		range: () => {
			const start = Helpers.Time.startOfMonth(new Date());
			const end = Helpers.Time.endOfMonth(new Date());
			return [start, end];
		},
	},
	{
		label: 'Last Month' as const,
		range: () => {
			const start = Helpers.Time.startOfMonth(Helpers.Time.addMonths(new Date(), -1));
			const end = Helpers.Time.endOfMonth(Helpers.Time.addMonths(new Date(), -1));
			return [start, end];
		},
	},
	{
		label: 'This Year' as const,
		range: () => {
			const start = Helpers.Time.startOfYear(new Date());
			const end = Helpers.Time.endOfYear(new Date());
			return [start, end];
		},
	},
	{
		label: 'Last Year' as const,
		range: () => {
			const start = Helpers.Time.startOfYear(Helpers.Time.addYears(new Date(), -1));
			const end = Helpers.Time.endOfYear(Helpers.Time.addYears(new Date(), -1));
			return [start, end];
		},
	},
];

export const DEFAULT_DATE_RANGE = {
	dashboard: rangePicker('Last 30 Days'),
	conversation: rangePicker('Last 30 Days'),
	workflow: rangePicker('Last 30 Days'),
};
