import { v4 as uuid } from 'uuid';

import { Constants } from '@voyage-lab/db';
import type { ShopifyTypes } from '@voyage-lab/shopify-api';
import { DeepPartial, Helpers } from '@voyage-lab/util';

import type { CommonArgs, EventArgs, ExtendedFetch, IdentifyArgs, IdentifyReturn } from './provider';
import { IntegrationProvider, IntegrationResourceProvider } from './provider';

export abstract class BaseIntegrationProvider extends IntegrationProvider {
	static override type = 'base';

	override async auth(props: CommonArgs) {
		// Validation
		if (!props.data?.brand?.lookupId) throw new Error('No data provided');

		const providerUserRes = await this.accountData.getProviderUser({
			lookupId: props.data.brand.lookupId,
			integrationId: props.data.brand.integrationId,
		});
		const brandIntegration = providerUserRes.integration;

		// If the data is not found we can create a lead and start onboarding
		if (!providerUserRes.data?.id) {
			if (!props.data.lead) throw new Error('No lead data provided');

			if (props.data.lead.id) {
				console.debug('Lead already exists, deep-merge, update and return');
				const lead = await this.accountData.getSingle({ leadId: props.data.lead.id });
				if (!lead) throw new Error(`Failed to fetch lead leads.id -> ${props.data.lead.id}`);

				const updatedLead = Helpers.Object.deepMerge(props.data.lead, lead);
				// TODO: Properly use zodSchema inside .update()
				// @ts-expect-error: TODO: fix this
				delete updatedLead.brands;
				// @ts-expect-error: TODO: fix this
				delete updatedLead.tenants;
				// @ts-expect-error: TODO: fix this
				delete updatedLead.users;

				const updatedLeadRes = await this.accountData.update({
					data: updatedLead,
				});

				if (!updatedLeadRes.data) throw new Error(`Failed to update lead leads.id -> ${props.data.lead.id}`);
				return { data: { lead: updatedLeadRes.data } };
			}

			const leadRes = await this.accountData.create({
				data: { ...props.data.lead, id: uuid() },
				allowDuplicateEmail: true,
			});

			if (!leadRes.data) throw new Error(`Failed to create lead: ${leadRes.error.message}`);

			return {
				data: { lead: leadRes.data },
			};
		}

		// TODO: Refactor and remove shopify related logic by introducing Provider.reauth()
		if (brandIntegration?.id && props.data.lead?.extra_data?.shopify_access_token) {
			console.debug('User already exists and has new session, we can reauth');
			await this.integrationData.patch({
				data: {
					id: brandIntegration.id,
					integration_id: Constants.Integration.ShopifyIntegrationId,
					status: 'connected',
					is_enabled: true,
					settings: {
						credentials: {
							access_token: props.data.lead.extra_data.shopify_access_token,
							scopes: props.data.lead.extra_data.shopify?.session?.scope?.split(','),
						},
					},
				},
			});
		}

		return this.open({
			params: props.params,
			data: {
				user: providerUserRes.data,
				brand: {
					integrationId: props.data.brand.integrationId,
					lookupId: props.data.brand.lookupId,
				},
			},
		});
	}

	override async open(props: CommonArgs) {
		// if (props.rawResponse)
		// 	return {
		// 		rawResponse: props.rawResponse,
		// 		data: props.data,
		// 	};

		// Validation
		if (!props.data?.brand?.lookupId || !props.data.brand.integrationId)
			throw new Error('Your account is not connected to a brand, please contact support');
		// if (!props.data.user?.auth?.provider || !props.data.user?.auth?.[props.data.user.auth.provider]?.user_id)
		// 	throw new Error('Your account is not connected to a user, please contact support');

		if (!props.data.user) throw new Error('No user data provided');
		// const integrationRes = await this.integrationData.getSingleBi({
		// 	lookupId: props.data.brand.lookupId,
		// });

		// const userRes = await this.accountData.getProviderUser({
		// 	provider: props.data.user.auth.provider,
		// 	userId: props.data.user.auth?.[props.data.user.auth.provider]?.user_id as string,
		// });

		// This is a new user, but from authorized provider so we can create a new user
		// if (!userRes.data && integrationRes.data) {
		// 	const bi = integrationRes.data.brand_integrations?.at?.(0);
		// 	if (!bi) throw new Error('Your account is not connected to a brand, please contact support');
		// 	const userCreateRes = await this.accountData.createUser({
		// 		data: {
		// 			...props.data.user,
		// 			tenant_id: bi.brands.tenant_id,
		// 		},
		// 	});

		// 	if (!userCreateRes.data) throw new Error('There was an error creating your user, please contact support');
		// 	props.data.user = userCreateRes.data;
		// }

		const authRes = await this.authData.login({
			id: props.data.user.id,
			provider: props.data.user.auth?.provider,
		});

		return {
			redirect: authRes.refreshToken
				? `/auth/redirect?${new URLSearchParams({ token: Helpers.String.toBase64(authRes) }).toString()}`
				: undefined,
			data: {
				user: props.data.user,
			},
		};
	}

	override async identify(args: IdentifyArgs): Promise<IdentifyReturn> {
		if (!args.data) throw new Error('No data provided');

		return await this.contactData.initiate(args.data);
	}

	override async handleEvent(props: EventArgs) {
		return { data: { success: true } };
	}

	override uninstall(): Promise<void> {
		return Promise.resolve();
	}

	static createFetch({ headers, url }: { headers: Record<string, string>; url: string }) {
		const fetcher: ExtendedFetch = async (input, init) => {
			// Handle data passed in init or as part of Request object
			const data = init?.data;
			const body = data ? JSON.stringify(data) : init?.body;

			// Build request options
			const requestInit: RequestInit = {
				...init,
				headers: {
					...headers,
					...init?.headers,
				},
				body,
			};

			// Make request
			const path = typeof input === 'string' ? input : input instanceof URL ? input.href : input.url;
			if (init?.apiVersion) {
				const [defaultVerssion, overrideVersion] = init.apiVersion.split(':');
				url = url.replace(defaultVerssion, overrideVersion);
			}

			const fullUrl = path.startsWith('http') ? path : `${url}${path}`;
			const response = await fetch(fullUrl, requestInit);

			// Only try to parse JSON if content-type indicates JSON response
			let responseData;
			const contentType = response.headers.get('content-type');
			if (contentType?.includes('application/json')) {
				try {
					const clonedResponse = response.clone();
					responseData = await clonedResponse.json();
				} catch (e) {
					console.error(new Error('Failed to parse JSON response'), {
						status: response.status,
						statusText: response.statusText,
						contentType,
					});
				}
			}

			// Return response with optional parsed data
			return Object.assign(response, { data: responseData });
		};

		return fetcher;
	}
}

export class BaseIntegrationResourceProvider extends IntegrationResourceProvider {
	override async getCheckout(args: { id?: string }) {
		return Promise.resolve({} as DeepPartial<{ checkout: ShopifyTypes.ICheckout }>);
	}

	override async getOrder(args: { id?: string }) {
		return Promise.resolve({} as DeepPartial<{ order: ShopifyTypes.IOrder }>);
	}

	override async getCustomer(args: { id?: string }): Promise<DeepPartial<ShopifyTypes.QueryRoot>> {
		return Promise.resolve({} as DeepPartial<ShopifyTypes.QueryRoot>);
	}

	override async getCart(args: { id?: string }): Promise<DeepPartial<ShopifyTypes.QueryRoot>> {
		return Promise.resolve({} as DeepPartial<ShopifyTypes.QueryRoot>);
	}

	override async syncHooks(): Promise<DeepPartial<ShopifyTypes.QueryRoot>> {
		return Promise.resolve({} as DeepPartial<ShopifyTypes.QueryRoot>);
	}

	override async getProduct(args: { id?: string }): Promise<DeepPartial<{ product: ShopifyTypes.QueryRoot }>> {
		return Promise.resolve({} as DeepPartial<{ product: ShopifyTypes.QueryRoot }>);
	}

	override async getVariant(args: { id?: string }): Promise<DeepPartial<{ variant: ShopifyTypes.QueryRoot }>> {
		return Promise.resolve({} as DeepPartial<{ variant: ShopifyTypes.QueryRoot }>);
	}

	override async getCoupon(args: { id?: string }): Promise<DeepPartial<{ coupon: ShopifyTypes.QueryRoot }>> {
		return Promise.resolve({} as DeepPartial<{ coupon: ShopifyTypes.QueryRoot }>);
	}

	override async createCoupon(args: {
		data: DeepPartial<ShopifyTypes.DiscountCodeBasicCreatePayload>;
	}): Promise<DeepPartial<{ coupon: ShopifyTypes.DiscountCodeBasic }>> {
		return Promise.resolve({} as DeepPartial<{ coupon: ShopifyTypes.DiscountCodeBasic }>);
	}
}
