const gql = String.raw;

export const WEBHOOK_DELETE = gql`
	mutation webhookSubscriptionDelete($id: ID!) {
		webhookSubscriptionDelete(id: $id) {
			userErrors {
				field
				message
			}
			deletedWebhookSubscriptionId
		}
	}
`;

export const WEBHOOK_CREATE = gql`
	mutation webhookSubscriptionCreate(
		$topic: WebhookSubscriptionTopic!
		$webhookSubscription: WebhookSubscriptionInput!
	) {
		webhookSubscriptionCreate(topic: $topic, webhookSubscription: $webhookSubscription) {
			webhookSubscription {
				id
				topic
				filter
				format
				endpoint {
					__typename
					... on WebhookHttpEndpoint {
						callbackUrl
					}
				}
			}
			userErrors {
				field
				message
			}
		}
	}
`;

export const WEBHOOK_UPDATE = gql`
	mutation WebhookSubscriptionUpdate($id: ID!, $webhookSubscription: WebhookSubscriptionInput!) {
		webhookSubscriptionUpdate(id: $id, webhookSubscription: $webhookSubscription) {
			userErrors {
				field
				message
			}
			webhookSubscription {
				id
				topic
				endpoint {
					__typename
					... on WebhookHttpEndpoint {
						callbackUrl
					}
					... on WebhookEventBridgeEndpoint {
						arn
					}
					... on WebhookPubSubEndpoint {
						pubSubProject
						pubSubTopic
					}
				}
				apiVersion {
					handle
				}
				format
			}
		}
	}
`;

export const WEBHOOK_CREATE_ARN = gql`
	mutation webhookSubscriptionCreate(
		$topic: WebhookSubscriptionTopic!
		$webhookSubscription: EventBridgeWebhookSubscriptionInput!
	) {
		eventBridgeWebhookSubscriptionCreate(topic: $topic, webhookSubscription: $webhookSubscription) {
			webhookSubscription {
				id
				topic
				filter
				format
				endpoint {
					__typename
					... on WebhookHttpEndpoint {
						callbackUrl
					}
				}
			}
			userErrors {
				field
				message
			}
		}
	}
`;

export const WEBHOOK_UPDATE_ARN = gql`
	mutation WebhookSubscriptionUpdate($id: ID!, $webhookSubscription: EventBridgeWebhookSubscriptionInput!) {
		eventBridgeWebhookSubscriptionUpdate(id: $id, webhookSubscription: $webhookSubscription) {
			userErrors {
				field
				message
			}
			webhookSubscription {
				id
				topic
				endpoint {
					__typename
					... on WebhookHttpEndpoint {
						callbackUrl
					}
					... on WebhookEventBridgeEndpoint {
						arn
					}
					... on WebhookPubSubEndpoint {
						pubSubProject
						pubSubTopic
					}
				}
				apiVersion {
					handle
				}
				format
			}
		}
	}
`;
