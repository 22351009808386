import type { LucideProps } from 'lucide-react';

export const List = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M11.8667 14.5C12.6134 14.5 12.9868 14.5 13.272 14.3547C13.5229 14.2269 13.7268 14.0229 13.8547 13.772C14 13.4868 14 13.1134 14 12.3667V11.9667C14 11.22 14 10.8466 13.8547 10.5614C13.7268 10.3105 13.5229 10.1065 13.272 9.9787C12.9868 9.83337 12.6134 9.83337 11.8667 9.83337L4.13333 9.83337C3.3866 9.83337 3.01323 9.83337 2.72801 9.9787C2.47713 10.1065 2.27316 10.3105 2.14532 10.5614C2 10.8466 2 11.22 2 11.9667L2 12.3667C2 13.1134 2 13.4868 2.14532 13.772C2.27316 14.0229 2.47713 14.2269 2.72801 14.3547C3.01323 14.5 3.3866 14.5 4.13333 14.5L11.8667 14.5Z"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.8667 7.16667C12.6134 7.16667 12.9868 7.16667 13.272 7.02134C13.5229 6.89351 13.7268 6.68954 13.8547 6.43865C14 6.15344 14 5.78007 14 5.03333V4.63333C14 3.8866 14 3.51323 13.8547 3.22801C13.7268 2.97713 13.5229 2.77316 13.272 2.64533C12.9868 2.5 12.6134 2.5 11.8667 2.5L4.13333 2.5C3.3866 2.5 3.01323 2.5 2.72801 2.64532C2.47713 2.77316 2.27316 2.97713 2.14532 3.22801C2 3.51323 2 3.8866 2 4.63333L2 5.03333C2 5.78007 2 6.15344 2.14532 6.43865C2.27316 6.68954 2.47713 6.89351 2.72801 7.02134C3.01323 7.16667 3.3866 7.16667 4.13333 7.16667L11.8667 7.16667Z"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
