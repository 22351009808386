import _defaultsDeep from 'lodash/defaultsDeep';

import * as keys from './key';
import * as parse from './parse';
import * as set from './set';

/**
 * Merges two objects deeply, with the second object taking precedence.
 *
 * @param obj - The object to merge into.
 * @param data - The object to merge from.
 * @returns The merged object.
 */
function deepMerge<TObj1 extends object, TObj2 extends object>(obj: TObj1, data: TObj2) {
	return _defaultsDeep(data, obj) as TObj1 & TObj2;
}

export const Object = {
	...set,
	...parse,
	deepMerge,
	...keys,
};
