import { v4 as uuid } from 'uuid';

import { Constants, type DatabaseEntity, type DatabaseEnum } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/schema';
import { Helpers } from '@voyage-lab/util';

import type {
	Flow,
	FlowProcessorArg,
	FlowProcessorCandidate,
	FlowProcessorExecuteCandidate,
	FlowProcessorProcessResult,
} from './processor';
import { FlowProcessor } from './processor';

export class FlowProcessorTap extends FlowProcessor {
	static override type: DatabaseEnum['t_workflows_type'] = 'tap_to_text';

	// Not needed for tap to text i.e: No rules to process
	override async process({
		flows,
		data,
	}: {
		flows: Flow[];
		data: FlowProcessorCandidate;
	}): Promise<FlowProcessorProcessResult> {
		return null;
	}

	// To do: get the sms url & initial sms & redirect
	async execute({ flow, data }: { flow: Flow; data: FlowProcessorExecuteCandidate }) {
		// Validations
		if (!flow) throw new Error('No flow has been determined');

		// To do: implement
	}

	override async executeAction({ flow, data }: { flow: Flow; data: FlowProcessorExecuteCandidate }) {
		// to do: implement
		const actionResult = {
			agentReply: flow.action.agents_response,
		};

		return actionResult;
	}

	override async getCandidates() {
		//To do get candidates
		return [];
	}
}
