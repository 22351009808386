// sort-imports-ignore
import { createAdminApiClient } from '@shopify/admin-api-client';
import '@shopify/shopify-api/adapters/web-api';

import { BaseIntegrationResourceProvider } from '../../base';
import { ResourceProviderArgs } from '../../provider';

import type { ShopifyTypes } from '@voyage-lab/shopify-api';

export class ShopifyResource extends BaseIntegrationResourceProvider {
	client: ReturnType<typeof createAdminApiClient>;
	constructor(args: ResourceProviderArgs & { client: ReturnType<typeof createAdminApiClient> }) {
		super(args);
		this.client = args.client;
	}
	override async getCustomer(args: { id?: string }): Promise<ShopifyTypes.QueryRoot> {
		const response = await this.client.request<ShopifyTypes.QueryRoot>('getCustomer', {
			variables: {
				id: args.id,
			},
		});

		if (!response.data) {
			console.error(new Error('Failed to get customer'), {
				response,
			});
			throw new Error('Failed to get customer');
		}

		return response.data;
	}
}
