import { cva } from 'class-variance-authority';

import { cn } from '../../utilities';
import type { IconProps } from '../icon';
import { Icon } from '../icon';
import { Spinner } from '../spinner';
import type { SpinnerProps } from '../spinner/spinner.types';
import type { ButtonProps } from './button.types';

const buttonVariantOptions = {
	variants: {
		/** Variant */
		variant: {
			primary: 'bg-primary text-[white] shadow',
			secondary: 'bg-neutrals-main-border hover:bg-neutrals-menu-border text-neutrals-text-primary',
			danger: 'bg-red text-[white]',
			outline:
				'border border-neutrals-main-border hover:border-primary bg-transparent shadow-sm hover:bg-primary hover:text-[white] dark:text-[white]',
			ghost: 'hover:bg-primary dark:text-[white] hover:text-[white]',
			icon: 'text-neutrals-text-primary !px-1 !py-1',
			link: 'text-primary-100 !px-1 !py-1',
			text: 'text-neutrals-text-primary !px-1 !py-1',
			success: 'bg-green hover:bg-green text-[white] font-bold',
			dark: 'bg-black dark:bg-blue-500 text-white',
			menu: 'bg-primary text-[white] shadow rounded-full',
			'outline-secondary': 'bg-violate-10 border border-violate-50 text-neutrals-text-primary',
		},
		size: {
			default: 'px-4 py-2.5',
			xs: 'px-3 py-0.5 text-xs',
			sm: 'px-3 py-1.5 text-xs',
			base: 'px-4 py-2 text-md',
			md: 'px-4 py-2 text-sm',
			lg: 'px-5 py-2.5 text-md',
			xl: 'px-3.5 py-2 text-md',
		},
	},
	defaultVariants: {
		variant: 'primary',
		size: 'default',
	},
} as const;

const buttonVariants = cva(
	'inline-flex items-center relative justify-center rounded-lg text-sm font-medium transition-color disabled:pointer-events-none disabled:opacity-50 hover:brightness-90 transition-all active:scale-[0.98]',
	buttonVariantOptions
);

/** Button */
const Button = ({
	className,
	variant,
	size,
	fullWidth,
	containerClassName,
	rounded = true,
	animate,
	...props
}: ButtonProps) => {
	const { children, leftIcon, rightIcon, isLoading, asChild, ...restProps } = props;

	if (asChild) {
		return <button {...props}>{children}</button>;
	}

	const ButtonComponent = ({ children }: { children: React.ReactNode }) => {
		return children;
	};

	const textSize = size || 'default';

	const iconSizeMapper: Record<typeof textSize, IconProps['size']> = {
		default: 'default',
		lg: 'md',
		sm: 'sm',
		xs: 'xs',
		md: 'sm',
		base: 'sm',
		xl: 'md',
	};

	const spinnerSizeMapper: Record<typeof textSize, SpinnerProps['size']> = {
		default: 'default',
		lg: 'lg',
		sm: 'sm',
		xs: 'sm',
		md: 'sm',
		base: 'sm',
		xl: 'md',
	};

	return (
		<ButtonComponent>
			<button
				className={cn([
					className,
					'group',
					buttonVariants({
						variant,
						size,
					}),
					animate && 'animate-zoom-in-normal',
					rounded && 'rounded-full',
					isLoading ? 'cursor-wait' : 'cursor-pointer',
					fullWidth && 'w-full',
					containerClassName,
				])}
				{...restProps}
			>
				{isLoading && <Spinner size={spinnerSizeMapper[textSize] || 'default'} className="absolute" />}
				<div
					className={`flex items-center whitespace-nowrap gap-2 ${isLoading ? 'opacity-0' : ''} ${className}`}
				>
					{leftIcon && <Icon currentColor size={iconSizeMapper[textSize] || 'default'} {...leftIcon} />}
					{children}
					{rightIcon && <Icon currentColor size={iconSizeMapper[textSize] || 'default'} {...rightIcon} />}
				</div>
			</button>
		</ButtonComponent>
	);
};

export { Button, buttonVariants, buttonVariantOptions };
