import { gql } from '@voyage-lab/cube';

export const WORKFLOW_LIST = gql`
	query GetWorkflowList($limit: Int, $offset: Int, $brandId: String, $timezone: String) {
		cube(
			limit: $limit
			offset: $offset
			timezone: $timezone
			where: { brands: { id: { equals: $brandId } }, workflows: { is_paused: { equals: "false" } } }
		) {
			workflows {
				id
				name
				is_paused
			}
		}
	}
`;

export const WORKFLOW_STATS = gql`
	query GetWorkflowStats(
		$currDateRange: [String]
		$prevDateRange: [String]
		$workflowId: String
		$timezone: String
		$isHourly: Boolean = false
		$isDaily: Boolean = false
		$isWeekly: Boolean = false
		$isMonthly: Boolean = false
		$isYearly: Boolean = false
	) {
		events: cube(
			timezone: $timezone
			where: {
				workflow_goal_state_change_events: {
					workflow_id: { equals: $workflowId }
					created_at: { inDateRange: $currDateRange }
				}
			}
		) {
			workflow_goal_state_change_events {
				state_display
				count
				created_at {
					hour @include(if: $isHourly)
					day @include(if: $isDaily)
					week @include(if: $isWeekly)
					month @include(if: $isMonthly)
					year @include(if: $isYearly)
				}
			}
		}
		curr_conversation_messages: cube(
			timezone: $timezone
			where: {
				workflows: { id: { equals: $workflowId } }
				conversation_messages: { created_at: { inDateRange: $currDateRange } }
			}
		) {
			conversation_messages {
				sent_message_count
				reply_count
				created_at {
					hour @include(if: $isHourly)
					day @include(if: $isDaily)
					week @include(if: $isWeekly)
					month @include(if: $isMonthly)
					year @include(if: $isYearly)
				}
			}
		}
		prev_conversation_messages: cube(
			timezone: $timezone
			where: {
				workflows: { id: { equals: $workflowId } }
				conversation_messages: { created_at: { inDateRange: $prevDateRange } }
			}
		) {
			conversation_messages {
				sent_message_count
				reply_count
			}
		}
		curr_order: cube(
			timezone: $timezone
			where: {
				workflows: { id: { equals: $workflowId } }
				orders: { created_at: { inDateRange: $currDateRange } }
			}
		) {
			orders {
				created_at {
					hour @include(if: $isHourly)
					day @include(if: $isDaily)
					week @include(if: $isWeekly)
					month @include(if: $isMonthly)
					year @include(if: $isYearly)
				}
				recovered_count
				recovered_sales
			}
		}
		prev_order: cube(
			timezone: $timezone
			where: {
				workflows: { id: { equals: $workflowId } }
				orders: { created_at: { inDateRange: $prevDateRange } }
			}
		) {
			orders {
				recovered_count
				recovered_sales
			}
		}
	}
`;

export const WORKFLOW_STATS_LIST = gql`
	query GetWorkflowStatsList(
		$currDateRange: [String]
		$prevDateRange: [String]
		$workflowIds: [String]
		$timezone: String
	) {
		curr_conversations: cube(
			timezone: $timezone
			where: {
				workflows: { id: { in: $workflowIds } }
				conversations: { created_at: { inDateRange: $currDateRange } }
			}
		) {
			conversations {
				workflow_id
				curr_conversation_count: count
				curr_reply_count: reply_count
			}
		}
		curr_orders: cube(
			timezone: $timezone
			where: { workflows: { id: { in: $workflowIds } }, orders: { created_at: { inDateRange: $currDateRange } } }
		) {
			orders {
				workflow_id
				curr_order_count: count
				curr_aov: aov
				curr_recovered_count: recovered_count
				curr_recovered_sales: recovered_sales
			}
		}
		prev_conversations: cube(
			timezone: $timezone
			where: {
				workflows: { id: { in: $workflowIds } }
				conversations: { created_at: { inDateRange: $prevDateRange } }
			}
		) {
			conversations {
				workflow_id
				prev_conversation_count: count
				prev_reply_count: reply_count
			}
		}
		prev_orders: cube(
			timezone: $timezone
			where: { workflows: { id: { in: $workflowIds } }, orders: { created_at: { inDateRange: $prevDateRange } } }
		) {
			orders {
				workflow_id
				prev_order_count: count
				prev_aov: aov
				prev_recovered_count: recovered_count
				prev_recovered_sales: recovered_sales
			}
		}
	}
`;
