import type { LucideProps } from 'lucide-react';
import React from 'react';

export const Status = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 2 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				d="M0.267578 0.454102H1.73242L1.61523 5.18066H0.384766L0.267578 0.454102ZM1 7.62207C0.555664 7.62207 0.204102 7.28516 0.204102 6.85547C0.204102 6.42578 0.555664 6.08887 1 6.08887C1.44434 6.08887 1.7959 6.42578 1.7959 6.85547C1.7959 7.28516 1.44434 7.62207 1 7.62207Z"
				fill={color || 'currentColor'}
			/>
		</svg>
	);
};
