import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import { AppError } from '@voyage-lab/core-common';
import type { IntegrationCoreData } from '@voyage-lab/core-integration';
import type { DatabaseEntity, DatabaseEnum } from '@voyage-lab/db';
import { Constants, type PostgrestClientType } from '@voyage-lab/db';
import { Helpers, type PartialExcept } from '@voyage-lab/util';

export class ContactData {
	#dbClient: PostgrestClientType;
	#integrationData: IntegrationCoreData;

	constructor(args: { dbClient: PostgrestClientType; integrationData: IntegrationCoreData }) {
		this.#dbClient = args.dbClient;
		this.#integrationData = args.integrationData;
	}

	/** Initial contact with minimal possible data, this fills required but not provied value with default value */
	async initiate(props: {
		phone?: string;
		email?: string;
		channels?: Partial<DatabaseEntity['contact_channels']>[];
		contact: PartialExcept<DatabaseEntity['contacts'], 'brand_id'>;
	}) {
		// Validation
		if (!props.channels?.length && !props.phone && !props.email) {
			console.error('No channels, phone or email provided', props);
			throw new AppError('Either username, phone or email must be provided');
		}

		// Initialization
		let smsIntegration: DatabaseEntity['brand_integrations'] | undefined;
		let emailIntegration: DatabaseEntity['brand_integrations'] | undefined;
		const channelBids = props.channels?.map<string>((c) => c.brand_integration_id!)?.filter(Boolean) || [];

		const missingBids = channelBids.length !== props.channels?.length;
		if (missingBids) {
			// brand_integrations.id was not passed so let's find it or create if it doesn't exist
			const integrationRes = await this.#integrationData.getBi({
				brandId: props.contact.brand_id,
				integrationIds: [...Constants.IntegrationIdsCategory.Sms, ...Constants.IntegrationIdsCategory.Email],
			});

			smsIntegration = integrationRes.data
				?.find((i) => Constants.IntegrationIdsCategory.Sms.some((id) => id === i.id))
				?.brand_integrations?.find((bi) => bi.is_enabled);

			emailIntegration = integrationRes.data
				?.find((i) => Constants.IntegrationIdsCategory.Email.some((id) => id === i.id))
				?.brand_integrations?.find((bi) => bi.is_enabled);

			if (!smsIntegration) {
				const createdIntegrationRes = await this.#integrationData.create({
					data: {
						brand_id: props.contact.brand_id,
						integration_id: Constants.Integration.TwilioIntegrationId,
						status: 'connected',
						created_at: new Date().toISOString(),
						updated_at: new Date().toISOString(),
						id: uuid(),
						settings: {},
						is_enabled: true,
					},
				});
				if (!createdIntegrationRes.data) throw new Error('Failed to create default sms integration');
				smsIntegration = createdIntegrationRes.data;
			}

			if (!emailIntegration) {
				const createdEmailIntegrationRes = await this.#integrationData.create({
					data: {
						brand_id: props.contact.brand_id,
						integration_id: Constants.Integration.EmailIntegrationId,
						status: 'connected',
						created_at: new Date().toISOString(),
						updated_at: new Date().toISOString(),
						id: uuid(),
						settings: {},
						is_enabled: true,
					},
				});
				if (!createdEmailIntegrationRes.data) throw new Error('Failed to create default email integration');
				emailIntegration = createdEmailIntegrationRes.data;
			}
		}

		let channels = props.channels ?? [];
		if (props.phone) channels.push({ username: formatChannelUsername(props.phone) ?? '' });
		if (props.email) channels.push({ username: formatChannelUsername(props.email) ?? '' });
		channels = channels.filter(Boolean);

		const usernames = channels.map((c) => formatChannelUsername(c.username!)).filter(Boolean) as string[];
		const bids = [...channelBids, smsIntegration?.id, emailIntegration?.id].filter(Boolean) as string[];

		const existingContactRes = await this.#dbClient
			.from('contact_channels')
			.select('*,contacts(*)')
			.ilikeAnyOf('username', usernames)
			.in('brand_integration_id', bids);

		let contact = existingContactRes.data?.find((c) => c.contacts?.id)?.contacts;

		// All channels already exist for this contact
		if (contact?.id && usernames.length === existingContactRes.data?.length) {
			// Update contact as there could be new data
			if (contact.id) {
				const mergedContact = Helpers.Object.deepMerge(contact, props.contact);
				const contactRes = await this.#dbClient
					.from('contacts')
					.update(mergedContact)
					.eq('id', contact.id)
					.select()
					.maybeSingle();

				if (!contactRes.data) throw new Error('Failed to update contact: ' + contactRes.error?.message);
				contact = contactRes.data;
			}
			return { contact, channels: existingContactRes.data };
		}

		if (!contact) {
			const contactRes = await this.#dbClient
				.from('contacts')
				.insert({
					...props.contact,
					id: props.contact.id || uuid(),
					brand_id: props.contact.brand_id,
					family_name: props.contact.family_name || '',
					given_name: props.contact.given_name || '',
					external_id: props.contact.external_id || '',
					source: props.contact.source || Constants.Contact.Source,
					created_at: props.contact.created_at || new Date().toISOString(),
					updated_at: props.contact.updated_at || new Date().toISOString(),
				})
				.select()
				.maybeSingle();
			if (!contactRes.data) {
				console.error('Failed to create contact: ' + contactRes.error?.message, props);
				throw new Error('Failed to create contact: ' + contactRes.error?.message);
			}

			contact = contactRes.data;
		}

		if (!contact) throw new Error('Failed to create contact');
		const missingChannels = channels
			.filter((c) => !existingContactRes.data?.some((ec) => ec.username === c.username))
			.filter((c) => c.username);

		// Fill all channels with brand integration id
		const channelsToCreate = missingChannels.map((c) => {
			// Validation
			const isEmail = z.string().email().safeParse(c.username).success;
			if (!isEmail && !smsIntegration?.id) throw new Error('Sms integration not found');
			if (isEmail && !emailIntegration?.id) throw new Error('Email integration not found');

			const defaultChannel: DatabaseEntity<'insert'>['contact_channels'] = {
				id: uuid(),
				brand_integration_id: c.brand_integration_id!,
				username: formatChannelUsername(c.username!)!,
				status: 'subscribed',
				external_id: c.external_id || '',
				extra_data: c.extra_data! || {},
				contact_id: contact.id,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			};
			if (isEmail && emailIntegration?.id) defaultChannel.brand_integration_id = emailIntegration.id;
			if (smsIntegration?.id && !isEmail) defaultChannel.brand_integration_id = smsIntegration.id;

			const completeChannel = Helpers.Object.deepMerge(defaultChannel, {
				...c,
				username: formatChannelUsername(c.username!),
			});
			return completeChannel as DatabaseEntity<'insert'>['contact_channels'];
		});

		const channelRes = await this.#dbClient.from('contact_channels').insert(channelsToCreate).select();
		if (!channelRes.data) throw new Error('Failed to create contact channel: ' + channelRes.error?.message);

		return { contact, channels: channelRes.data };
	}

	static constructName(
		contact?: Partial<DatabaseEntity['contacts']>,
		channel?: Partial<DatabaseEntity['contact_channels']>
	) {
		return (
			(contact?.given_name || '') + ' ' + (contact?.family_name || '').trim() ||
			Helpers.Phone.prettyFormat(formatChannelUsername(channel?.username || '') || '') ||
			'Unknown'
		);
	}
}

function formatChannelUsername(username: string) {
	let formattedUsername: string | null = username;
	if (formattedUsername.includes('@')) formattedUsername = formattedUsername.trim().toLowerCase();
	else formattedUsername = Helpers.Phone.e164Format(formattedUsername);

	// Return null if username is not valid
	if (!formattedUsername || formattedUsername.length < 5) return username?.trim()?.toLowerCase();

	return formattedUsername ?? username;
}
