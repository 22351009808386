export const downloadChartCSV = (data: object[], name: string) => {
	const titleKeys = Object.keys(data[0]);

	if (!titleKeys) return;

	const refinedData = [];
	refinedData.push(titleKeys.map((k) => k.toUpperCase()));

	data.forEach((d) => {
		const values = Object.values(d);
		const removeCommas = values.map((v) => {
			if (typeof v === 'string') {
				return v.replace(/,/g, '');
			}
			return v;
		});
		refinedData.push(removeCommas);
	});

	let csvContent = '';

	refinedData.forEach((d) => {
		csvContent += d.join(',') + '\n';
	});

	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
	const objUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.setAttribute('href', objUrl);
	link.setAttribute('download', `${name}.csv`);
	link.click();
};
