import bg_circle from './bg_circle.svg';
import bright_star from './bright_star.svg';
import dummy_chart from './dummy_chart.svg';
import google_logo from './google_logo.svg';
import knowledgebase from './knowledgebase.svg';
import layout_nav_logo from './layout_nav_logo.svg';
import line_chart from './line_chart.svg';
import line_chart_lg_primary from './line_chart_lg_primary.svg';
import line_chart_lg_secondary from './line_chart_lg_secondary.svg';
import line_chart_lg_warning from './line_chart_lg_warning.svg';
import lr_logo from './lr_logo.svg';
import lr_logo_gradient from './lr_logo_gradient.svg';
import lr_logo_icon from './lr_logo_icon.svg';
import lr_logo_sm from './lr_logo_sm.svg';
import lr_logo_text from './lr_logo_text.svg';
import man_face_avatar from './man_face_avatar.png';
import man_face_avatar_2 from './man_face_avatar_2.png';
import message from './message.svg';
import phone from './phone.svg';
import shopify from './shopify.svg';
import star_logo from './star_logo.svg';
import step_line from './step_line.svg';
import success from './success.svg';
import woman_face_avatar from './woman_face_avatar.png';
import woman_face_avatar_2 from './woman_face_avatar_2.png';
import workflow_chart from './workflow_chart.svg';
import workflow_connector from './workflow_connector.svg';

export const assets = {
	lr_logo,
	lr_logo_icon,
	lr_logo_text,
	lr_logo_sm,
	line_chart,
	line_chart_lg_primary,
	line_chart_lg_secondary,
	line_chart_lg_warning,
	workflow_connector,
	shopify,
	dummy_chart,
	man_face_avatar,
	woman_face_avatar,
	bg_circle,
	step_line,
	phone,
	message,
	success,
	layout_nav_logo,
	star_logo,
	google_logo,
	workflow_chart,
	bright_star,
	knowledgebase,
	lr_logo_gradient,
	man_face_avatar_2,
	woman_face_avatar_2,
};
