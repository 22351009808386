import type { PostgrestClientType } from '@voyage-lab/db';

import { NotificationData } from './notification';
import { PermissionData } from './permission';

export class ApplicationData {
	#dbClient: PostgrestClientType;
	notification: NotificationData;
	permission: PermissionData;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
		this.notification = new NotificationData(this.#dbClient);
		this.permission = new PermissionData(this.#dbClient);
	}
}
