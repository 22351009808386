import { cva } from 'class-variance-authority';

import { cn } from '../../utilities';
import type { TextProps } from './text.types';

export const textVariantOptions = {
	variants: {
		size: {
			default: 'text-base',
			xs: 'text-xs',
			sm: 'text-sm',
			md: 'text-md',
			lg: 'md:text-lg text-md',
			xl: 'md:text-xl text-lg',
			'2xl': 'md:text-2xl text-xl',
			'3xl': 'md:text-3xl text-2xl',
			'4xl': 'md:text-4xl text-3xl',
		},
		weight: {
			default: '',
			bold: 'font-bold',
			bolder: 'font-bolder',
		},
		variant: {
			default: 'text-neutrals-text-primary',
			error: 'text-red',
			primary: 'text-primary-100',
			secondary: 'text-neutrals-text-secondary',
			danger: 'text-red',
			success: 'text-green',
			warning: 'text-orange',
			disabled: 'text-neutrals-text-secondary',
			icon: 'text-neutrals-icon-secondary',
		},
	},
	defaultVariants: {
		size: 'default',
		weight: 'default',
		variant: 'default',
	},
} as const;

const textVariants = cva('', textVariantOptions);

const TextRoot = ({ className, size, weight, variant: type, children, as, target, noWrap, ...props }: TextProps) => {
	const Wrapper = ({ children }: { children: React.ReactNode }) => {
		return children;
	};
	return (
		<Wrapper>
			<p
				className={cn([
					textVariants({ className, size, weight, variant: type }),
					noWrap ? 'whitespace-nowrap' : '',
				])}
				{...props}
			>
				{children}
			</p>
		</Wrapper>
	);
};

const Heading = ({ className, size, weight, variant: type, children, ...props }: TextProps) => {
	return (
		<h1 className={cn(textVariants({ className, size, weight, variant: type }))} {...props}>
			{children}
		</h1>
	);
};

const Span = ({ className, size, weight, variant: type, children, ...props }: TextProps) => {
	return (
		<span className={cn(textVariants({ className, size, weight, variant: type }))} {...props}>
			{children}
		</span>
	);
};

const Text = Object.assign(TextRoot, { Heading, Span });

export { Text, textVariants };
