import { fontFamily } from 'tailwindcss/defaultTheme';

export const tailwindThemeConfig = {
	screens: {
		sm: '640px',
		md: '768px',
		lg: '1024px',
		xl: '1280px',
		'2xl': '1536px',
		'md-h': { raw: '(min-height: 900px)' },
	},
	fontSize: {
		base: '1rem',
		xs: '0.625rem',
		sm: '0.75rem',
		md: '0.875rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.75rem',
		'4xl': '2.25rem',
	},
	fontWeight: {
		normal: '400',
		bold: '600',
		bolder: '700',
	},
	container: {
		center: true,
		padding: '2rem',
		screens: {
			'2xl': '1400px',
		},
	},
	colors: {
		transparent: 'transparent',

		primary: 'var(--primary)',
		'primary-light': 'var(--primary-light)',
		'primary-100': 'var(--primary-100)',
		'primary-outline': 'var(--primary-outline)',

		blue: 'var(--blue)',
		'blue-20': 'var(--blue-20)',
		'blue-100': 'var(--blue-100)',

		'cyan-outline': 'var(--cyan-outline)',
		'cyan-icon': 'var(--cyan-icon)',

		green: 'var(--green)',
		'green-2': 'var(--green-2)',
		'green-20': 'var(--green-20)',
		'green-100': 'var(--green-100)',
		'green-dark': 'var(--green-dark)',
		'green-light': 'var(--green-light)',
		'green-border-outline': 'var(--green-border-outline)',
		'green-text-outline': 'var(--green-text-outline)',

		yellow: 'var(--yellow)',
		'yellow-20': 'var(--yellow-20)',
		'yellow-100': 'var(--yellow-100)',
		'yellow-light': 'var(--yellow-light)',

		white: 'var(--white)',
		black: 'var(--black)',

		'white-icon': 'var(--white-icon)',
		'neutrals-white': 'var(--text-white)',

		'violate-10': 'var(--violate-10)',
		'violate-50': 'var(--violate-50)',

		'dark-1': 'var(--dark-1)',
		'dark-2': 'var(--dark-2)',
		'dark-3': 'var(--dark-3)',

		'mid-1': 'var(--mid-1)',

		grey: 'var(--grey)',
		'grey-1': 'var(--grey-1)',
		'grey-2': 'var(--grey-2)',
		'grey-3': 'var(--grey-3)',

		'primary-200': 'var(--primary-200)',
		'primary-500': 'var(--primary-500)',

		pink: 'var(--pink)',
		'pink-soft': 'var(--pink-soft)',

		turquoise: 'var(--turquoise)',

		'white-shade': 'var(--white-shade)',
		'black-shade': 'var(--black-shade)',

		red: 'var(--red)',
		'red-20': 'var(--red-20)',
		'red-light': 'var(--red-light)',
		'red-100': 'var(--red-100)',
		orange: 'var(--orange)',
		'orange-20': 'var(--orange-20)',
		'orange-light': 'var(--orange-light)',
		'orange-soft': 'var(--orange-soft)',
		'orange-100': 'var(--orange-100)',
		'orange-border-outline': 'var(--orange-border-outline)',
		'orange-bg-outline': 'var(--orange-bg-outline)',
		'orange-text-outline': 'var(--orange-text-outline)',

		background: 'var(--background)',
		'background-2': 'var(--background-2)',
		'background-100': 'var(--background-100)',

		'neutrals-menu-bg': 'var(--neutrals-menu-bg)',
		'neutrals-menu-highlight': 'var(--neutrals-menu-highlight)',
		'neutrals-auth-bg': 'var(--neutrals-auth-bg)',
		'neutrals-menu-divider': 'var(--neutrals-menu-divider)',
		'neutrals-menu-border': 'var(--neutrals-menu-border)',
		'neutrals-main-border': 'var(--neutrals-main-border)',
		'neutrals-text-secondary': 'var(--neutrals-text-secondary)',
		'neutrals-text-primary': 'var(--neutrals-text-primary)',
		'neutrals-icon-secondary': 'var(--neutrals-icon-secondary)',
		'neutrals-menu-secondary': 'var(--neutrals-menu-secondary)',
		'neutrals-menu-secondary-2': 'var(--neutrals-menu-secondary-2)',
		'neutrals-card-empty': 'var(--neutrals-card-empty)',

		'status-error': 'var(--status-error)',
		'status-success': 'var(--status-success)',
		'status-warning': 'var(--status-warning)',

		'graphs-2': 'var(--graphs-2)',

		glass: 'var(--glass)',
		'table-header-bg': 'var(--table-header-bg)',
		'table-bg-secondary': 'var(--table-bg-secondary)',
	},
	extend: {
		boxShadow: {
			base: '0px 4px 24px -7px var(--primary)',
		},
		spacing: {
			page: '3rem',
			'page-sm': '1.25rem',
		},
		padding: {
			page: '3rem',
			'page-sm': '1.25rem',
			card: '1.25rem',
			'card-sm': '1rem',
			'card-lg': '1.5rem',
			sm: '0.5rem',
			md: '1rem',
			lg: '1.5rem',
			xl: '2rem',
			xxl: '3rem',
		},
		fontFamily: {
			sans: ['var(--font-sans)', ...fontFamily.sans],
		},
		keyframes: {
			'accordion-down': {
				from: { height: '0px' },
				to: { height: 'var(--radix-accordion-content-height)' },
			},
			'accordion-up': {
				from: { height: 'var(--radix-accordion-content-height)' },
				to: { height: '0px' },
			},
			'bar-loader': {
				from: { left: '-100%' },
				to: { left: '100%' },
			},
			'bounce-high': {
				'0%, 100%': { transform: 'translateY(-50%)' },
				'50%': { transform: 'translateY(0)' },
			},
			'auto-slide': {
				'0%': { transform: 'translateX(-50%)' },
				'100%': { transform: 'translateX(0%)' },
			},
			'auto-slide-reverse': {
				'0%': { transform: 'translateX(0%)' },
				'100%': { transform: 'translateX(-50%)' },
			},
			'page-fade-in': {
				'0%': { opacity: '0', transform: 'translateX(30px)' },
				'100%': { opacity: '100', transform: 'translateX(0)' },
			},
			'page-fade-in-from-left': {
				'0%': { opacity: '0', transform: 'translateX(-30px)' },
				'100%': { opacity: '100', transform: 'translateX(0)' },
			},
			'zoom-in': {
				'0%': { transform: 'scale(0)' },
				'80%': { transform: 'scale(1.2)' },
				'100%': { transform: 'scale(1)' },
			},
			'zoom-in-normal': {
				'0%': { transform: 'scale(0)' },
				'100%': { transform: 'scale(1)' },
			},
			'chart-tooltip': {
				'0%': { transform: 'scale(0)' },
				'80%': { transform: 'scale(0)' },
				'100%': { transform: 'scale(1)' },
			},
			'zoom-bottom-right': {
				'0%': { transform: 'scale(0)', transformOrigin: 'bottom right' },
				'20%': { transform: 'scale(1.1)', transformOrigin: 'bottom right' },
				'40%': { transform: 'scale(0.9)', transformOrigin: 'bottom right' },
				'60%': { transform: 'scale(1.05)', transformOrigin: 'bottom right' },
				'80%': { transform: 'scale(0.95)', transformOrigin: 'bottom right' },
				'100%': { transform: 'scale(1)', transformOrigin: 'bottom right' },
			},
			'zoom-bottom-left': {
				'0%': { transform: 'scale(0)', transformOrigin: 'bottom left' },
				'20%': { transform: 'scale(1.1)', transformOrigin: 'bottom left' },
				'40%': { transform: 'scale(0.9)', transformOrigin: 'bottom left' },
				'60%': { transform: 'scale(1.05)', transformOrigin: 'bottom left' },
				'80%': { transform: 'scale(0.95)', transformOrigin: 'bottom left' },
				'100%': { transform: 'scale(1)', transformOrigin: 'bottom left' },
			},
			flying: {
				'0%': { transform: 'translateY(0)' },
				'50%': { transform: 'translateY(5px)' },
				'100%': { transform: 'translateY(0)' },
			},
			'error-input': {
				'0%': { borderColor: 'red' },
				'50%': { borderColor: 'red' },
				'100%': { borderColor: '' },
			},
			'fade-out': {
				'0%': { opacity: '1' },
				'100%': { opacity: '0' },
			},
			'fade-out-to-top': {
				'0%': { opacity: '1' },
				'100%': { opacity: '0', transform: 'translateY(-100%)' },
			},
			'fade-in-from-bottom': {
				'0%': { opacity: '0', transform: 'translateY(100%)' },
				'100%': { opacity: '1' },
			},
			'fade-in-from-top': {
				'0%': { opacity: '0', transform: 'translateY(-100%)' },
				'100%': { opacity: '1' },
			},
			'fade-in-from-left': {
				'0%': { opacity: '0', transform: 'translateX(-100%)' },
				'100%': { opacity: '1' },
			},
			'shake-sm': {
				'0%, 100%': { transform: 'rotate(0deg)' },
				'10%, 30%, 50%, 70%, 90%': { transform: 'rotate(-1deg)' },
				'20%, 40%, 60%, 80%': { transform: 'rotate(1deg)' },
			},
			'zoom-and-fade': {
				'0%': { transform: 'scale(0)', opacity: '1' },
				'50%': { transform: 'scale(1)', opacity: '1' },
				'100%': { transform: 'scale(1.2)', opacity: '0' },
			},
			'bell-ring': {
				'0%': { transform: 'rotate(0deg) translateY(0%)', transformOrigin: 'top' },
				'10%': { transform: 'rotate(0deg) translateY(-10%)', transformOrigin: 'top' },
				'15%, 25%, 35%, 45%, 55%': { transform: 'rotate(-10deg) translateY(-10%)', transformOrigin: 'top' },
				'20%, 30%, 40%, 50%': { transform: 'rotate(10deg) translateY(-10%)', transformOrigin: 'top' },
				'60%': { transform: 'rotate(0deg) translateY(0%)', transformOrigin: 'top' },
				'100%': { transform: 'rotate(0deg) translateY(0%)', transformOrigin: 'top' },
			},
			'move-left-right': {
				'0%': { transform: 'translateX(0)' },
				'100%': { transform: 'translateX(50%)' },
			},
			'expand-left-right': {
				'0%': { width: '0%' },
				'100%': { width: '100%' },
			},
		},
		animation: {
			'accordion-down': 'accordion-down 0.2s ease-out',
			'accordion-up': 'accordion-up 0.2s ease-out',
			'bar-loader': 'bar-loader 1s linear infinite',
			'bounce-high': 'bounce-high 1s ease-in-out infinite',
			'page-fade-in': 'page-fade-in 0.5s ease-in-out',
			'page-fade-in-from-left': 'page-fade-in-from-left 0.5s ease-in-out',
			'auto-slide': 'auto-slide 15s linear infinite',
			'auto-slide-reverse': 'auto-slide-reverse 15s linear infinite',
			'zoom-bottom-right': 'zoom-bottom-right 1s ease-in-out',
			'zoom-bottom-left': 'zoom-bottom-left 1s ease-in-out',
			flying: 'flying 2s ease-in-out infinite',
			'error-input': 'error-input 2s ease-in-out',
			'fade-out-to-top': 'fade-out-to-top 0.5s ease-in-out',
			'fade-out': 'fade-out 0.5s ease-in-out',
			'fade-in-from-bottom': 'fade-in-from-bottom 0.5s ease-in-out',
			'fade-in-from-top': 'fade-in-from-top 0.5s ease-in-out',
			'zoom-in': 'zoom-in 0.5s ease-in-out',
			'shake-sm': 'shake-sm 1s linear infinite',
			'chart-tooltip': 'chart-tooltip 0.4s ease-in-out',
			'zoom-and-fade': 'zoom-and-fade 1s ease-out infinite',
			'bell-ring': 'bell-ring 1s ease-in-out',
			'zoom-in-normal': 'zoom-in-normal 0.2s ease-in-out',
			'move-left-right': 'move-left-right 30s linear infinite',
			'expand-left-right': 'expand-left-right 1s linear',
		},
	},
};

const responsivePatterns = [
	`(flex|grid|hidden|block)`,
	`flex-(row|col|row-reverse|col-reverse)`,
	`gap-(1|2|3|4|5|6|7|8|9|10)`,
	`justify-(start|end|center|between|around|evenly)`,
	`items-(start|end|center|baseline)`,
	`grid-cols-(1|2|3|4|5|6|7|8|9|10|none|auto|min|max|fr)`,
	`col-span-(1|2|3|4|5|6|7|8|9|10|full)`,
	`grid-rows-(1|2|3|4|5|6|none|auto|min|max|fr)`,
	`row-span-(1|2|3|4|5|6|full)`,
];

const nonResponsivePatterns = [`bg-.`, `text-.`];

export const tailwindSafelist = [
	{
		pattern: new RegExp(nonResponsivePatterns.join('|')),
	},
	{
		pattern: new RegExp(responsivePatterns.join('|')),
		variants: ['sm', 'md', 'xl', 'lg', '2xl'],
	},
];
