import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import type { GraphQlClientType } from '@voyage-lab/cube';
import { GraphqlQueryTypes } from '@voyage-lab/cube';
import type { DatabaseEntity, DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';
import { Schema } from '@voyage-lab/schema';
import type { PartialExcept } from '@voyage-lab/util';

import { ConversationQuery } from '..';

const NumericString = z.string().refine(
	(value) => {
		return !isNaN(parseFloat(value));
	},
	{
		message: 'Value must be a numeric string',
	}
);

export class ConversationData {
	#dbClient: PostgrestClientType;
	#graphqlClient: GraphQlClientType | null;

	constructor(dbClient: PostgrestClientType, graphqlClient: GraphQlClientType | null) {
		this.#dbClient = dbClient;
		this.#graphqlClient = graphqlClient;
	}

	async getSingle(props?: { conversationId?: string; next?: boolean }) {
		//Postgrest does not support sum() on referenced table, querying all orders for customer and calculating sum.
		const query = this.#dbClient.from('conversations').select(`
			id,
			created_at,
			conversation_messages(*),
			workflow_goal_state_change_events(*),
			contact_channels(*,
			  	contacts(*)
			),
			brands!inner(name)
		  `);

		if (props?.conversationId) {
			query.eq('id', props.conversationId);
		}

		const { data, error } = await query.order('created_at', { ascending: false }).limit(1).maybeSingle();

		let next: string | null = '';
		let prev: string | null = '';
		const products = [];
		//Get Next & Prev Record Id
		if (props?.next) {
			const nextQuery = this.#dbClient.from('conversations').select(`id`).lt('created_at', data?.created_at);

			const prevQuery = this.#dbClient.from('conversations').select(`id`).gt('created_at', data?.created_at);

			const [nextResult, prevResult] = await Promise.all([
				nextQuery.order('created_at', { ascending: false }).limit(1).maybeSingle(),
				prevQuery.order('created_at', { ascending: true }).limit(1).maybeSingle(),
			]);
			next = nextResult.data?.id !== undefined ? nextResult.data.id : null;
			prev = prevResult.data?.id !== undefined ? prevResult.data.id : null;
		}

		return { ...data, prev, next };
	}

	async getCheckoutDetails(props?: { cartId?: string; checkoutId?: string }) {
		if (!props?.cartId && !props?.checkoutId) {
			throw new Error('No cart or checkout ID provided');
		}

		if (props?.cartId) {
			const cart = await this.#dbClient.from('carts').select(`*`).eq('id', props.cartId).single();
			return cart.data;
		} else if (props?.checkoutId) {
			const checkout = await this.#dbClient
				.from('checkouts')
				.select(`*, total:total_price`)
				.eq('id', props.checkoutId)
				.single();
			return checkout.data;
		} else {
			throw new Error('No cart or checkout id found');
		}
	}

	async getAll(props?: {
		id?: string;
		brandId: string;
		checkoutState?: DatabaseEnum['t_checkout_state'][];
		excludeCheckoutState?: DatabaseEnum['t_checkout_state'][];
		workflow?: string[];
		q?: string;
		from?: string;
		to?: string;
		limit?: number;
		offset?: number;
		hasResponse?: boolean;
		isHidden?: boolean;
	}) {
		const checkoutShouldInner = !!props?.checkoutState?.length || !!props?.excludeCheckoutState?.length;

		const query = this.#dbClient.from('conversations').select(`*,
                contact_channels!inner(username, contacts!inner(family_name, given_name)),
				checkouts${checkoutShouldInner ? '!inner' : ''}(state)
            `);
		if (props?.id) {
			query.eq('id', props.id);
		}
		if (props?.brandId) {
			query.eq('brand_id', props.brandId);
		}

		if (props?.checkoutState?.length) {
			query.in('checkouts.state', props.checkoutState);
		}

		if (props?.excludeCheckoutState?.length) {
			query.not('checkouts.state', 'in', `(${props.excludeCheckoutState.join(',')})`);
		}

		if (props?.workflow?.length) {
			query.in('workflow_id', props.workflow);
		}

		if (props?.hasResponse) {
			query.select(`*,
                contact_channels!inner(username, contacts!inner(family_name, given_name)),
				checkouts${checkoutShouldInner ? '!inner' : ''}(state),
				conversation_messages!inner(direction)
            `);
			query.eq('conversation_messages.direction', 'incoming');
		}

		if (props?.q && props?.q !== undefined) {
			//filter null contacts
			query.not('contact_channels', 'is', null);

			//Filter Number
			const isNumberLike = NumericString.safeParse(props?.q?.trim()).success;

			const searchStr = props?.q?.trim();
			const [firstName, lastName] = searchStr.split(' ');

			if (isNumberLike) {
				query.ilike(`contact_channels.username`, `%${props?.q}%`);
			} else if (searchStr && (firstName || lastName)) {
				query.or(
					`given_name.ilike.%${searchStr}%, family_name.ilike.%${searchStr}%, and(given_name.ilike.%${firstName}%, family_name.ilike.%${lastName}%)`,
					{
						referencedTable: 'contact_channels.contacts',
					}
				);
			}
		}

		if (props?.from && props?.to) {
			query.gte('created_at', props?.from).lte('created_at', props?.to);
		}

		if (props?.isHidden !== undefined) {
			query.eq('is_hidden', props.isHidden);
		}

		if (props?.offset !== undefined) {
			const limit = props.limit || 100;
			query.range(props.offset, props.offset + limit - 1);
		}

		return query.order('created_at', { ascending: false });
	}

	async getConversationCount() {
		if (!this.#graphqlClient) throw new Error('GraphQL client not initialized');

		const { data, errors } = await this.#graphqlClient({
			operation: GraphqlQueryTypes.GetConversationStatsDocument,
			variables: {},
		});

		return data;
	}

	async getSingleConvo(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');

		const { data, error } = await this.#dbClient
			.from('conversations')
			.select(
				`
				id,
                created_at,
                updated_at,
                contact_channels!inner(username,
                    contacts(family_name, given_name)
                ),
				brands!inner(name),
				workflow_goal_state_change_events (
					state
				),
                conversation_messages()
			`
			)
			.eq('id', id)
			.order('created_at', { referencedTable: 'workflow_goal_state_change_events', ascending: false })
			.limit(1)
			.single();

		if (error) {
			console.error('Error fetching single conversation:', error);
			throw error;
		}

		return data;
	}

	async getSingleConvoThread(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');

		const { data, error } = await this.#dbClient
			.from('conversations')
			.select(
				`conversation_messages (
					id,
					body,
					direction,
					created_at
				),
				conversation_feedback (
					id,
					message_id
				),
				workflow_goal_state_change_events (
					id,
					state,
					created_at
				)
			`
			)
			.eq('id', id)
			.order('created_at', { foreignTable: 'conversation_messages' })
			.order('created_at', { foreignTable: 'workflow_goal_state_change_events' })
			.limit(1)
			.maybeSingle();

		if (error) {
			console.error('Error fetching single conversation thread:', error);
			throw error;
		}

		return data;
	}

	async getSingleConvoByFrontID(frontId: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversations')
			.select('id, brand_id')
			.eq('extra_data->>front_id', frontId)
			.limit(1)
			.maybeSingle();
		return data;
	}

	async getEscalation(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_escalations')
			.select('brand_id, question, processed_question, processed_response')
			.eq('id', id)
			.maybeSingle();
		return data;
	}

	async listEscalationsByBrand(brandId: string, limit: number) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_escalations')
			.select('id, brand_id, question, processed_response, is_added_kb, created_at')
			.eq('brand_id', brandId)
			.order('created_at', { ascending: false })
			.limit(limit);
		return data;
	}

	async getConversationFeedbacks(props: { page: number; limit?: number; brandId?: string; search?: string }) {
		const limit = props.limit || 10;
		const offset = (props.page - 1) * limit;

		const query = this.#dbClient.from('conversation_feedback').select(
			`*,
				messages:conversation_messages(body),
				conversations!inner(
					id,
					brand_id,
					conversation_messages(count),
					contact_channels!inner(
						contacts(given_name, family_name),
						username
					),
					workflows!inner(
						id,
						name
					)
				)
			`,
			{ count: 'exact' }
		);

		if (props.brandId) query.eq('conversations.brand_id', props.brandId);
		if (props.search) {
			const phrases = props.search
				.trim()
				.toLowerCase()
				.split(/\s+/)
				.map((phrase) => `'${phrase}'`)
				.join(' | ');
			query.textSearch('comment', phrases);
		}

		return query.range(offset, offset + limit - 1).order('created_at', { ascending: false });
	}

	async getFeedback(props: { messageId?: string; id?: string }) {
		// Validation
		if (!props.messageId && !props.id) throw new Error('messageId or id is required');

		const query = this.#dbClient.from('conversation_feedback').select(`*,messages:conversation_messages(body)`);

		// Filters
		if (props.messageId) query.eq('message_id', props.messageId);
		if (props.id) query.eq('id', props.id);

		return await query.limit(1).maybeSingle();
	}

	async updateEscalationAddedKB(escalationId: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_escalations')
			.update({
				is_added_kb: true,
			})
			.eq('id', escalationId);
		return data;
	}

	async upsertFeedback(props: { data: Partial<DatabaseEntity['conversation_feedback']> }) {
		// Assign Defaults
		// @ts-expect-error: Zod vs Postgrest type missmatch
		if (!props.data.created_at) props.data.created_at = new Date();
		if (!props.data.id) props.data.id = uuid();

		// Validations
		const validData = Schema.conversationFeedbackInputSchema
			.pick({
				id: true,
				created_at: true,
				rating: true,
				comment: true,
				message_id: true,
				conversation_id: true,
			})
			.parse(props.data);

		return this.#dbClient
			.from('conversation_feedback')
			.upsert(
				{
					id: validData.id,
					conversation_id: validData.conversation_id,
					message_id: validData.message_id,
					created_at: validData.created_at.toISOString(),
					updated_at: new Date().toISOString(),
					rating: validData.rating,
					comment: validData.comment,
				},
				{ onConflict: 'message_id' }
			)
			.select('*')
			.maybeSingle();
	}

	async getChannelStatus(conversationId?: string, messageId?: string) {
		if (conversationId) {
			const { data, error } = await this.#dbClient
				.from('conversations')
				.select('contact_channels(status)')
				.eq('id', conversationId)
				.maybeSingle();
			if (error || !data) return null;
			return data.contact_channels?.status;
		}

		if (messageId) {
			const { data, error } = await this.#dbClient
				.from('conversation_messages')
				.select('id, conversations(contact_channels(status))')
				.eq('id', messageId)
				.maybeSingle();
			if (error || !data) return null;
			return data.conversations?.contact_channels?.status;
		}
		return null;
	}

	async getUnsubscribedChannels(brandId: string, integrationIds: DatabaseEntity['integrations']['id'][]) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('contact_channels')
			.select('id, username, brand_integrations!inner(id, brand_id, integration_id)')
			.eq('brand_integrations.brand_id', brandId)
			.eq('status', 'unsubscribed')
			.in('brand_integrations.integration_id', integrationIds);
		return data;
	}

	async upsertBlockContactChannels(username: string, brandId: string, brandIntegrationId: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const existingChannel = await this.#dbClient
			.from('contact_channels')
			.update({ status: 'unsubscribed' })
			.eq('username', username)
			.eq('brand_integration_id', brandIntegrationId)
			.select('username, brand_integrations(brand_id)')
			.maybeSingle();

		if (existingChannel.data) return existingChannel.data;
		const { data: contactData, error: contactError } = await this.#dbClient
			.from('contacts')
			.insert({
				id: uuid(),
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
				family_name: '',
				given_name: '',
				external_id: '',
				brand_id: brandId,
				source: 'csv' as const,
			})
			.select('id');
		if (contactError) throw new Error('Failed to insert contacts');

		const { data: contactChannelData, error: contactChannelError } = await this.#dbClient
			.from('contact_channels')
			.insert({
				id: uuid(),
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
				contact_id: contactData[0].id,
				brand_integration_id: brandIntegrationId,
				username,
				external_id: '',
				status: 'unsubscribed' as const,
				extra_data: { blocked: true },
			})
			.select('id');
		if (contactChannelError) throw new Error('Failed to insert contact channels');
		return contactChannelData;
	}

	async deleteContactChannel(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		return await this.#dbClient.from('contact_channels').delete().eq('id', id);
	}

	async getAgentsByConversation(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_messages')
			.select('id, extra_data')
			.eq('conversation_id', id)
			.eq('direction', 'outgoing');

		if (!data || error) {
			console.error('Failed to get conversation messages', error);
			return null;
		}
		const agents = [];
		for (const message of data) {
			if (message.extra_data?.front_agent?.id) {
				agents.push(message.extra_data.front_agent.id);
			}
		}

		const { data: agentsData, error: agentsError } = await this.#dbClient
			.from('users')
			.select('id')
			.in('auth->provider->front->>user_id', agents);
		if (agentsError || !agentsData) {
			console.error('Failed to get agents', agentsError);
			return null;
		}
		return agentsData;
	}

	async insertConversationQuality(props: { data: DatabaseEntity<'insert'>['conversation_qualities'] }) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_qualities')
			.insert(props.data)
			.select('*')
			.single();
		if (error) console.error('Failed to insert conversation quality', error);
		return data;
	}

	async skipOutboundMessage(messageId: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_messages')
			.update({ status: 'agent_skipped', extra_data: { skip_reason: 'unsubscribed' } })
			.eq('id', messageId);
		return data;
	}

	// async getCheckoutInfo(id: string) {
	// 	if (!this.#graphqlClient) throw new Error('GraphQL client not initialized');

	// 	const { data, errors } = await this.#graphqlClient({
	// 		operation: ConversationQuery.CHECKOUT_INFO,
	// 		variables: { id },
	// 	});

	// 	return data;
	// }

	async initiate({
		data: { conversation, message },
	}: {
		data: {
			conversation: PartialExcept<DatabaseEntity['conversations'], 'brand_id' | 'channel_id'>;
			message: PartialExcept<DatabaseEntity['conversation_messages'], 'body'>;
		};
	}) {
		// Assign Defaults
		conversation = Object.assign(conversation, {
			...conversation,
			id: conversation.id || uuid(),
			created_at: conversation.created_at || new Date().toISOString(),
			updated_at: conversation.updated_at || new Date().toISOString(),
			is_hidden: conversation.is_hidden ?? true,
		} satisfies Partial<DatabaseEntity<'insert'>['conversations']>);

		message = Object.assign(message, {
			...message,
			id: message.id || uuid(),
			conversation_id: message.conversation_id || conversation.id,
			created_at: message.created_at || new Date().toISOString(),
			updated_at: message.updated_at || new Date().toISOString(),
			status: message.status || 'auto_queued',
			direction: message.direction || 'outgoing',
		} satisfies Partial<DatabaseEntity<'insert'>['conversation_messages']>);

		// Validations
		const validConversation = Schema.conversationsInputSchema
			.omit({
				created_at: true,
				updated_at: true,
			})
			.extend({
				created_at: z.string(),
				updated_at: z.string(),
			})
			.parse(conversation);
		const validMessage = Schema.conversationMessagesInputSchema
			.omit({
				created_at: true,
				updated_at: true,
				scheduled_for: true,
			})
			.extend({
				created_at: z.string(),
				updated_at: z.string(),
				scheduled_for: z.string(),
			})
			.parse(message);

		const conversationCreateRes = await this.#dbClient
			.from('conversations')
			.insert(validConversation as unknown as DatabaseEntity['conversations'])
			.select('*')
			.single();

		if (!conversationCreateRes.data) throw new Error('Failed to create conversation');
		validMessage.conversation_id = conversationCreateRes.data.id;

		const messageCreateRes = await this.#dbClient
			.from('conversation_messages')
			.insert(validMessage as unknown as DatabaseEntity['conversation_messages'])
			.select('*')
			.single();

		if (!messageCreateRes.data) {
			await this.#dbClient.from('conversations').delete().eq('id', conversationCreateRes.data.id);
			throw new Error('Failed to create message');
		}

		return {
			conversation: conversationCreateRes.data,
			message: messageCreateRes.data,
		};
	}

	async createFile(props: { data: DatabaseEntity<'insert'>['conversation_files'] }) {
		const conversationFile = await this.#dbClient
			.from('conversation_files')
			.insert(props.data)
			.select('*')
			.single();
		return conversationFile;
	}
}
