'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import type { AuthOptions, AuthResponse } from './auth';
import { useAuthContext } from './context';

type UseSessionOptions<TOptional extends boolean> = Omit<AuthOptions<TOptional>, 'permissions'> & {
	/** If should be redirected to login page */
	noRedirect?: boolean;

	/** Route to redirect when unauthenticated */
	redirectRoute?: string;
};

/**
 * Custom hook for handling authentication using NextAuth.
 *
 * @param options - Optional configuration for the authentication hook.
 * @returns The user session if authenticated.
 * @throws Error when the user is not authenticated based on the provided options or default error message.
 */
export function useAuth<TOptional extends boolean = false>(options: UseSessionOptions<TOptional> = {}) {
	const router = useRouter();
	const authCtx = useAuthContext();

	useEffect(() => {
		if (!authCtx.authQuery.data?.userId && !options.optional) {
			if (options.redirectRoute) {
				router.replace(options.redirectRoute);
			} else {
				router.refresh();
			}
		}
	}, [authCtx.authQuery.data]);

	return {
		...authCtx,
		data: authCtx.authQuery.data as AuthResponse<TOptional>,
		update: authCtx.updateAuthMutation.mutateAsync,
		signOut: authCtx.signOutMutation.mutateAsync,

		// // For backward compatibility
		// loading: authCtx.authQuery.isLoading,
		// status: authCtx.authQuery.status === 'success' ? 'authenticated' : 'unauthenticated',
		queryKey: authCtx.authQuery.isLoading
			? undefined
			: authCtx.authQuery.data
				? `t:${authCtx.authQuery.data.id}|b:${authCtx.authQuery.data.brandId}`
				: null,
	};
}
