'use client';

import { client } from '@passwordless-id/webauthn';
import { useActionState, useEffect, useState } from 'react';
import { z } from 'zod';

import { Button, Icon, Spacer, TextInput, notify } from '@voyage-lab/ui';

import { FormButton, FormTextInput } from '../../components/form';
import { ERROR } from '../../constants';
import { forgetPassword, resetPassword, setupAccount } from './action';

export function ForgotPasswordForm(props: { setupPageLink: string }) {
	const [state, formAction] = useActionState(forgetPassword, {});

	useEffect(() => {
		if (!state.success) {
			if (state.message) notify.error(state.message);
		} else {
			if (state.message) notify.success(state.message);
		}
	}, [state]);

	const host = typeof window !== 'undefined' ? window.location.origin : '';
	const fieldErrors = state.success ? undefined : state.fieldErrors;

	return (
		<form action={formAction}>
			<input type="hidden" name="host" value={`${host}${props.setupPageLink}`} />
			<FormTextInput
				required
				name="email"
				type="email"
				errors={fieldErrors}
				fullWidth
				defaultValue={state.inputs?.email as string}
				innerPrefix={<Icon name="Mail" color="grey-1" />}
				placeholder="Email"
			/>
			<Spacer vertical={10} />
			<FormButton fullWidth>Submit</FormButton>
		</form>
	);
}

export function ResetPasswordForm({ config, email }: { config: React.ReactNode; email: string }) {
	const [state, formAction] = useActionState(resetPassword, {});

	useEffect(() => {
		if (!state.success) {
			if (state.message) notify.error(state.message);
		} else {
			if (state.message) notify.success(state.message);
		}
	}, [state]);

	return (
		<form action={formAction}>
			{config}
			<TextInput
				required
				disabled
				name="email"
				type="email"
				value={email}
				fullWidth
				innerPrefix={<Icon name="Mail" color="grey-1" />}
				placeholder="Email"
			/>
			<TextInput
				required
				name="password"
				type="password"
				defaultValue={state.inputs?.password as string}
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Password"
			/>
			<TextInput
				required
				name="confirmPassword"
				type="password"
				defaultValue={state.inputs?.confirmPassword as string}
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Confirm Password"
			/>
			<Spacer vertical={10} />
			<FormButton fullWidth>Submit</FormButton>
		</form>
	);
}

export function PasskeySignInForm({ challenge }: { challenge: string }) {
	const [isLoading, setIsLoading] = useState(false);
	if (!client.isAvailable()) return null;

	async function handleClick() {
		try {
			setIsLoading(true);
			const authentication = await client.authenticate({
				challenge,
			});

			if (!authentication.response.userHandle) return notify.error(ERROR.INVALID_PASSKEY);

			const userId = atob(authentication.response.userHandle);

			if (!z.string().uuid().safeParse(userId).success) return notify.error(ERROR.INVALID_PASSKEY);

			// TODO: Implement passkey sign in
			// await signIn('credentials', {
			// 	id: userId,
			// 	passKey: JSON.stringify(authentication),
			// 	challenge,
			// });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			notify.error(error?.message);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Button
			type="button"
			fullWidth={false}
			onClick={handleClick}
			isLoading={isLoading}
			disabled={isLoading}
			variant={'secondary'}
			leftIcon={{
				name: 'Fingerprint',
			}}
		>
			Passkey
		</Button>
	);
}

export function SetupAccountForm({ config, email }: { config: React.ReactNode; email: string }) {
	const [state, formAction] = useActionState(setupAccount, {});

	useEffect(() => {
		if (!state.success) {
			if (state.message) notify.error(state.message);
		} else {
			if (state.message) notify.success(state.message);
		}
	}, [state]);

	const fieldErrors = state.success ? undefined : state.fieldErrors;

	return (
		<form action={formAction}>
			{config}
			<FormTextInput
				required
				disabled
				name="email"
				type="email"
				errors={fieldErrors}
				value={email}
				fullWidth
				innerPrefix={<Icon name="Mail" color="grey-1" />}
				placeholder="Email"
			/>
			<FormTextInput
				required
				name="givenName"
				errors={fieldErrors}
				fullWidth
				innerPrefix={<Icon name="User" color="grey-1" />}
				placeholder="First Name"
				defaultValue={state.inputs?.givenName as string}
			/>
			<FormTextInput
				required
				name="familyName"
				errors={fieldErrors}
				fullWidth
				innerPrefix={<Icon name="User" color="grey-1" />}
				placeholder="Last Name"
				defaultValue={state.inputs?.familyName as string}
			/>
			<FormTextInput
				required
				name="password"
				type="password"
				errors={fieldErrors}
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Password"
				defaultValue={state.inputs?.password as string}
			/>
			<FormTextInput
				required
				name="confirmPassword"
				type="password"
				errors={fieldErrors}
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Confirm Password"
				defaultValue={state.inputs?.confirmPassword as string}
			/>
			<Spacer vertical={10} />
			<FormButton fullWidth>Setup</FormButton>
		</form>
	);
}
