import { v4 as uuid } from 'uuid';

import { FileData } from '@voyage-lab/core-file';
import type { DatabaseEntity, DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';
import { Constants } from '@voyage-lab/db';
import { Schema } from '@voyage-lab/schema';

export class BrandData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async getSingle(props: { brandId: string }) {
		const { data, error } = await this.#dbClient
			.from('brands')
			.select(
				`
            id,
            name,
			support_email,
			tos_url,
			privacy_policy_url,
			support_phone,
			escalation_email,
            subscriptions(
                plans(
                    name,
                    price,
                    usage_charge_percentage
                ),
				billing_email
            ),
			attribution_rules
        `
			)
			.eq('id', props.brandId)
			.maybeSingle();

		return data;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async updateBrandIndustry({ brandId, industry }: { brandId: string; industry: any }) {
		const { data, error } = await this.#dbClient.from('brands').update({ industry: industry }).eq('id', brandId);

		if (error) {
			console.log('Update industry error', error);
			return null;
		}
		console.log('Updated: ', error);
		return brandId;
	}

	async updateBrand({ brandId, data }: { brandId: string; data: Partial<DatabaseEntity['brands']> }) {
		const validData = Schema.brandsInputSchema.omit({ id: true, created_at: true }).partial().parse(data);
		const { data: updatedData, error } = await this.#dbClient
			.from('brands')
			.update(validData as unknown as DatabaseEntity['brands'])
			.eq('id', brandId)
			.select('*')
			.maybeSingle();
		return {
			data: updatedData,
			error,
		};
	}

	async updateExtraData({
		brandId,
		data,
	}: {
		brandId: string;
		data: Partial<DatabaseEntity['brands']['extra_data']>;
	}) {
		const brandExtraData = await this.#dbClient.from('brands').select('extra_data').eq('id', brandId).maybeSingle();

		const { data: updatedData, error } = await this.#dbClient
			.from('brands')
			.update({
				extra_data: {
					...(brandExtraData?.data?.extra_data || {}),
					...data,
				},
			})
			.eq('id', brandId)
			.select('*')
			.maybeSingle();

		return {
			data: updatedData,
			error,
		};
	}

	async getDefaultPaymentProvider(brandId: string) {
		const { data } = await this.#dbClient.from('brands').select('extra_data').eq('id', brandId).maybeSingle();
		return data?.extra_data?.is_direct ? 'stripe' : 'shopify';
	}

	async listActive(props: { limit: number; offset: number; search?: string }) {
		const query = this.#dbClient
			.from('brands')
			.select('id, brand_integrations!inner(), subscriptions!inner(), extra_data')
			.eq('brand_integrations.integration_id', Constants.Integration.ShopifyIntegrationId)
			.eq('brand_integrations.status', 'connected')
			.eq('subscriptions.status', 'active');

		if (props.search) query.ilike('name', `%${props.search}%`);

		return await query
			.order('created_at', { ascending: false })
			.range(props.offset, props.offset + props.limit - 1);
	}

	async listAll(props: { limit: number; offset: number; search?: string; id?: string }) {
		const query = this.#dbClient.from('brands').select('id, name, domain');
		if (props.search) query.ilike('name', `%${props.search}%`);
		if (props.id) query.eq('id', props.id);
		return await query
			.order('created_at', { ascending: false })
			.range(props.offset, props.offset + props.limit - 1);
	}

	async addPermission(props: { brandId: string; permissionId: string; useeId: string }) {
		const { brandId, permissionId, useeId } = props;
		const { data } = await this.#dbClient
			.from('brand_permissions')
			.upsert(
				{
					id: uuid(),
					brand_id: brandId,
					permission_id: permissionId,
					author_id: useeId,
					state: 'permitted',
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
				},
				{ onConflict: 'brand_id, permission_id' }
			)
			.select('*')
			.maybeSingle();

		return data;
	}

	async removePermission(props: { brandId: string; permissionId: string; useeId: string }) {
		const { brandId, permissionId, useeId } = props;
		const { data } = await this.#dbClient
			.from('brand_permissions')
			.update({ state: 'denied' })
			.eq('brand_id', brandId)
			.eq('permission_id', permissionId)
			.eq('author_id', useeId);
		return data;
	}

	async createBrandProcess(props: {
		fileService: FileData;
		brandId: string;
		processType: DatabaseEnum['t_brand_processes_type'];
		action: DatabaseEnum['t_brand_processes_action'];
		name: string;
		file: { file: Buffer; name: string; type: string; filePath?: string };
		metadata: Record<string, string>;
	}) {
		const { brandId, processType, action, name, file, metadata, fileService } = props;

		const { dbFile, s3File } = await fileService.uploadAndCreate({
			file,
			metadata,
		});

		if (!dbFile) return { data: null, file: null, error: 'Failed to upload file' };
		const { data, error } = await this.#dbClient.from('brand_processes').insert({
			brand_id: brandId,
			type: processType,
			action,
			file_id: dbFile.id,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
		});

		return { data, file: dbFile, error };
	}

	async getBrandProcessByFileId(props: { fileId: string }) {
		return await this.#dbClient
			.from('brand_processes')
			.select('*, files!inner(*)')
			.eq('files.id', props.fileId)
			.maybeSingle();
	}
}

export type BrandDetailsT = Awaited<ReturnType<BrandData['getSingle']>>;
