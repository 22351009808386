import type { LucideProps } from 'lucide-react';

export const Dollar = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M8 1.33331V14.6666"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 10.6667C4 12.1394 5.19391 13.3334 6.66667 13.3334H9.33333C10.8061 13.3334 12 12.1394 12 10.6667C12 9.19393 10.8061 8.00002 9.33333 8.00002H6.66667C5.19391 8.00002 4 6.80611 4 5.33335C4 3.86059 5.19391 2.66669 6.66667 2.66669H9.33333C10.8061 2.66669 12 3.86059 12 5.33335"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
