export const Integration = {
	ShopifyIntegrationId: 'ece6024a-31c4-4a69-834c-e3aa09c2fffe',
	TwilioIntegrationId: '37406c5b-700d-44a9-b160-9159f59b2041',
	InfobipIntegrationId: '1b43fc9c-804a-44ce-84e3-f11431b6d509',
	EmailIntegrationId: 'a0075734-d82c-4ffa-bee2-bc9953c2550a',
	FrontIntegrationId: 'd4364990-c043-4dcf-9530-f0d5eaf21528',
	JustUnoIntegrationId: 'ece8e78c-0103-4822-99e1-f85381ed62e9',
	BigCommerceIntegrationId: 'ece6024a-31c4-4a69-834c-e3aa09c3fffe',
	KlaviyoIntegrationId: '39c49f07-9042-4dda-9248-9b1931088506',
} as const;

export const BrandIntegration = {
	ShopifyIntegrationId: 'c8266f45-1564-4b4c-9688-1fa11f57ec8e',
	TwilioIntegrationId: '233264d4-1b45-4b3a-bb7a-17fa15a640eb',
	InfobipIntegrationId: '1a6548c5-53fe-4565-8b3b-9ea616d0cf6d',
	EmailIntegrationId: '8012554c-e4c6-461e-84d6-09984e752566',
	FrontIntegrationId: '99ab04b9-92d9-4712-8646-555200f32718',
} as const;

export const IntegrationIdsCategory = {
	Sms: [Integration.TwilioIntegrationId, Integration.InfobipIntegrationId],
	Email: [Integration.EmailIntegrationId],
	Ecommerce: [Integration.ShopifyIntegrationId, Integration.BigCommerceIntegrationId],
} as const;
