import type { LucideProps } from 'lucide-react';

export const Grid = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M12.9335 9.83337H10.4002C10.0268 9.83337 9.84011 9.83337 9.6975 9.90604C9.57206 9.96995 9.47007 10.0719 9.40616 10.1974C9.3335 10.34 9.3335 10.5267 9.3335 10.9V13.4334C9.3335 13.8067 9.3335 13.9934 9.40616 14.136C9.47007 14.2615 9.57206 14.3635 9.6975 14.4274C9.84011 14.5 10.0268 14.5 10.4002 14.5H12.9335C13.3069 14.5 13.4935 14.5 13.6362 14.4274C13.7616 14.3635 13.8636 14.2615 13.9275 14.136C14.0002 13.9934 14.0002 13.8067 14.0002 13.4334V10.9C14.0002 10.5267 14.0002 10.34 13.9275 10.1974C13.8636 10.0719 13.7616 9.96995 13.6362 9.90604C13.4935 9.83337 13.3069 9.83337 12.9335 9.83337Z"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.6 2.5H3.06667C2.6933 2.5 2.50661 2.5 2.36401 2.57266C2.23856 2.63658 2.13658 2.73856 2.07266 2.86401C2 3.00661 2 3.1933 2 3.56667V6.1C2 6.47337 2 6.66005 2.07266 6.80266C2.13658 6.9281 2.23856 7.03009 2.36401 7.094C2.50661 7.16667 2.6933 7.16667 3.06667 7.16667H5.6C5.97337 7.16667 6.16005 7.16667 6.30266 7.094C6.4281 7.03009 6.53009 6.9281 6.594 6.80266C6.66667 6.66005 6.66667 6.47337 6.66667 6.1V3.56667C6.66667 3.1933 6.66667 3.00661 6.594 2.86401C6.53009 2.73856 6.4281 2.63658 6.30266 2.57266C6.16005 2.5 5.97337 2.5 5.6 2.5Z"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.9333 2.5H10.4C10.0266 2.5 9.83995 2.5 9.69734 2.57266C9.5719 2.63658 9.46991 2.73856 9.406 2.86401C9.33333 3.00661 9.33333 3.1933 9.33333 3.56667V6.1C9.33333 6.47337 9.33333 6.66005 9.406 6.80266C9.46991 6.9281 9.5719 7.03009 9.69734 7.094C9.83995 7.16667 10.0266 7.16667 10.4 7.16667H12.9333C13.3067 7.16667 13.4934 7.16667 13.636 7.094C13.7614 7.03009 13.8634 6.9281 13.9273 6.80266C14 6.66005 14 6.47337 14 6.1V3.56667C14 3.1933 14 3.00661 13.9273 2.86401C13.8634 2.73856 13.7614 2.63658 13.636 2.57266C13.4934 2.5 13.3067 2.5 12.9333 2.5Z"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.6 9.83333H3.06667C2.6933 9.83333 2.50661 9.83333 2.36401 9.906C2.23856 9.96991 2.13658 10.0719 2.07266 10.1973C2 10.3399 2 10.5266 2 10.9V13.4333C2 13.8067 2 13.9934 2.07266 14.136C2.13658 14.2614 2.23856 14.3634 2.36401 14.4273C2.50661 14.5 2.6933 14.5 3.06667 14.5H5.6C5.97337 14.5 6.16005 14.5 6.30266 14.4273C6.4281 14.3634 6.53009 14.2614 6.594 14.136C6.66667 13.9934 6.66667 13.8067 6.66667 13.4333V10.9C6.66667 10.5266 6.66667 10.3399 6.594 10.1973C6.53009 10.0719 6.4281 9.96991 6.30266 9.906C6.16005 9.83333 5.97337 9.83333 5.6 9.83333Z"
				stroke={color || 'currentColor'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
