'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { parseAsString, useQueryStates } from 'nuqs';
import { Suspense, useActionState, useEffect } from 'react';

import type { LoginProps } from '@voyage-lab/core-auth';
import { Button, Divider, Icon, Layout, Spacer, Text, notify } from '@voyage-lab/ui';
import { Helpers } from '@voyage-lab/util';
import { Routes, hasPermission, signIn } from '@voyage-lab/web';

import { assets } from '../../../assets';
import { FormButton, FormTextInput } from '../../../components/form';
import { handleSignIn, redirectToDashboard } from '../action';
import { PasskeySignInForm } from '../form';

type LoginFormProps = {
	callbackUrl: string;
	resetLink?: string;
	challenge?: string;
	enableGoogleSignIn?: boolean;
} & Pick<LoginProps, 'app'>;

const termsUrl = 'https://liverecover.com/terms';
const privacyUrl = 'https://liverecover.com/privacy-policy';

export function LoginForm({ callbackUrl, resetLink, challenge, enableGoogleSignIn, app }: LoginFormProps) {
	const [state, formAction] = useActionState(handleSignIn, {});
	const fieldErrors = state.success ? {} : state.fieldErrors || {};

	useEffect(() => {
		if (!state.success) {
			if (state.message) notify.error(state.message);
		} else {
			if (state.message) notify.success(state.message);
		}
	}, [state]);

	return (
		<Layout>
			<Suspense>
				<TokenLoginForm callbackUrl={callbackUrl} />
			</Suspense>
			<Text.Heading size={'3xl'} className="text-center">
				Welcome back
			</Text.Heading>
			<Text size={'sm'} variant={'secondary'} className="text-center">
				Sign in to your account
			</Text>
			<Spacer vertical={20} />
			<Layout display={'flex'} direction={'col'} gap={'1'} className="w-[360px]">
				<form action={formAction} aria-label="Login form">
					<input type="hidden" name="provider" defaultValue={'credentials'} />
					<input type="hidden" name="callbackUrl" defaultValue={callbackUrl} />
					<input type="hidden" name="app" defaultValue={app} />
					<Layout display={'flex'} gap={'2'} direction={'row'}>
						<FormTextInput
							required
							name="email"
							errors={fieldErrors}
							defaultValue={state.inputs?.email as string}
							type="email"
							fullWidth
							autoComplete="username"
							innerPrefix={<Icon name="Mail" color="grey-1" />}
							placeholder="Email"
						/>
					</Layout>
					<FormTextInput
						fullWidth
						type="password"
						name="password"
						defaultValue={state.inputs?.password as string}
						autoComplete="current-password"
						required
						innerPrefix={<Icon name="KeyRound" color="grey-1" />}
						placeholder="Password"
						remove_error_container
					/>
					{!!resetLink && (
						<Layout display={'flex'} justify={'between'}>
							<Link href={resetLink} prefetch>
								<Button type="button" variant={'link'}>
									Forgot password?
								</Button>
							</Link>
							<Link href={Routes.client.account.root} prefetch>
								<Button type="button" variant={'link'}>
									Don't have an account?
								</Button>
							</Link>
						</Layout>
					)}
					<Spacer vertical={15} />
					<Layout display={'flex'} gap={'2'} direction={'row'}>
						<FormButton type="submit" fullWidth>
							Log in
						</FormButton>
						{challenge && typeof window !== 'undefined' && <PasskeySignInForm challenge={challenge} />}
						{challenge && typeof window === 'undefined' && (
							<Button
								formNoValidate
								fullWidth={false}
								variant={'secondary'}
								leftIcon={{
									name: 'Fingerprint',
								}}
							>
								Passkey
							</Button>
						)}
					</Layout>
				</form>
				{enableGoogleSignIn && (
					<>
						<Layout display={'flex'} align={'center'} gap={'2'} className="my-3">
							<Divider />
							<Text size={'sm'} weight={'bold'} variant="primary">
								OR
							</Text>
							<Divider />
						</Layout>
						<form action={formAction}>
							<input type="hidden" name="provider" defaultValue={'google'} />

							<FormButton size={'lg'} fullWidth variant={'outline'}>
								<Image alt="google" height="20" width="20" src={assets.google_logo} />
								Sign in with Google
							</FormButton>
						</form>
					</>
				)}
				<Spacer vertical={10} />
				<Text size={'sm'} variant={'secondary'} className="text-center">
					By clicking the button above, I agree to LiveRecover{' '}
					<Link href={termsUrl} target="_blank">
						<Text.Span size={'sm'} variant={'primary'}>
							Terms of Service
						</Text.Span>
					</Link>{' '}
					and{' '}
					<Link href={privacyUrl} target="_blank">
						<Text.Span size={'sm'} variant={'primary'}>
							Privacy Policy
						</Text.Span>
					</Link>
					.
				</Text>
			</Layout>
			<Spacer vertical={20} />
		</Layout>
	);
}

export function TokenLoginForm({ callbackUrl }: { callbackUrl: string }) {
	const router = useRouter();
	const [params] = useQueryStates({
		token: parseAsString,
	});

	useEffect(() => {
		if (!params.token) {
			router.replace(callbackUrl);
			return;
		}

		handleSignIn(params.token, callbackUrl);
	}, [params.token]);

	async function handleSignIn(token: string, callbackUrl: string) {
		const authSession = await signIn({
			provider: 'credentials',
			token,
			redirectUrl: callbackUrl,
		});
	}

	return null;
}
