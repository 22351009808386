// sort-imports-ignore
import '@shopify/shopify-api/adapters/web-api';

import { AppError } from '@voyage-lab/core-common';
import type { ShopifyTypes } from '@voyage-lab/shopify-api';
import { DeepPartial } from '@voyage-lab/util';
import { BaseIntegrationResourceProvider } from '../../base';
import { BigCommerce } from './bigcommerce';
import { ApiResponse, Checkout, Webhook, Order } from './types';

export class BigCommerceResource extends BaseIntegrationResourceProvider {
	override async getCustomer(args: { id?: string }) {
		const response = await this.fetch(`/customers/${args.id}`);
		const data = await response.json();

		if (!data) {
			console.error(new Error('Failed to get customer'), {
				response,
			});
			throw new Error('Failed to get customer');
		}

		return {
			customer: {
				id: data.id,
				email: data.email,
				firstName: data.first_name,
				lastName: data.last_name,
				addresses: [],
				hasTimelineComment: false,
			},
		} as unknown as ShopifyTypes.QueryRoot;
	}

	override async getCart(args: { id?: string }) {
		return Promise.resolve({} as ShopifyTypes.QueryRoot);
	}

	override async getCheckout(args: { id?: string }) {
		const checkoutRes = await this.fetch<ApiResponse<Checkout>>(`/checkouts/${args.id ?? ''}`);
		if (!checkoutRes.data?.data) {
			const error = new Error(`BigCommerce -> getCheckout -> ${args.id} \n`);
			console.error(new Error(error.message), JSON.stringify({ response: checkoutRes }));
			throw error;
		}

		function transformCheckout(checkout: Checkout): DeepPartial<ShopifyTypes.ICheckout> {
			return {
				// @ts-expect-error: BigCommerce returns a string for the checkout ID
				id: checkout.id,
				email: checkout?.billing_address?.email,
				created_at: new Date(checkout?.created_time).toISOString(),
				updated_at: new Date(checkout?.updated_time).toISOString(),
				currency: checkout?.cart?.currency?.code,
				customer: checkout?.cart?.customer_id
					? {
							id: checkout.cart.customer_id,
							email: checkout.cart?.email,
						}
					: undefined,
				customer_id: checkout?.cart?.customer_id,
				customer_locale: 'en', // BigCommerce doesn't provide this directly
				billing_address: checkout?.billing_address
					? {
							address1: checkout.billing_address.address1 || '',
							address2: checkout.billing_address.address2 || '',
							city: checkout.billing_address.city || '',
							company: checkout.billing_address.company || '',
							country: checkout.billing_address.country || '',
							country_code: checkout.billing_address.country_code || '',
							first_name: checkout.billing_address.first_name || '',
							last_name: checkout.billing_address.last_name || '',
							phone: checkout.billing_address.phone || '',
							province: checkout.billing_address.state_or_province || '',
							province_code: checkout.billing_address.state_or_province_code || '',
							zip: checkout.billing_address.postal_code || '',
						}
					: undefined,
				shipping_address: checkout?.consignments?.[0]?.shipping_address
					? {
							address1: checkout.consignments[0].shipping_address.address1 || '',
							address2: checkout.consignments[0].shipping_address.address2 || '',
							city: checkout.consignments[0].shipping_address.city || '',
							company: checkout.consignments[0].shipping_address.company || '',
							country: checkout.consignments[0].shipping_address.country || '',
							country_code: checkout.consignments[0].shipping_address.country_code || '',
							first_name: checkout.consignments[0].shipping_address.first_name || '',
							last_name: checkout.consignments[0].shipping_address.last_name || '',
							phone: checkout.consignments[0].shipping_address.phone || '',
							province: checkout.consignments[0].shipping_address.state_or_province || '',
							province_code: checkout.consignments[0].shipping_address.state_or_province_code || '',
							zip: checkout.consignments[0].shipping_address.postal_code || '',
						}
					: undefined,
				line_items:
					checkout?.cart?.line_items?.physical_items?.map((item) => ({
						id: item?.id ? Number(item.id) : undefined,
						price: item?.sale_price?.toString() || '0',
						product_id: item?.product_id,
						quantity: item?.quantity || 0,
						requires_shipping: item?.is_require_shipping ?? false,
						sku: item?.sku || '',
						title: item?.name || '',
						variant_id: item?.variant_id,
						variant_title: '', // BigCommerce doesn't provide this directly
						vendor: '', // BigCommerce doesn't provide this directly
						name: item?.name || '',
						gift_card: false, // BigCommerce handles gift cards separately
						taxable: item?.is_taxable ?? false,
						total_discount: item?.discount_amount?.toString() || '0',
					})) || [],
				note: checkout?.customer_message || '',
				subtotal_price: checkout?.subtotal_inc_tax?.toString() || '0',
				total_price: checkout?.grand_total?.toString() || '0',
				total_tax: checkout?.tax_total?.toString() || '0',
				taxes_included: checkout?.cart?.tax_included ?? false,
				total_discounts: checkout?.cart?.discount_amount?.toString() || '0',
				discount_codes:
					checkout?.coupons?.map((code) => ({
						...code,
						description: code.display_name,
						code: code.code,
						value: code.code,
						amount: code.discounted_amount?.toString() || '0',
						value_type: code.coupon_type.includes('percentage') ? 'percentage' : 'fixed_amount',
					})) || [],
				requires_shipping:
					checkout?.cart?.line_items?.physical_items?.some((item) => item?.is_require_shipping) ?? false,
				// @ts-expect-error: BigCommerce returns a string for the order ID
				order: checkout?.order_id
					? {
							id: checkout.order_id,
							name: checkout.order_id,
						}
					: undefined,
				shipping_lines:
					checkout?.consignments?.map((consignment) => ({
						price: consignment?.shipping_cost_inc_tax?.toString() || '0',
						title: consignment?.selected_shipping_option?.description || '',
						code: consignment?.selected_shipping_option?.type || '',
					})) || [],
				tax_lines:
					checkout?.taxes?.map((tax) => ({
						price: tax?.amount?.toString() || '0',
						title: tax?.name || '',
						rate: 0, // BigCommerce doesn't provide tax rate directly
					})) || [],
				token: checkout?.id || '',
				cart_token: checkout?.cart?.id || '',
				phone: checkout?.billing_address?.phone || null,
			};
		}

		const transformedCheckout = transformCheckout(checkoutRes.data.data);
		console.info(
			`BigCommerce -> getCheckout -> ${args.id} \n`,
			JSON.stringify({
				raw: checkoutRes.data.data,
				transformed: transformedCheckout,
			})
		);
		return {
			checkout: transformedCheckout,
		};
	}

	override async getOrder(args: { id?: string }) {
		const orderRes = await this.fetch<Order>(`/orders/${args.id ?? ''}`, { apiVersion: 'v3:v2' });
		if (!orderRes.data) {
			const error = new Error(`BigCommerce -> getOrder -> ${args.id} \n`);
			console.error(new Error(error.message), JSON.stringify({ response: orderRes }));
			throw error;
		}

		function transformOrder(order: Order): DeepPartial<ShopifyTypes.IOrder> {
			return {
				id: order.id,
				email: order.billing_address?.email,
				created_at: new Date(order.date_created).toISOString(),
				updated_at: new Date(order.date_modified).toISOString(),
				number: order.id,
				order_number: order.id,
				currency: order.currency_code,
				total_price: order.total_inc_tax,
				subtotal_price: order.subtotal_inc_tax,
				total_tax: order.total_tax,
				total_discounts: order.discount_amount,
				total_shipping_price_set: {
					shop_money: {
						amount: parseFloat(order.shipping_cost_inc_tax),
						currency_code: order.currency_code,
					},
					presentment_money: {
						amount: parseFloat(order.shipping_cost_inc_tax),
						currency_code: order.currency_code,
					},
				},
				financial_status:
					order.payment_status === 'paid'
						? 'paid'
						: order.payment_status === 'pending'
							? 'pending'
							: order.payment_status === 'refunded'
								? 'refunded'
								: 'pending',
				fulfillment_status:
					order.status === 'Shipped'
						? 'fulfilled'
						: order.status === 'Partially Shipped'
							? 'partial'
							: order.status === 'Cancelled'
								? 'restocked'
								: null,
				confirmed: true,
				// discount_codes: order.coupons?.map((coupon) => ({
				// 	description: coupon.display_name,
				// 	value: coupon.code,
				// 	amount: coupon.discounted_amount?.toString() || '0',
				// 	value_type: coupon.coupon_type.includes('percentage') ? 'percentage' : 'fixed_amount',
				// })) || [],
				contact_email: order.billing_address?.email,
				billing_address: order.billing_address
					? {
							address1: order.billing_address.address1 || '',
							address2: order.billing_address.address2 || '',
							city: order.billing_address.city || '',
							company: order.billing_address.company || '',
							country: order.billing_address.country || '',
							country_code: order.billing_address.country_code || '',
							first_name: order.billing_address.first_name || '',
							last_name: order.billing_address.last_name || '',
							phone: order.billing_address.phone || '',
							province: order.billing_address.state_or_province || '',
							province_code: order.billing_address.state_or_province_code || '',
							zip: order.billing_address.postal_code || '',
						}
					: undefined,
				shipping_address: order.billing_address
					? {
							address1: order.billing_address.address1 || '',
							address2: order.billing_address.address2 || '',
							city: order.billing_address.city || '',
							company: order.billing_address.company || '',
							country: order.billing_address.country || '',
							country_code: order.billing_address.country_code || '',
							first_name: order.billing_address.first_name || '',
							last_name: order.billing_address.last_name || '',
							phone: order.billing_address.phone || '',
							province: order.billing_address.state_or_province || '',
							province_code: order.billing_address.state_or_province_code || '',
							zip: order.billing_address.postal_code || '',
						}
					: undefined,
				customer: {
					id: order.customer_id,
					email: order.billing_address?.email,
					accepts_marketing: order.is_email_opt_in,
					created_at: order.date_created,
					updated_at: order.date_modified,
					first_name: order.billing_address?.first_name || '',
					last_name: order.billing_address?.last_name || '',
					orders_count: 0, // Not available in BigCommerce order data
					state: 'enabled',
					total_spent: order.total_inc_tax,
					phone: order.billing_address?.phone || undefined,
				},
				test: false,
				taxes_included: order.is_tax_inclusive_pricing,
				tax_lines: [
					{
						price: order.total_tax,
						rate: 0, // Not directly available in BigCommerce
						title: 'Tax',
					},
				],
				tags: '',
				gateway: order.payment_method,
				total_weight: 0, // Not directly available in BigCommerce order data
				browser_ip: order.ip_address,
				cart_token: order.cart_id,
				processing_method: 'direct',
				source_name: order.order_source || 'web',
				total_outstanding: '0.00', // Not directly available in BigCommerce
				total_tip_received: '0.00',
				payment_gateway_names: [order.payment_method],
				presentment_currency: order.currency_code,
				note_attributes: [
					{
						name: 'status_id',
						value: String(order.status_id),
					},
				],
				client_details: {
					accept_language: order.customer_locale,
					browser_height: null,
					browser_ip: order.ip_address,
					browser_width: null,
					session_hash: null,
					user_agent: null,
				},
			};
		}

		const transformedOrder = transformOrder(orderRes.data);
		console.info(
			`BigCommerce -> getOrder -> ${args.id} \n`,
			JSON.stringify({
				raw: orderRes.data,
				transformed: transformedOrder,
			})
		);

		return {
			order: transformedOrder,
		};
	}

	override async syncHooks(): Promise<DeepPartial<ShopifyTypes.QueryRoot>> {
		const hookAddress = this.provider.credentials.webhookAddress;
		if (!hookAddress) throw new Error('Webhook address is not set');

		const registeredHooks: Webhook[] = [];
		const existingHooks = (await this.fetch<ApiResponse<Webhook[]>>('/hooks')).data;
		if (!existingHooks) throw new Error('Failed to get existing hooks');

		const missingHooks = BigCommerce.WEBHOOK_TOPICS.filter(
			(scope) => !existingHooks.data.some((hook) => hook.scope === scope && hook.destination === hookAddress)
		);
		const unrelatedHooks = existingHooks.data.filter(
			(hook) => !BigCommerce.WEBHOOK_TOPICS.includes(hook.scope as (typeof BigCommerce.WEBHOOK_TOPICS)[number])
		);

		for (const scope of missingHooks) {
			const createdHookRes = await this.fetch<ApiResponse<Webhook>>('/hooks', {
				method: 'POST',
				data: {
					scope,
					destination: hookAddress,
				},
			});
			if (!createdHookRes.data?.data) {
				const error = new Error('Failed to register hook for topic: ' + scope);
				console.error(error, {
					response: createdHookRes.data,
				});
				throw new AppError(error.message);
			}
			if (createdHookRes.ok && createdHookRes.data.data.id) registeredHooks.push(createdHookRes.data.data);
		}

		// Delete unrelated hooks
		for (const hook of unrelatedHooks) {
			await this.fetch(`/hooks/${hook.id}`, { method: 'DELETE' });
		}

		function transformHook(hook: Webhook): DeepPartial<ShopifyTypes.WebhookSubscription> {
			return {
				id: String(hook.id),
				topic: hook.scope as ShopifyTypes.WebhookSubscriptionTopic,
				createdAt: new Date(hook.created_at).toISOString(),
				updatedAt: new Date(hook.updated_at).toISOString(),
				endpoint: {
					callbackUrl: hook.destination,
				},
			};
		}

		console.log('Sync Hooks', {
			created: registeredHooks.map((hook) => hook.scope),
			existing: existingHooks.data.map((hook) => hook.scope),
			missing: missingHooks,
			unrelated: unrelatedHooks.map((hook) => hook.scope),
		});

		return {
			webhookSubscriptions: {
				nodes: registeredHooks.map(transformHook),
			},
		};
	}
}
