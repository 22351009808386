import { v4 as uuid } from 'uuid';

import { Constants, type DatabaseEntity, type DatabaseEnum } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/schema';
import { Helpers } from '@voyage-lab/util';

import type {
	Flow,
	FlowProcessorArg,
	FlowProcessorCandidate,
	FlowProcessorExecuteCandidate,
	FlowProcessorProcessResult,
} from './processor';
import { FlowProcessor } from './processor';

export class FlowProcessorCart extends FlowProcessor {
	static override type: DatabaseEnum['t_workflows_type'] = 'cart';

	override async process({
		flows,
		data,
	}: {
		flows: Flow[];
		data: FlowProcessorCandidate;
	}): Promise<FlowProcessorProcessResult> {
		let matchedFlow: Flow | null = null;
		const contactChannelRes = await this.dbClient
			.from('contact_channels')
			.select('*,contacts(*)')
			.eq('id', data.channel_id)
			.maybeSingle();

		const contactChannel = contactChannelRes.data;
		const contact = contactChannel?.contacts;

		const flowEvent: DatabaseEntity['workflow_goal_state_change_events'] = {
			id: uuid(),
			state: 'filtered_out',
			workflow_id: '',
			extra_data: {},
			created_at: new Date().toISOString(),
			checkout_id: null,
			conversation_id: null,
			cart_id: data.cart_id || null,
			order_id: null,
		};

		if (!contactChannel || !contact) flowEvent.state = 'channel_missing';
		// TODO: Take reEngagePeriodDays from brand, currently hardcoded to 30 days
		const hasEngagedRecently = contactChannel ? this.hasEngagedRecently(contactChannel, 30) : false;

		if (hasEngagedRecently) flowEvent.state = 'recently_engaged';

		for await (const flow of flows) {
			if (!contactChannel || !contact || hasEngagedRecently) continue;
			const evaluationResult = await this.filterEvaluator(flow, {
				contact,
				channel: contactChannel,
				flows,
				event: data.event,
			});

			if (evaluationResult) {
				flowEvent.state === 'started';
				flowEvent.workflow_id = flow.id;

				if (flow.action.type === 'skip') {
					flowEvent.state === 'filtered_out';
				}

				matchedFlow = flow;
			}
		}

		// If there is no match then we use the default rull less workflow
		const defaultRullLessWorkflow = flows?.find((w) => w.rules?.rules?.length === 0);
		if (!matchedFlow && defaultRullLessWorkflow) {
			matchedFlow = defaultRullLessWorkflow;
			flowEvent.state = 'started';
			flowEvent.workflow_id = defaultRullLessWorkflow.id;
		}

		if (flowEvent.workflow_id)
			await this.flowData.createEvent({
				data: flowEvent,
			});

		if (!matchedFlow || !contactChannel || !contact) return null;

		return {
			...data,
			channel: contactChannel,
			contact,
			flow_id: matchedFlow.id,
			flow: matchedFlow,
			flowEvents: [flowEvent],
		};
	}

	async execute({ flow, data }: { flow: Flow; data: FlowProcessorExecuteCandidate }) {
		// Validations
		if (!flow) throw new Error('Now flow has been determined');

		// Assignments
		let discount = null;
		let discountRule = null;

		const messageHasDiscount = flow.message?.some((m) => m.type === 'discount_code');
		if (flow?.discount_rule_id && messageHasDiscount) {
			const discountRuleRes = await this.flowData.getSingleDiscountRule({ id: flow.discount_rule_id });
			if (discountRuleRes.data) {
				if (flow.brand_integrations.integration_id === Constants.Integration.ShopifyIntegrationId) {
					discount = await this.integrationProvider.generateDiscount(
						discountRuleRes.data,
						flow.brand_integrations.settings
					);
					discountRule = discountRuleRes.data;
				}
			}
		}

		const hydMsg = this.hydratedMessage.prepare(flow.message || [], {
			discount_code: discount?.code,
			workflow: {
				...flow,
				discount_rule: discountRule,
				integration_settings: flow.brand_integrations.settings as unknown as TypesT.BrandIntegrationJson,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} as any,
		});

		const message = await hydMsg.render({
			checkout: data.event,
			channel: data.channel,
			contact: data.contact,
			brand: flow.brand_integrations.brands,
		});
		const timezone = (data.channel.extra_data || {}).timezone;

		const scheduledFor = Helpers.Time.getZonedTime({
			timezone,
			province: data.channel.extra_data.timezone,
			time: new Date(),
		});

		let scheduledForIso = scheduledFor.toISOString();
		if (this.isTestingEnv) scheduledForIso = new Date().toISOString();

		const initiatedConversation = await this.conversationData.initiate({
			data: {
				conversation: {
					brand_id: flow.brand_integrations.brand_id,
					channel_id: data.channel.id,
					workflow_id: flow.id,
					cart_id: data.cart_id || null,
					checkout_id: data.checkout_id || null,
				},
				message: {
					body: message,
					scheduled_for: scheduledForIso,
				},
			},
		});

		if (discount) {
			await this.dbClient.from('discount_codes').insert({
				id: uuid(),
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
				brand_id: flow.brand_integrations.brand_id,
				conversation_id: initiatedConversation.conversation.id,
				code: discount.code,
				external_id: discount.external_id,
			});
		}

		await hydMsg.commitShortenedUrl({
			// oid: candidate.data.id,
			wfid: flow.id,
			cvid: initiatedConversation.conversation.id,
			mid: initiatedConversation.message.id,
		});

		return flow;
	}

	override async executeAction({ flow, data }: { flow: Flow; data: FlowProcessorExecuteCandidate }) {
		// TODO: Move from apps/web-admin/front/home to here

		const actionResult = {
			flow,
			agentReply: flow.action.agents_response,
		};

		return actionResult;
	}

	override async getCandidates() {
		const query = `
WITH
	candidate_carts AS (
		SELECT
			c.id id,
			c.id cart_id,
			c.contact_id,
			cc.id channel_id,
			bi.brand_id,
			row_to_json(c) AS event,
			array_agg(w.id) flow_ids
		FROM
			carts c
			JOIN contact_channels cc ON cc.contact_id = c.contact_id
			JOIN brand_integrations bi ON c.brand_integration_id = bi.id
			JOIN brands b ON b.id = bi.brand_id
			JOIN workflows w ON w.brand_integration_id = c.brand_integration_id
			LEFT JOIN checkouts ck ON ck.external_cart_id = c.external_id
		WHERE
			c.updated_at > now() - interval '3 hours'
			AND cc.username LIKE '+1%'
			AND (
				cc.last_engaged_at IS NULL
				OR cc.last_engaged_at < now() - (b.re_engage_period * interval '1 day')
			)
			AND ck.external_cart_id IS NULL
			AND c.state = 'pending'
			AND w.type NOT IN ('order', 'schedule', 'tap_to_text')
			AND w.is_paused = 'False'
			AND c.updated_at + w.delay_minutes * interval '1 min' < now()
		GROUP BY
			1,
			2,
			3,
			4,
			5
	),
	unique_carts AS (
		SELECT DISTINCT
			ON (cart_id) *
		FROM
			candidate_carts
	),
	update_cart_status AS (
		UPDATE carts
		SET
			state = 'abandoned'
		FROM
			unique_carts
		WHERE
			carts.id = unique_carts.cart_id
	)
SELECT DISTINCT
	ON (channel_id) *
FROM
	unique_carts;
	`;
		const candidates = await this.pgClient.query<FlowProcessorCandidate>(query);
		return candidates?.rows ?? [];
	}

	async mapCartToCheckout(cart: DatabaseEntity['carts']): Promise<DatabaseEntity['checkouts']> {
		return {
			...cart,
			cleaned_raw_data: {
				detail: {
					payload: cart,
				},
			},
			extra_data: {
				abandoned_checkout_url: cart,
			},
		} as unknown as DatabaseEntity['checkouts'];
	}
}
