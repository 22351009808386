import { PostgrestClient } from '@supabase/postgrest-js';
import * as cfetch from 'cross-fetch';
import * as jwt from 'jsonwebtoken';

import type { PostgrestClientType } from '@voyage-lab/db';

/** Postgrest client */
export const createRestClient = (
	apiUrl: string,
	jwtSecret: string,
	useCrossFetch = true,
	customFetch?: typeof fetch
): PostgrestClientType => {
	const jwtToken = jwt.sign({ iss: 'lr', role: 'postgres' }, jwtSecret);
	return new PostgrestClient(apiUrl, {
		headers: jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {},
		fetch: customFetch
			? customFetch
			: !useCrossFetch
				? undefined
				: (input, init) => {
						// fetch with error logging interceptor
						return cfetch.fetch(input, init).then(async (response) => {
							if (!response.ok) {
								console.error({
									status: response.status,
									statusText: response.statusText,
									url: response.url,
									body: await response.clone().json(),
								});
							}
							return response;
						});
					},
	});
};
