export const getWelcomeMessage = (firstName: string) => {
	const messages = ["You're welcome! 😊", "You're welcome! :)"];

	const messagesForFirstName = [
		`You're welcome ${firstName}! 😊`,
		`You're welcome ${firstName}! :)`,
		`You're welcome ${firstName}!`,
		`You're welcome! 😊`,
	];

	if (!firstName) return messages[Math.floor(Math.random() * messages.length)];

	return messagesForFirstName[Math.floor(Math.random() * messagesForFirstName.length)];
};

export const getNudgeMessage = (props: { firstName?: string; agentName?: string | null; companyName?: string }) => {
	let intro = '';
	if (props.firstName) {
		intro += `Hey, ${props.firstName},`;
	} else {
		intro += `Hey,`;
	}
	if (props.companyName) {
		if (props.agentName) {
			intro += ` this is ${props.agentName} from ${props.companyName}.`;
		} else {
			intro += ` checking in from ${props.companyName}.`;
		}
	}
	const nudge = ` Just wanted to see if you had any questions or if there was anything else I can do to assist 😊`;
	return intro + nudge;
};
