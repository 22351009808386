/**
 * Sorts the keys of an object based on the specified method
 * @param obj - The object whose keys need to be sorted
 * @param method - The sorting method to use:
 *   - 'asc': Sort keys alphabetically in ascending order
 *   - 'desc': Sort keys alphabetically in descending order
 *   - 'len_asc': Sort keys by length in ascending order (with alphabetical subsort)
 *   - 'len_desc': Sort keys by length in descending order (with alphabetical subsort)
 * @returns A new object with sorted keys maintaining the original values
 * @example
 * ```ts
 * sortKeys({ c: 1, a: 2, b: 3 }, 'asc') // { a: 2, b: 3, c: 1 }
 * sortKeys({ long: 1, short: 2 }, 'len_asc') // { short: 2, long: 1 }
 * ```
 */
export function sortKeys<T extends Record<string, unknown>>(
	obj: T,
	method: 'asc' | 'desc' | 'len_asc' | 'len_desc' = 'asc'
): T {
	return Object.fromEntries(
		Object.entries(obj).sort(([keyA], [keyB]) => {
			if (method === 'asc') return keyA.localeCompare(keyB);
			if (method === 'desc') return keyB.localeCompare(keyA);
			if (method === 'len_asc') {
				const lengthDiff = keyA.length - keyB.length;
				return lengthDiff === 0 ? keyA.localeCompare(keyB) : lengthDiff;
			}
			if (method === 'len_desc') {
				const lengthDiff = keyB.length - keyA.length;
				return lengthDiff === 0 ? keyA.localeCompare(keyB) : lengthDiff;
			}
			return 0;
		})
	) as T;
}
