import { PartialExcept } from 'libs/util/src/types';

import { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';

/**
 * Implements logic to determine if an order was attributed to an agent through either a link or discount code.
 * This class handles checking for both link-based and code-based attribution methods, considering brand-specific
 * attribution rules and time windows.
 */
export class AttributionChecker {
	#dataClient: PostgrestClientType;

	/**
	 * Creates a new instance of AttributionChecker.
	 * @param {Object} params - The constructor parameters
	 * @param {PostgrestClientType} params.dataClient - The database client for querying attribution data
	 */
	constructor({ dataClient }: { dataClient: PostgrestClientType }) {
		this.#dataClient = dataClient;
	}

	/**
	 * Checks if an order can be attributed based on links shared in conversation messages.
	 * @param {AttributionCheckArgs} params - The parameters for checking link attribution
	 * @param {DatabaseEntity['brand_integrations']} params.integration - The brand integration details
	 * @param {DatabaseEntity['contact_channels'][]} params.channels - The communication channels
	 * @param {Pick<DatabaseEntity['brands'], 'attribution_type' | 'attribution_hard_cap' | 'extra_data' | 'id'>} params.brand - The brand configuration
	 * @param {DatabaseEntity['conversations'] | null} params.conversation - The conversation details
	 * @returns {Promise<Partial<DatabaseEntity<'insert'>['workflow_goals']> | null>} Attribution goal data if link attribution is found, null otherwise
	 */
	async checkLinkAttribution({
		integration,
		channels,
		brand,
		conversation,
	}: AttributionCheckArgs): Promise<Partial<DatabaseEntity<'insert'>['workflow_goals']> | null> {
		// Prop Validation
		if (!conversation) return null;

		// Initialization
		const minimumSupportedAttrDate = new Date(Date.now() - brand.attribution_hard_cap * 24 * 60 * 60 * 1000);
		const recentMessagesWithLink = await this.#dataClient
			.from('conversation_messages')
			.select()
			.eq('conversation_id', conversation.id)
			.ilike('body', 'https://')
			.gte('created_at', minimumSupportedAttrDate.toISOString());

		const messages = recentMessagesWithLink.data;
		if (!messages?.length) return null;

		return {
			attribution: 'link',
			extra_data: {
				message_ids: messages.map((message) => message.id),
			},
		};
	}

	/**
	 * Checks if an order can be attributed based on discount codes used.
	 * Supports both static codes (prefixed with 'LR-') and dynamic codes stored in the database.
	 * @param {AttributionCheckArgs} params - The parameters for checking code attribution
	 * @param {DatabaseEntity['brand_integrations']} params.integration - The brand integration details
	 * @param {EventData} params.event - The event data containing order information
	 * @returns {Promise<Partial<DatabaseEntity<'insert'>['workflow_goals']> | null>} Attribution goal data if code attribution is found, null otherwise
	 */
	async checkCodeAttribution({
		integration,
		event,
	}: AttributionCheckArgs): Promise<Partial<DatabaseEntity<'insert'>['workflow_goals']> | null> {
		// Prop Validation
		if (!isOrder(event)) return null;

		// Initialization
		const codesInOrder =
			event.cleaned_raw_data.detail.payload.discount_codes.map(
				// @ts-expect-error: BigCommerce returns a string for the order ID
				(code) => code.code || code.value
			) || [];

		// Prefix Check
		const staticCoupons = codesInOrder.filter((code) => code.startsWith('LR-'));
		if (staticCoupons.length) {
			return {
				attribution: 'discount',
				extra_data: {
					codes: staticCoupons,
					code_detection: 'prefix',
				},
			};
		}

		// Exact Check
		const matchingCodesRes = await this.#dataClient
			.from('discount_codes')
			.select()
			.eq('brand_id', integration.brand_id)
			.in('code', codesInOrder);

		const dynamicCoupons = matchingCodesRes.data?.map((code) => code.code) || [];
		if (dynamicCoupons.length) {
			return {
				attribution: 'discount',
				extra_data: {
					codes: dynamicCoupons,
					code_detection: 'exact',
				},
			};
		}

		return null;
	}

	/**
	 * Performs a complete attribution check considering both link and code-based attribution methods.
	 * Prioritizes code attribution over link attribution if both are present.
	 * @param {AttributionCheckArgs} props - The parameters for performing the attribution check
	 * @returns {Promise<{goal: Partial<DatabaseEntity<'insert'>['workflow_goals']>, conversation: any} | null>} Attribution results including the goal and associated conversation
	 */
	async check(props: AttributionCheckArgs) {
		// Initialization
		const { event, integration, channels, cart, brand } = props;
		const conversationQuery = this.#dataClient
			.from('conversations')
			.select('id,workflow_id')
			.in(
				'channel_id',
				channels.map((channel) => channel.id)
			)
			.eq('brand_id', integration.brand_id)
			.order('created_at', { ascending: false })
			.limit(1);
		const cartId = cart?.id || isCart(event) ? event.id : undefined;
		if (isOrder(event) && event.id) conversationQuery.eq('order_id', event.id);
		if (isCheckout(event) && event.id) conversationQuery.eq('checkout_id', event.id);
		if (cartId) conversationQuery.eq('cart_id', cartId);
		const conversationRes = await conversationQuery.maybeSingle();
		const conversation = conversationRes?.data;

		// Optional Validation
		if (!conversation) {
			const error = new Error(`AttributionChecker: Conversation not found`);
			console.error(new Error(error.message), JSON.stringify({ props, conversationRes }));
			// TODO: Decide what to do when we got got no conversatoin associated with a order/checkout/cart event
		}

		// Code Attribution
		const codeGoal = await this.checkCodeAttribution(props);
		if (codeGoal) return { goal: codeGoal, conversation };

		// Link Attribution
		if (brand.attribution_type === 'code_only') return null;
		const linkGoal = await this.checkLinkAttribution({ ...props, conversation });
		if (!linkGoal) return null;
		return { goal: linkGoal, conversation };
	}
}

/**
 * Arguments required for performing attribution checks
 */
type AttributionCheckArgs = {
	/** The event data that triggered the attribution check */
	event: EventData;
	/** The brand integration configuration */
	integration: DatabaseEntity['brand_integrations'];
	/** Brand-specific attribution settings */
	brand: Pick<DatabaseEntity['brands'], 'attribution_type' | 'attribution_hard_cap' | 'extra_data' | 'id'>;
	/** Available communication channels */
	channels: DatabaseEntity['contact_channels'][];
	/** Associated cart data, if any */
	cart: DatabaseEntity['carts'] | null;
	/** Associated conversation data, if any */
	conversation?: PartialExcept<DatabaseEntity['conversations'], 'id' | 'workflow_id'> | null;
};

/**
 * Union type representing different types of events that can trigger attribution checks
 */
type EventData =
	| DatabaseEntity<'insert'>['orders']
	| DatabaseEntity<'insert'>['checkouts']
	| DatabaseEntity<'insert'>['carts'];

const isOrder = (data: EventData): data is DatabaseEntity['orders'] => {
	const eventData = data as DatabaseEntity['orders'];
	const rawData = eventData?.cleaned_raw_data?.detail?.payload;
	return isNonNullObject(rawData) && ('order_number' in rawData || 'discount_codes' in rawData);
};

const isNonNullObject = (value: unknown): value is Record<string, unknown> => {
	return typeof value === 'object' && value !== null && !Array.isArray(value);
};

const isCheckout = (data: EventData): data is DatabaseEntity['checkouts'] => {
	const eventData = data as DatabaseEntity['checkouts'];
	const rawData = eventData?.cleaned_raw_data?.detail?.payload;
	return isNonNullObject(rawData) && 'token' in rawData && 'checkout_token' in rawData;
};

const isCart = (data: EventData): data is DatabaseEntity['carts'] => {
	const eventData = data as DatabaseEntity['carts'];
	const rawData = eventData?.cleaned_raw_data?.detail?.payload;
	return isNonNullObject(rawData) && 'token' in rawData && 'items' in rawData;
};
