import type { TokenPolicy } from '@voyage-lab/core-auth';

/**
 * Checks if any of the provided permissions match against the required policies
 *
 * @param policies - Array of required TokenPolicy to check against
 * @param permissions - Array of TokenPolicy that the user has
 * @returns boolean indicating if the user has at least one matching permission
 *
 * @example
 * const policies = ['admin:read', 'admin:write'];
 * const userPermissions = ['admin:read'];
 * const allowed = hasPermission(policies, userPermissions); // true
 */
export const hasPermission = (policies: TokenPolicy[] = [], permissions: TokenPolicy[] = []) => {
	const isPermitted = permissions.some((d) => {
		return policies.some((p) => p.match(removeStarPrefix(d)) || d.match(removeStarPrefix(p)));
	});
	return isPermitted;
};

const removeStarPrefix = (policy: string) => {
	if (policy.startsWith('*:')) return policy.replace('*:', '');
	return policy;
};
