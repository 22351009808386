// Generated by ts-to-zod
import { z } from 'zod';

export const conversationJsonSchema = z.object({
	extra_data: z.object({
		front_id: z.string(),
	}),
});

export const contactChannelIdSchema = z.object({
	id: z.string(),
});
