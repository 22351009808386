import { CubeUtils, type GraphqlQueryTypes } from '@voyage-lab/cube';
import { Helpers } from '@voyage-lab/util';

export type WorkflowStatsType = {
	description: string;
	value: number | string;
	type: 'sent' | 'replied' | 'orders' | 'sales';
	chart: {
		x: (Partial<GraphqlQueryTypes.TimeDimension> | undefined | null)[];
		y: number[];
	};
	percentage: number;
	format: (value: string | number) => string;
};

export const generateStats = (
	data?: GraphqlQueryTypes.GetWorkflowStatsQuery,
	dateRange?: { from: string; to: string }
) => {
	const currentSent = Helpers.Array.sum(
		data?.curr_conversation_messages,
		(e) => e.conversation_messages.sent_message_count || 0
	);
	const currentReplied = Helpers.Array.sum(
		data?.curr_conversation_messages,
		(e) => e.conversation_messages.reply_count || 0
	);
	const currentOrdered = Helpers.Array.sum(data?.curr_order, (e) => e.orders.recovered_count || 0);
	const currentSales = Helpers.Array.sum(data?.curr_order, (e) => e.orders.recovered_sales || 0);

	const previousSent = Helpers.Array.sum(
		data?.prev_conversation_messages,
		(e) => e.conversation_messages.sent_message_count || 0
	);
	const previousReplied = Helpers.Array.sum(
		data?.prev_conversation_messages,
		(e) => e.conversation_messages.reply_count || 0
	);
	const previousOrdered = Helpers.Array.sum(data?.prev_order, (e) => e.orders.recovered_count || 0);
	const previousSales = Helpers.Array.sum(data?.prev_order, (e) => e.orders.recovered_sales || 0);

	const stats = [
		{
			description: 'Sent',
			type: 'sent',
			value: Helpers.Number.formatToString(currentSent || 0),
			format: (value: string | number) => Helpers.Number.formatToString(+value, 0),
			percentage: Helpers.Number.calcIncrementPercentage(previousSent || 0, currentSent || 0, 1),
			chart: fillMissingDates(
				{
					x:
						data?.curr_conversation_messages
							?.map((e) => e.conversation_messages.created_at)
							.filter((e) => !!e) || [],
					y:
						data?.curr_conversation_messages?.map((e) => e.conversation_messages.sent_message_count || 0) ||
						[],
				},
				dateRange
			),
		},
		{
			description: 'Replied',
			type: 'replied',
			value: Helpers.Number.formatToString(currentReplied || 0),
			format: (value: string | number) => Helpers.Number.formatToString(+value, 0),
			percentage: Helpers.Number.calcIncrementPercentage(previousReplied || 0, currentReplied || 0, 1),
			chart: fillMissingDates(
				{
					x:
						data?.curr_conversation_messages
							?.map((e) => e.conversation_messages.created_at)
							.filter((e) => !!e) || [],
					y: data?.curr_conversation_messages?.map((e) => e.conversation_messages.reply_count || 0) || [],
				},
				dateRange
			),
		},
		{
			description: 'Orders',
			value: Helpers.Number.formatToString(currentOrdered || 0),
			format: (value: string | number) => Helpers.Number.formatToString(+value, 0),
			percentage: Helpers.Number.calcIncrementPercentage(previousOrdered || 0, currentOrdered || 0, 1),
			type: 'orders',
			chart: fillMissingDates(
				{
					x: data?.curr_order?.map((e) => e.orders.created_at).filter((e) => !!e) || [],
					y: data?.curr_order?.map((e) => e.orders.recovered_count || 0) || [],
				},
				dateRange
			),
		},
		{
			description: 'Sales',
			value: '$' + Helpers.Number.abbreviateNumber(currentSales || 0),
			format: (value: string | number) => '$' + Helpers.Number.formatToString(+value, 1),
			percentage: Helpers.Number.calcIncrementPercentage(previousSales || 0, currentSales || 0, 1),
			type: 'sales',
			chart: fillMissingDates(
				{
					x: data?.curr_order?.map((e) => e.orders.created_at).filter((e) => !!e) || [],
					y: data?.curr_order?.map((e) => e.orders.recovered_sales || 0) || [],
				},
				dateRange
			),
		},
	] as WorkflowStatsType[];

	return { stats };
};

export const fillMissingDates = (data: WorkflowStatsType['chart'], dateRange?: { from: string; to: string }) => {
	if (!dateRange) return data;

	const range = CubeUtils.generateCubeDateSeries(dateRange.from, dateRange.to);

	const y = range.map((e) => {
		const existing = data.x.findIndex((x) => CubeUtils.parseTimeDimension(x) === CubeUtils.parseTimeDimension(e));
		return existing === -1 ? 0 : data.y[existing];
	});

	return { x: range, y };
};
