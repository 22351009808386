import { AppError } from '@voyage-lab/core-common';
import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import { Helpers } from '@voyage-lab/util';

export class IntegrationCoreData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async create(props: { data: DatabaseEntity<'insert'>['brand_integrations'] }) {
		// Check if already exists
		const existingIntegration = await this.#dbClient
			.from('brand_integrations')
			.select('*')
			.eq('brand_id', props.data.brand_id)
			.eq('integration_id', props.data.integration_id)
			.limit(1)
			// .eq('lookup_id', props.data?.lookup_id || 'null')
			.maybeSingle();

		if (existingIntegration.data) return existingIntegration;

		// Validation
		// Database Call
		return this.#dbClient.from('brand_integrations').insert(props.data).select('*').single();
	}

	async update(props: { data: DatabaseEntity<'update'>['brand_integrations'] }) {
		if (!props.data.id && (!props.data.brand_id || !props.data.integration_id) && !props.data.lookup_id) {
			throw new Error('ID, Lookup ID or both brand ID and integration ID are required');
		}

		let query = this.#dbClient.from('brand_integrations').select('*').limit(1);
		if (props.data.id) {
			query = query.eq('id', props.data.id);
		} else if (props.data.brand_id && props.data.integration_id) {
			query = query.eq('brand_id', props.data.brand_id).eq('integration_id', props.data.integration_id);
		} else if (props.data.lookup_id) {
			query = query.eq('lookup_id', props.data.lookup_id);
		} else {
			throw new Error('Invalid unique identifier');
		}
		const existingIntegration = await query.maybeSingle();

		if (existingIntegration.data) {
			return this.#dbClient
				.from('brand_integrations')
				.update(props.data)
				.eq('id', existingIntegration.data.id)
				.select()
				.maybeSingle();
		} else {
			return this.create({ data: props.data as DatabaseEntity<'insert'>['brand_integrations'] });
		}
	}
	async patch(props: { data: DatabaseEntity<'update'>['brand_integrations'] }) {
		// Initialization
		const bid = props.data.id;

		// Validation
		if (!bid) throw new AppError('No id provided');

		// Query
		const existingIntegration = await this.#dbClient
			.from('brand_integrations')
			.select('*')
			.eq('id', bid)
			.limit(1)
			.maybeSingle();

		if (!existingIntegration.data) throw new AppError('Integration not found');

		// Merge
		const mergedData = Helpers.Object.deepMerge(existingIntegration.data, props.data);
		// @ts-expect-error: id is not allowed to be updated
		delete mergedData.id;
		mergedData.updated_at = new Date().toISOString();

		// Mutation
		return this.#dbClient.from('brand_integrations').update(mergedData).eq('id', bid).select('*').single();
	}
	setup(props: { data: DatabaseEntity['brand_integrations'] }) {
		// Validation
		// Database Call
	}
	async getAll(props: { published?: boolean }) {
		const query = this.#dbClient.from('integrations').select();

		if (props.published !== undefined) query.eq('is_published', props.published);

		return await query;
	}

	/**
	 * @param props.multiple - If true, returns an array of brand integrations.
	 */
	//TODO: Rename to getBrandIntegration
	async getSingleBi(props: GetBiProps) {
		const query = this.#getBiQuery(props);
		const response = await query.limit(1).maybeSingle();
		const bi = response.data?.brand_integrations?.[0];

		return {
			...response,
			bi,
		};
	}

	async getBi(props: GetBiProps) {
		const query = this.#getBiQuery(props);

		const response = await query;
		return response;
	}

	#getBiQuery(props: GetBiProps) {
		const biInner = props.lookupId || props.lookupIds?.length || (props.integrationIds?.length ?? 0) > 1;

		const query = this.#dbClient
			.from('integrations')
			.select(
				`*,brand_integrations${biInner ? '!inner' : ''}(*,brands!inner(extra_data,tenant_id,attribution_type,attribution_hard_cap))`
			);

		if (props.brandId) query.eq('brand_integrations.brand_id', props.brandId);
		if (props.integrationId) query.eq('id', props.integrationId);
		if (props.lookupId) query.eq('brand_integrations.lookup_id', props.lookupId);
		if (props.integrationIds) query.in('id', props.integrationIds);
		if (props.lookupIds) query.in('brand_integrations.lookup_id', props.lookupIds);
		if (props.status) query.eq('brand_integrations.status', props.status);
		if (props.id) query.eq('brand_integrations.id', props.id);

		return query;
	}
}

type GetBiProps = {
	brandId?: string;
	integrationId?: DatabaseEntity['integrations']['id'];
	id?: string;
	integrationIds?: DatabaseEntity['integrations']['id'][];
	lookupId?: string;
	lookupIds?: string[];
	status?: DatabaseEntity['brand_integrations']['status'];
};
