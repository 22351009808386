'use client';

import { parseAsString, useQueryStates } from 'nuqs';
import { useEffect, useRef } from 'react';

import { notify } from '@voyage-lab/ui';

export const ToastMessageListener = () => {
	const [searchParams] = useQueryStates({
		error: parseAsString,
		success: parseAsString,
	});

	const lastMessage = useRef<{ type: 'error' | 'success'; message: string } | null>(null);

	useEffect(() => {
		if (searchParams.error) {
			if (
				!lastMessage.current ||
				lastMessage.current.type !== 'error' ||
				lastMessage.current.message !== searchParams.error
			) {
				notify.error(searchParams.error);
				lastMessage.current = { type: 'error', message: searchParams.error };
			}
		}

		if (searchParams.success) {
			if (
				!lastMessage.current ||
				lastMessage.current.type !== 'success' ||
				lastMessage.current.message !== searchParams.success
			) {
				notify.success(searchParams.success);
				lastMessage.current = { type: 'success', message: searchParams.success };
			}
		}
	}, [searchParams]);

	return null;
};
