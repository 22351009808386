export const baseStyle = {
	':root': {
		'--primary': '#702dfd',
		'--primary-light': '#702dfd50',
		'--primary-100': '#9A6AFF',
		'--primary-200': '#702EFD',
		'--primary-500': '#431C97',
		'--primary-outline': '#9A6AFF33',

		'--blue': '#3c43fb',
		'--blue-20': '#3c43fb33',
		'--blue-100': '#3c43fb59',

		'--green': '#2ad195',
		'--green-100': '#2ad1954a',
		'--green-2': '#5E8E3E',
		'--green-20': '#13BE8033',
		'--green-dark': '#159d6c',
		'--green-light': '#2ad19550',
		'--green-border-outline': '#16745533',
		'--green-text-outline': '#14be80',

		'--yellow': '#d1c12a',
		'--yellow-20': '#d1c12a33',
		'--yellow-100': '#d1c12a4a',
		'--yellow-light': '#d1c12a50',

		'--cyan-outline': '#0098E433',
		'--cyan-icon': '#0099e4',

		'--violate-10': '#9A6AFF1A',
		'--violate-50': '#9A6AFF80',

		'--white': '#e8e4f0',
		'--text-white': '#fff',
		'--black': '#241e2b',

		'--white-icon': '#888397',

		'--dark-1': '#dfdbe8',
		'--dark-2': '#d3cee0',
		'--dark-3': '#cbc8d0',

		'--mid-1': '#ada7bf',

		'--grey': '#4a3e64',
		'--grey-1': '#69607d',
		'--grey-2': '#574c6f',

		'--pink': '#e815df',
		'--pink-soft': '#CF68FF',

		'--turquoise': '#2b93f2',

		'--white-shade': '#FFFFFF1A',
		'--black-shade': '#0000001A',

		'--red': '#F96E6E',
		'--red-20': '#F96E6E33',
		'--red-100': '#ff8a8a38',
		'--red-light': '#F96E6E50',

		'--orange': '#e57e17',
		'--orange-20': '#e57e1733',
		'--orange-light': '#e57e1750',
		'--orange-100': '#e57e174a',
		'--orange-border-outline': '#97673333',
		'--orange-bg-outline': '#f4e1c9',
		'--orange-text-outline': '#ffaa48',
		'--orange-soft': '#F2D193',

		'--background': '#FBFBFB',
		'--background-2': '#f8f8f8',
		'--background-100': '#e6eaee',
		'--off': '#241e2b',

		/* Neutrals */
		'--neutrals-menu-bg': '#f4f4f6',
		'--neutrals-menu-highlight': '#723FE3',
		'--neutrals-auth-bg': '#f9fafb',
		'--neutrals-menu-divider': '#e4e6eb',
		'--neutrals-menu-border': '#ececec',
		'--neutrals-main-border': '#E4E4E4',
		'--neutrals-text-secondary': '#a4aebc',
		'--neutrals-text-primary': '#000000',
		'--neutrals-icon-secondary': '#888397',
		'--neutrals-menu-secondary': '#f4f4f6',
		'--neutrals-menu-secondary-2': '#888397',
		'--neutrals-card-empty': '#fbfbfb',

		/* Status */
		'--status-error': '#f13885',
		'--status-success': '#13be80',
		'--status-warning': '#ffc700',

		/* Graphs */
		'--graphs-2': '#ae2dd1',

		'--glass': '#000000cf',
		'--table-header-bg': '#E4E4E4',
		'--table-bg-secondary': '#FBFBFB',
	},
	'.dark': {
		'--background': '#17151B',
		'--background-2': '#1b191f',
		'--background-100': '#342e40',
		'--off': '#eaedf0',

		// "--white": "#EAEDF0",
		// "--black": "#18161D",
		'--white-icon': '#fff',
		'--text-white': '#fff',

		'--dark-1': '#1f1b28',
		'--dark-2': '#1f1b28',
		'--dark-3': '#1f1b28',

		'--mid-1': '#403a51',

		'--grey': '#cec7dc',
		'--grey-1': '#716983',
		'--grey-2': '#9f97b1',
		'--grey-3': '#403a52',
		'--green-border-outline': '#167455',
		'--green-20': '#1b212b',
		'--green-text-outline': '#14be80',

		'--primary-outline': '#3e305a',

		'--orange-border-outline': '#976733',
		'--orange-bg-outline': '#2e241e',

		'--cyan-outline': '#1f3955',
		'--cyan-icon': '#0099e4',

		/* Neutrals */
		'--neutrals-menu-bg': '#272231',
		'--neutrals-menu-highlight': '#723FE3',
		'--neutrals-auth-bg': '#1D1A23',
		'--neutrals-menu-divider': '#2d3036',
		'--neutrals-menu-border': '#221F28',
		'--neutrals-main-border': '#33373d',
		'--neutrals-text-secondary': '#838d97',
		'--neutrals-icon-secondary': '#888397',
		'--neutrals-text-primary': '#ffffff',
		'--neutrals-menu-secondary': '#1c1b23',
		'--neutrals-menu-secondary-2': '#8A81AC',
		'--neutrals-card-empty': '#1d1c24',

		/* Modal */
		'--layout-modal-light': '#111',

		/* Border */
		'--border-100': '#aaa',

		'--table-header-bg': '#393544',
		'--table-bg-secondary': '#1C1921',
	},

	// base
	'@font-face': [
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'normal',
			fontWeight: 400,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'normal',
			fontWeight: 600,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYMEDIUM.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'normal',
			fontWeight: 700,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBOLD.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'italic',
			fontWeight: 100,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYULTRALIGHTITALIC.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'italic',
			fontWeight: 200,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYTHINITALIC.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'italic',
			fontWeight: 300,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYLIGHTITALIC.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'italic',
			fontWeight: 600,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYSEMIBOLDITALIC.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'italic',
			fontWeight: 800,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYHEAVYITALIC.woff) format('woff')",
		},
		{
			fontFamily: 'SF Pro Display',
			fontStyle: 'italic',
			fontWeight: 900,
			src: "url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBLACKITALIC.woff) format('woff')",
		},
	],
	'*': {
		fontFamily: "'SF Pro Display', 'Inter'",
	},
	body: {
		fontFeatureSettings: "'rlig' 1, 'calt' 1",
	},
	'.input-basic': {
		'@apply rounded-lg border bg-background border-neutrals-main-border text-black dark:text-white text-md px-3.5 py-2.5':
			{},
		lineHeight: 'normal',
	},
	'.scrollbar-visible': {
		scrollbarWidth: 'unset',
	},
	'.scrollbar-visible::-webkit-scrollbar': {
		display: 'unset',
	},
	'.page-padding': {
		'@apply lg:p-page p-page-sm': {},
	},
	'.scrollbar-hidden': {
		scrollbarWidth: 'none',
	},
	'.scrollbar-hidden::-webkit-scrollbar': {
		display: 'none',
	},
	'.scrollbar-stable': {
		scrollbarGutter: 'stable',
	},
	'.scrollbar-custom::-webkit-scrollbar': {
		width: '8px',
		height: '8px',
	},
	'.scrollbar-custom::-webkit-scrollbar-track': {
		'@apply bg-dark-2 rounded-[30px]': {},
	},
	'.scrollbar-custom::-webkit-scrollbar-thumb': {
		'@apply bg-mid-1 rounded-[30px]': {},
	},
	'.scrollbar-custom::-webkit-scrollbar-thumb:hover': {
		'@apply bg-grey-2': {},
	},
	'.scrollbar-secondary::-webkit-scrollbar': {
		width: '4px',
		height: '4px',
	},
	'.scrollbar-secondary::-webkit-scrollbar-track': {
		'@apply bg-dark-2 rounded-[30px]': {},
	},
	'.scrollbar-secondary::-webkit-scrollbar-thumb': {
		'@apply bg-mid-1 rounded-[30px]': {},
	},
	'.scrollbar-secondary::-webkit-scrollbar-thumb:hover': {
		'@apply bg-grey-2': {},
	},
	'.hide-on-mobile': {
		'@apply hidden lg:block': {},
	},
	'.hide-on-desktop': {
		'@apply lg:hidden block': {},
	},
	'.tooltip': {
		'@apply invisible flex absolute transition items-center': {},
	},
	'.has-tooltip:hover .tooltip': {
		'@apply visible z-50': {},
	},
	'.tooltip-arrow': {
		'@apply absolute -left-[8px] w-0 h-0 border-t-[7px] border-t-transparent border-r-[10px] border-neutrals-main-border border-b-[7px] border-b-transparent':
			{},
	},
	'.freeze-animation': {
		animationFillMode: 'forwards !important',
	},
	'.pause-animation': {
		animationPlayState: 'paused !important',
	},
	select: {
		background:
			"url(\"data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23808080' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>\") no-repeat",
		backgroundPosition: 'calc(100% - 0.75rem) center !important',
		mozAppearance: 'none !important',
		webkitAppearance: 'none !important',
		appearance: 'none !important',
		paddingRight: '2rem !important',
	},
	'select:invalid': {
		color: '#9ca3af',
	},
	'.rdp-day_range_start': {
		'@apply rounded-l-md': {},
	},
	'.rdp-day_range_end': {
		'@apply rounded-r-md': {},
	},
	'.rdp-tbody td:last-child': {
		'@apply !rounded-r-md overflow-hidden': {},
	},
	'.rdp-tbody td:first-child': {
		'@apply !rounded-l-md overflow-hidden': {},
	},
};
