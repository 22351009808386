export const TEST_IDS = {
	conversations: {
		listItem: 'convo-list-item',
		listItemTitle: 'convo-list-title', //Contact Name in list item
		message: 'convo-message',
		messageTime: 'convo-message-time',
		contactName: 'convo-contact', //Contact Name in convo header
		cartItem: 'convo-cart-item',
		noConvoFoundMsg: 'no-convo-found-msg',

		//tabs
		dashboardTab: 'convo-dashboard-tab',
		listsTab: 'convo-lists-tab',
		feedbackTab: 'convo-feedback-tab',
	},
	kb: {
		listItem: 'kb-list-item',
		kbNewButton: 'kb-new-btn',
		listItemTitle: 'kb-list-title', //Contact Name in list item
		titleInput: 'kb-title-input',
		answerInput: 'kb-answer-input',
		kbSubmitButton: 'kb-submit-btn',
		kbStatusButton: 'kb-status-btn',
	},
	workflow: {
		cardItem: 'wf-card-item',
		itemName: 'wf-name',
		toggleState: 'wf-toggle-state',
		name: 'wf-name',
		nameInput: 'wf-name-input',
		editFlowButton: 'wf-edit-btn',
	},
	auth: {
		loginButton: 'auth-login-button',
		emailInput: 'auth-email-input',
		passwordInput: 'auth-password-input',
	},
	onboarding: {
		signupForm: 'onboarding-signup-form',
		emailInput: 'onboarding-email-input',
		firstNameInput: 'onboarding-firstname-input',
		lastNameInput: 'onboarding-lastname-input',
		orgNameInput: 'onboarding-org-input',
		storeUrlInput: 'onboarding-store-url-input',
		passwordInput: 'onboarding-password-input',
		confirmPasswordInput: 'onboarding-confirm-password-input',
		sourceDropdown: 'onboarding-source-dropdown',
		revenueDropdown: 'onboarding-revenue-dropdown',
		getStartedButton: 'onboarding-get-started-btn',
		nextButton: 'onboarding-next-btn',
		skipButton: 'onboarding-skip-btn',
		checkbox: 'onboarding-checkbox',
	},
	navLinks: {
		conversations: 'navlink-convos',
		workflows: 'navlink-flows',
		kb: 'navlink-kbs',
		dashboard: 'navlink-dashboard',
	},
} as const;

export const TEXT_CONSTANTS = {
	conversations: {
		conversationsLabel: 'Conversations',
		noConversationsMessage: 'No conversations found for the selected date range',
		noResultsMessage: 'No results',
		repliesLabel: 'Replies',
		replyRateLabel: 'Reply Rate',
		p2pSubscribersLabel: 'P2P Subscribers',
		feedbackTblFirstHeader: 'Customer Name',
	},
	workflows: {
		flowsLabel: 'Flows',
		newFlowButton: 'New Flow',
		abandonedCartFlow: 'Abandoned Cart',
		successToast: 'Workflow updated successfully',
		toastPaused: 'Workflow paused!',
		toastActivated: 'Workflow activated!',
		integrationMissingMsg: 'Please connect your ecommerce store to continue',
		billingPlanMissingMsg: 'upgrade',
	},
	common: {
		save: 'Save',
	},
};
