'use client';

import { cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../../utilities';
import { Icon } from '../../icon';
import { InputLayout } from '../input.layout';
import type { InputProps } from './text.types';

export const textInputVariants = cva('input-basic h-[100%] w-full', {
	variants: {
		size: {
			default: '',
		},
	},
	defaultVariants: {
		size: 'default',
	},
});

const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			type,
			largeText,
			label,
			description,
			error,
			size,
			disabled,
			innerSuffix,
			outerSuffix,
			innerPrefix,
			outerPrefix,
			fullWidth,
			containerClassName,
			onChangeValue,
			errors,
			name,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			remove_error_container,
			inputDescription,
			required,
			...props
		},
		ref
	) => {
		const isPassword = type === 'password';
		const [password, setPassword] = React.useState(isPassword);

		const togglePasswordVisibility = () => {
			setPassword(!password);
		};

		const errorMessage = name ? errors?.[name]?.at(0) : undefined;

		return (
			<InputLayout
				required={required}
				error={error || errorMessage}
				description={description}
				label={label}
				inputDescription={inputDescription}
				className={fullWidth ? 'w-full' : ''}
				remove_error_container={remove_error_container}
			>
				<div className={'flex items-center text-neutrals-text-primary'}>
					{outerPrefix && <div className="mr-3">{outerPrefix}</div>}
					<div className={cn('flex items-center relative', fullWidth && 'w-full')}>
						{innerPrefix && <div className="ml-3 absolute">{innerPrefix}</div>}
						<input
							name={name}
							className={cn(
								disabled && 'cursor-not-allowed opacity-50',
								innerPrefix && 'pl-10',
								innerSuffix && 'pr-10',
								largeText && 'text-md',
								textInputVariants({
									size,
									className,
								})
							)}
							{...(onChangeValue
								? {
										onChange: (v) => {
											onChangeValue(v.target.value);
										},
									}
								: {})}
							disabled={disabled}
							type={isPassword ? (!password ? 'text' : 'password') : type}
							ref={ref}
							{...props}
						/>
						{innerSuffix ? (
							<div className="right-3 absolute">{innerSuffix}</div>
						) : (
							isPassword && (
								<div className="right-3 absolute" onClick={togglePasswordVisibility}>
									<Icon name={!password ? 'Eye' : 'EyeOff'} color="grey-1" />
								</div>
							)
						)}
					</div>
					{outerSuffix && <div className="ml-3">{outerSuffix}</div>}
				</div>
			</InputLayout>
		);
	}
);

TextInput.displayName = 'Input';

export { TextInput };
