'use client';

import React, { createContext, useContext, useState, useTransition } from 'react';

import { Button } from '../button';
import { Modal } from '../modal';
import { Spacer } from '../spacer';
import type { ConfirmModalPropType, ModalState } from './confirm.types';

const ConfirmContext = createContext<{
	confirm: (props: ConfirmModalPropType) => void;
	closeConfirmModal: () => void;
}>(null!);

const initialState = {
	show: false,
	title: '',
	message: '',
	onConfirm: undefined,
	onAsyncConfirm: undefined,
	onCancel: undefined,
};

export const ConfirmModalProvider = ({ children }: { children: React.ReactNode }) => {
	const [modal, setModal] = useState<ModalState>(initialState);
	const [isConfirming, setIsConfirming] = useState(false);

	const confirm = (props: ConfirmModalPropType) => {
		setModal({
			show: true,
			title: props.title,
			message: props.message,
			onConfirm: props.onConfirm,
			onAsyncConfirm: async () => {
				setIsConfirming(true);
				try {
					await props.onAsyncConfirm?.();
				} catch (error) {}
				setIsConfirming(false);
			},
			onCancel: props.onCancel,
		});
	};

	const closeConfirmModal = () => {
		setModal(initialState);
	};

	return (
		<ConfirmContext.Provider value={{ confirm, closeConfirmModal }}>
			<Modal open={modal.show} onOpenChange={(v) => setModal((prev) => ({ ...prev, show: v }))}>
				<Modal.Content className="sm:max-w-[425px]">
					<Modal.Header>
						<Modal.Title>{modal.title}</Modal.Title>
						<Modal.Description>{modal.message}</Modal.Description>
					</Modal.Header>
					<Spacer vertical={10} />
					<Modal.Footer>
						<Button disabled={isConfirming} size="sm" variant="outline" onClick={modal.onCancel}>
							Cancel
						</Button>
						<Button
							disabled={isConfirming}
							isLoading={isConfirming}
							size="sm"
							variant="danger"
							onClick={modal.onConfirm || modal.onAsyncConfirm}
							className="px-2"
						>
							Yes
						</Button>
					</Modal.Footer>
				</Modal.Content>
			</Modal>
			{children}
		</ConfirmContext.Provider>
	);
};

export function useConfirmModal() {
	const context = useContext(ConfirmContext);

	if (!context) {
		throw new Error('useConfirmModal must be used within a ConfirmModalProvider');
	}

	return context;
}
