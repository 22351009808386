import * as bcrypt from 'bcryptjs';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import { AppError, JWT, type JwtTypes } from '@voyage-lab/core-common';
import type { EmailService } from '@voyage-lab/core-email';
import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import { Schema, type TypesT } from '@voyage-lab/schema';
import { Helpers } from '@voyage-lab/util';

import { AccountSchema } from '..';
import type { ReinviteMemberSchema, UpdateMemberSchema, inviteMemberSchema } from '../schema';

export class AccountData {
	#dbClient: PostgrestClientType;
	#secret: string;
	#jwt: JWT;
	#setupTokenExpireTimeInMinute: number;
	email?: EmailService;

	constructor({
		dbClient,
		email,
		jwtSecret,
	}: {
		dbClient: PostgrestClientType;
		email?: EmailService;
		jwtSecret: string;
	}) {
		this.#dbClient = dbClient;
		this.#secret = jwtSecret;
		this.email = email;
		this.#jwt = new JWT(this.#secret);
		this.#setupTokenExpireTimeInMinute = 60 * 24 * 3; // 3 days
	}

	async getUser(props: { userId: string }) {
		const { data, error } = await this.#dbClient
			.from('users')
			.select(
				`
				id,
				role,
            	given_name,
				family_name,
				extra_data,
				phone,
				updated_at,
				last_login,
				email
        	`
			)
			.eq('id', props.userId)
			.maybeSingle();

		return data as (typeof data & TypesT.UserJson) | null;
	}

	async getProviderUser(props: {
		provider?: string;
		userId?: string;
		lookupId?: string;
		integrationId?: DatabaseEntity['integrations']['id'];
	}) {
		// Validation (either userId and provider or lookupId and integrationId)
		if (!((props.userId && props.provider) || (props.integrationId && props.lookupId))) {
			throw new AppError('Either userId and provider or lookupId and integrationId is required');
		}

		const query = this.#dbClient
			.from('brand_integrations')
			.select('*,brands!inner(id,tenants!inner(id, users!inner(*)))');

		if (props.userId && props.provider)
			query.eq(`brands.tenants.users.auth->${props.provider}->>user_id`, props.userId);
		if (props.lookupId && props.integrationId)
			query.eq('lookup_id', props.lookupId).eq('integration_id', props.integrationId);

		query.eq('brands.tenants.users.state', 'active');
		query.order('created_at', { referencedTable: 'brands.tenants.users', ascending: true });
		query.limit(1, { referencedTable: 'brands.tenants.users' });
		query.limit(1);

		const biRes = await query.maybeSingle();
		const userRes = { data: biRes?.data?.brands?.tenants?.users?.[0], integration: biRes?.data };

		return userRes;
	}

	async createUser(props: { data: DatabaseEntity['users'] }) {
		// Validation
		const validData = Schema.usersInputSchema.parse(props.data);

		// Database mutation
		return this.#dbClient
			.from('users')
			.insert(validData as unknown as DatabaseEntity['users'])
			.select('*')
			.single();
	}

	async getSingle(props: { leadId: string }) {
		const { data, error } = await this.#dbClient
			.from('leads')
			.select(
				`
            	*,
				brands(*),
				tenants(*),
				users(*)
        	`
			)
			.eq('id', props.leadId)
			.maybeSingle();

		return data as (typeof data & TypesT.LeadJson) | null;
	}

	async create(props: { data: DatabaseEntity['leads']; allowDuplicateEmail?: boolean }) {
		// Validation
		const validData = AccountSchema.SignUpSchemaStepOne.parse(props.data) as unknown as DatabaseEntity['leads'];

		// Check if a user with same email already exists
		const uniqueEmailSchema = z.object({
			email: z
				.string()
				.email()
				.refine(
					async (email) => {
						const existingUser = await this.#dbClient
							.from('users')
							.select('id')
							.eq('email', email)
							.limit(1)
							.maybeSingle();
						return !existingUser.data;
					},
					{ message: 'An account with this email already exists' }
				),
		});

		if (!props.allowDuplicateEmail) await uniqueEmailSchema.parseAsync({ email: validData.email });

		return this.#dbClient.from('leads').insert(validData).select('*').single();
	}

	async setup(props: { data: DatabaseEntity['leads'] }) {
		// Validation
		const validData = AccountSchema.SignUpSchemaFinal.parse(props.data);

		const existingLeadRes = await this.#dbClient
			.from('leads')
			.select('*,tenants(*),brands(*),users(*)')
			.eq('id', validData.id)
			.maybeSingle();

		if (!existingLeadRes.data) throw new Error('Failed to find the lead: ' + JSON.stringify(existingLeadRes));

		// If lead already has tenant, user, and brand ids, then return the account data
		if (existingLeadRes.data?.tenants?.id && existingLeadRes.data?.users?.id && existingLeadRes.data?.brands?.id) {
			return existingLeadRes.data as typeof existingLeadRes.data & TypesT.LeadJson;
		}

		// Create tenant, user, brand, and shopify integration
		const tenant: DatabaseEntity<'insert'>['tenants'] = {
			id: uuid(),
			name: validData.company_name || '',
			email: validData.email,
			is_enabled: true,
			type: 'brand',
			hubspot_id: '',
			extra_data: {},
			phone: null,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
		};

		const user: DatabaseEntity<'insert'>['users'] = {
			id: uuid(),
			tenant_id: tenant.id,
			email: validData.email,
			given_name: validData.first_name,
			family_name: validData.last_name,
			role: 'admin',
			is_enabled: true,
			phone: '',
			is_staff: false,
			password: validData.extra_data.password ? this.#encryptPassword(validData.extra_data.password) : null,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
		};

		// Handle BigCommerce
		const isBigCommerce = validData.extra_data?.installation_source === 'bigcommerce';
		if (isBigCommerce) {
			// @ts-expect-error: TODO: add typing for bigcommerce
			const bigcommerceSession = validData.extra_data?.bigcommerce?.session;
			if (!bigcommerceSession) throw new Error('BigCommerce session is required');
			const bigCommerceUser = bigcommerceSession.user;
			if (!bigCommerceUser) throw new Error('BigCommerce user is required');

			user.auth = {
				provider: 'bigcommerce',
				bigcommerce: {
					user_id: bigCommerceUser.id,
				},
			};
		}

		const brand: DatabaseEntity<'insert'>['brands'] = {
			id: uuid(),
			tenant_id: tenant.id,
			name: validData.company_name || '',
			is_enabled: true,
			extra_data: {
				is_direct: validData.extra_data?.installation_source === 'direct',
				finalized_onboarding: true,
			},
			domain: new URL(Helpers.String.toHttpUrl(validData.store_url)).hostname,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
		};

		// Now mutate the data
		const createTenantRes = await this.#dbClient.from('tenants').insert(tenant).select('id').maybeSingle();
		const createUserRes = await this.#dbClient.from('users').insert(user).select('id').maybeSingle();
		const createBrandRes = await this.#dbClient.from('brands').insert(brand).select('id').maybeSingle();

		// Destry all the things if something went wrong
		if (!createTenantRes.data || !createUserRes.data || !createBrandRes.data) {
			await this.#dbClient.from('tenants').delete().eq('id', validData.id);
			await this.#dbClient.from('users').delete().eq('id', validData.id);
			await this.#dbClient.from('brands').delete().eq('id', validData.id);

			throw new Error(
				'Failed to create tenant, user, and brand: ' +
					JSON.stringify({ createTenantRes, createUserRes, createBrandRes })
			);
		}

		// Looks good, now we can update the lead with ids
		const leadExtraData = structuredClone(props.data?.extra_data || {}) as TypesT.LeadJson['extra_data'];
		// if (leadExtraData?.password) delete leadExtraData.password;
		const updatedLead = await this.#dbClient
			.from('leads')
			.update({
				tenant_id: createTenantRes.data?.id,
				user_id: createUserRes.data?.id,
				brand_id: createBrandRes.data?.id,

				// TODO: use zod to cleanup the extra_data
				// extra_data: leadExtraData,
			})
			.eq('id', validData.id)
			.select('*, tenants(*), users(*), brands(*)')
			.single();

		// Check if the lead was updated
		if (!updatedLead.data) {
			// Failed to update the lead, but still continue
			throw new Error('Failed to update the lead with tenant, user, and brand ids');
		}

		// Return the account data

		return updatedLead.data as typeof existingLeadRes.data & TypesT.LeadJson;
	}

	update(props: { data: Partial<DatabaseEntity['leads']> & Required<Pick<DatabaseEntity['leads'], 'id'>> }) {
		// Validation
		// if (step === 0) AccountSchema.SignUpSchemaStepOne.parse(props.data);
		// if (step === 1) AccountSchema.SignUpSchemaStepTwo.parse(props.data);

		return this.#dbClient.from('leads').update(props.data).eq('id', props.data.id).select('*').single();
	}

	updateUser(props: { data: Partial<DatabaseEntity['users']> & Required<Pick<DatabaseEntity['users'], 'id'>> }) {
		const validData = AccountSchema.AccountInfoUpdateSchema.parse(props.data);
		return this.#dbClient.from('users').update(validData).eq('id', props.data.id).select('*').single();
	}

	async getUserList(props: { page: number; tenantId: string; limit?: number; search?: string }) {
		const limit = props.limit || 10;
		const offset = (props.page - 1) * limit;

		const users = this.#dbClient
			.from('users')
			.select(
				`
				id,
				email,
				given_name,
				family_name,
				extra_data,
				role,
				state,
				created_at,
				updated_at,
				last_login,
				extra_data->invite_expires_at
			`,
				{ count: 'exact' }
			)
			.order('state', { ascending: true })
			.order('role', { ascending: true })
			.order('created_at', { ascending: false })
			.range(offset, offset + limit - 1)
			.eq('tenant_id', props.tenantId);

		if (props.search) {
			const searchTerms = props.search.split(' ').map((term) => term.trim());
			const searchCols = ['email', 'given_name', 'family_name'];
			users.or(
				searchTerms.map((term) => searchCols.map((col) => `${col}.ilike.%${term}%`).join(',')).join('.or')
			);
		}

		const res = await users;

		return res;
	}

	async inviteMember(props: z.infer<typeof inviteMemberSchema>) {
		// Parse and validate emails
		let emailArray: string[] = [];
		const separators = ['\n', '\r\n', ',', ';'];

		// First split by line breaks
		const lines = props.email.split(/[\n\r]+/);

		// Process each line
		lines.forEach((line) => {
			// Split each line by common separators
			let emails: string[] = [line];
			separators.forEach((separator) => {
				emails = emails.flatMap((part) =>
					part
						.split(separator)
						.map((e) => e.trim())
						.filter(Boolean)
				);
			});

			// Validate and add valid emails
			emails.forEach((email) => {
				if (z.string().email().safeParse(email).success) {
					emailArray.push(email);
				}
			});
		});

		// Unique emails
		emailArray = [...new Set(emailArray)];

		// Check existing users
		const existingUser = await this.#dbClient.from('users').select('email').in('email', emailArray);
		const existingUserEmails = existingUser.data?.map((user) => user.email) || [];
		const newUserEmails = emailArray.filter((email) => !existingUserEmails.includes(email));

		const results: (Partial<DatabaseEntity['users']> & {
			type: 'existing' | 'failed' | 'success';
			message?: string;
		})[] = [];

		existingUser.data?.forEach((user) => {
			results.push({
				...user,
				type: 'existing',
				message: 'User already exists',
			});
		});

		// Create users and send invites
		for (const email of newUserEmails) {
			try {
				const setupToken = this.generateSetupToken(email);
				const userId = uuid();

				const user: DatabaseEntity<'insert'>['users'] & TypesT.UserJson = {
					id: userId,
					tenant_id: props.tenant_id,

					email,
					role: props.role,
					is_enabled: false,
					phone: '',
					state: 'invited',
					is_staff: props.is_staff,

					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),

					auth: {
						setup_token: setupToken,
					},
					extra_data: {
						invite_expires_at: this.generateSetupTokenExpiryDate(),
					},
				};

				// Insert user
				await this.#dbClient.from('users').insert(user);

				// Send invitation email
				await this.sendInvitationEmail({
					email: user.email,
					login_page_link: props.login_page_link,
					setupToken: setupToken,
				});

				results.push({
					...user,
					type: 'success',
					message: 'Invitation sent!',
				});
			} catch (error) {
				results.push({
					email: email,
					type: 'failed',
					message: error instanceof Error ? error.message : 'Unknown error occurred',
				});
			}
		}

		return results;
	}

	async reinviteMember(props: z.infer<typeof ReinviteMemberSchema>) {
		const user = await this.#dbClient.from('users').select('state').eq('email', props.email).maybeSingle();
		const userData = user.data as DatabaseEntity['users'] & TypesT.UserJson;

		if (!userData) {
			throw new Error('User not found');
		}

		const setupToken = this.generateSetupToken(props.email);

		await this.#dbClient
			.from('users')
			.update({
				auth: {
					setup_token: setupToken,
				},
				extra_data: {
					invite_expires_at: this.generateSetupTokenExpiryDate(),
				},
			})
			.eq('email', props.email);

		return await this.sendInvitationEmail({
			email: props.email,
			login_page_link: props.login_page_link,
			setupToken,
		});
	}

	async updateMember(props: z.infer<typeof UpdateMemberSchema>) {
		const user: DatabaseEntity<'update'>['users'] = {};
		if (props.role) user.role = props.role;
		if (props.state) user.state = props.state;

		await this.#dbClient.from('users').update(user).eq('email', props.email);
	}

	async sendInvitationEmail(props: { email: string; login_page_link?: string; setupToken: string }) {
		const setupLink = `${props.login_page_link}?token=${props.setupToken}`;
		const content = `You've been invited to set up your LiveRecover account! You can set up your account by clicking the link below. <br /> <a href="${setupLink}">Set up account</a>`;
		if (!this.email) throw new Error('Email service not available');
		return this.email.sendEmail({
			content: content,
			subject: 'Set up your LiveRecover account',
			recipient: props.email,
		});
	}

	generateSetupToken(email: string) {
		return this.#jwt.encode<JwtTypes['token']>(
			{
				type: 'setup',
				email,
			},
			`${this.#setupTokenExpireTimeInMinute}m`
		);
	}

	generateSetupTokenExpiryDate() {
		return Helpers.Time.addMinutes(Date.now(), this.#setupTokenExpireTimeInMinute).toISOString();
	}

	async patch(props: {
		data: Partial<DatabaseEntity['users'] & TypesT.UserJson> & Required<Pick<DatabaseEntity['users'], 'id'>>;
	}) {
		const { data: existing } = await this.#dbClient
			.from('users')
			.select('extra_data')
			.eq('id', props.data.id)
			.single();

		const updatedExtraData = {
			...((existing?.extra_data || {}) as TypesT.UserJson['extra_data']),
			...((props.data.extra_data || {}) as TypesT.UserJson['extra_data']),
		};

		props.data.extra_data = updatedExtraData;

		return this.#dbClient.from('users').update(props.data).eq('id', props.data.id).select('*').single();
	}

	async patchExtraData(props: {
		data: Partial<DatabaseEntity['leads'] & TypesT.LeadJson> &
			Required<Pick<DatabaseEntity['leads'], 'id' | 'extra_data'>>;
	}) {
		const { data: existingLead } = await this.#dbClient
			.from('leads')
			.select('extra_data')
			.eq('id', props.data.id)
			.single();

		const updatedExtraData = {
			...((existingLead?.extra_data || {}) as TypesT.LeadJson['extra_data']),
			...((props.data.extra_data || {}) as TypesT.LeadJson['extra_data']),
		};

		return this.#dbClient
			.from('leads')
			.update({ extra_data: updatedExtraData })
			.eq('id', props.data.id)
			.select('*')
			.single();
	}

	async validateCompanyDomain(shop: string) {
		const existingShop = await this.#dbClient
			.from('brand_integrations')
			.select('brands(tenants(email))')
			.eq('lookup_id', shop)
			.eq('status', 'connected')
			.limit(1)
			.maybeSingle();

		const email = existingShop.data?.brands?.tenants?.email;

		if (existingShop.data) {
			throw new Error(
				`${shop} is already connected to a LiveRecover account ${
					email ? `under the email ${email}` : ''
				}. Please login using that email or let us know if we can assist you in getting access to your account`
			);
		}
	}

	async generateAccountToken(id: string) {
		// Encrypt the account id using the account token encryption key
		const accountId = id;
		const encryptedAccountId = await this.#encrypt(accountId);
		return encryptedAccountId;
	}

	async validateAccountToken(token: string) {
		try {
			// Decrypt the account id using the account token encryption key
			const decryptedAccountId = await this.#decrypt(token);
			return decryptedAccountId;
		} catch (error) {
			console.error('Failed to validate account token', error);
			throw new Error('Invalid account token');
		}
	}

	#encryptPassword(password: string) {
		const hashedPassword = bcrypt.hashSync(password, 10);
		return hashedPassword;
	}

	async #encrypt(text: string) {
		const crypto = await import('crypto');
		const cipher = crypto.createCipher('aes-256-cbc', this.#ACCOUNT_TOKEN_ENCRYPTION_KEY);
		let encrypted = cipher.update(text, 'utf8', 'hex');
		encrypted += cipher.final('hex');
		return encrypted;
	}

	async #decrypt(encryptedText: string) {
		const crypto = await import('crypto');

		const decipher = crypto.createDecipher('aes-256-cbc', this.#ACCOUNT_TOKEN_ENCRYPTION_KEY);
		let decrypted = decipher.update(encryptedText, 'hex', 'utf8');
		decrypted += decipher.final('utf8');
		return decrypted;
	}

	readonly #ACCOUNT_TOKEN_ENCRYPTION_KEY = 'l#r';
}

export type BrandDetailsT = Awaited<ReturnType<AccountData['getSingle']>>;
