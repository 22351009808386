import { createAdminApiClient } from '@shopify/admin-api-client';
import { LATEST_API_VERSION, Shopify } from '@shopify/shopify-api';
// import '@shopify/shopify-api/adapters/web-api';
import type Stripe from 'stripe';

import type { EmailService } from '@voyage-lab/core-email';
import type { DatabaseEntity, DatabaseEnum, PostgrestClientType, TypesT } from '@voyage-lab/db';
import type { ShopifyTypes } from '@voyage-lab/shopify-api';
import { ShopifyQuery, ShopifyUtils } from '@voyage-lab/shopify-api';

import { Subscription } from '../../subscription';
import { Payment } from '../base';

export class ShopifyPaymentProcessor extends Payment {
	db: Subscription;
	email: EmailService;
	returnUrl: string;
	shopifyApiKey: string;
	environment: string;

	constructor(props: {
		dbClient: PostgrestClientType;
		shopifyApiKey: string;
		returnUrl: string;
		email: EmailService;
		environment: string;
	}) {
		super();
		this.db = new Subscription(props.dbClient);
		this.email = props.email;
		this.environment = props.environment;
		this.returnUrl = props.returnUrl;
		this.shopifyApiKey = props.shopifyApiKey;
	}

	#toShopifyId(id: string) {
		return ShopifyUtils.toShopifyId(id, 'app_subscription');
	}

	#fromShopifyId(id: string) {
		return ShopifyUtils.fromShopifyId(id);
	}

	#parseActiveStatus(status: ShopifyTypes.AppSubscriptionStatus) {
		const activeStatuses = {
			ACTIVE: 'active',
			ACCEPTED: 'active',
		} as { [x in ShopifyTypes.AppSubscriptionStatus]: DatabaseEnum['t_subscription_status'] };
		return activeStatuses[status];
	}

	async createShopifyClient(brandId: string) {
		const brandIntegration = await this.db.getShopifyIntegration({ brandId });
		const settings = brandIntegration?.settings as TypesT.BrandIntegrationShopifyJson['settings'];
		if (!settings) {
			console.error('[ShopifyPaymentProcessor] Brand not found', {
				brandId,
				settings,
				operation: 'CREATE_SHOPIFY_CLIENT',
			});
			throw new Error('Brand not found');
		}

		const shopify = createAdminApiClient({
			storeDomain: settings.shopify_domain,
			apiVersion: LATEST_API_VERSION,
			accessToken: settings.credentials.access_token,
		});

		return shopify;
	}

	async getRemoteSubscription(brandId: string, subscriptionId: string) {
		const shopify = await this.createShopifyClient(brandId);
		const res = await shopify.request<{ node: ShopifyTypes.AppSubscription }>(
			ShopifyQuery.RETRIEVE_APP_SUBSCRIPTION,
			{
				variables: {
					id: this.#toShopifyId(subscriptionId),
				},
			}
		);
		if (res.errors) {
			console.warn('[ShopifyPaymentProcessor] Failed to retrieve subscription from GraphQL', {
				brandId,
				subscriptionId,
				operation: 'RETRIEVE_APP_SUBSCRIPTION',
				errors: res.errors,
			});
		}
		return res.data?.node;
	}

	override createCustomPlan(props: {
		planId: string;
		rate: string;
		price: string;
	}): Promise<DatabaseEntity['plans'] | null> {
		throw new Error('Method not implemented.');
	}

	override resetSubscriptionCheckout(subscriptionId: string): Promise<Stripe.Response<Stripe.Checkout.Session>> {
		throw new Error('Method not implemented.');
	}

	override async updatePendingSetupSubscription(
		subscription: DatabaseEntity['subscriptions']
	): Promise<DatabaseEntity['subscriptions'] | null> {
		const subscriptionData = await this.getRemoteSubscription(subscription.brand_id, subscription.external_id);
		if (!subscriptionData) {
			console.warn('[ShopifyPaymentProcessor] Remote subscription not found', {
				brandId: subscription.brand_id,
				subscriptionId: subscription.external_id,
				operation: 'UPDATE_PENDING_SETUP',
			});
			return null;
		}
		const subscriptionStatus = this.#parseActiveStatus(subscriptionData.status);
		if (subscriptionStatus) return this.db.activateSubscription(subscription.id, subscriptionStatus);
		return null;
	}

	async createRecurringSubscription({
		interval = 'EVERY_30_DAYS',
		...props
	}: {
		brandId: string;
		name: string;
		price: number;
		interval?: string;
		trialDays?: number;
	}) {
		const shopify = await this.createShopifyClient(props.brandId);
		const res = await shopify.request<ShopifyTypes.Mutation>(ShopifyQuery.CREATE_RECURRING_SUBSCRIPTION, {
			variables: {
				name: props.name,
				returnUrl: this.returnUrl,
				trialDays: props.trialDays,
				test: ['staging', 'development'].includes(this.environment),
				lineItems: [
					{
						plan: {
							appRecurringPricingDetails: {
								price: {
									amount: props.price,
									currencyCode: 'USD',
								},
								interval,
							},
						},
					},
				],
			},
		});
		if (res.errors) {
			console.error('[ShopifyPaymentProcessor] Failed to create recurring subscription in GraphQL', {
				brandId: props.brandId,
				operation: 'CREATE_RECURRING_SUBSCRIPTION',
				errors: res.errors,
			});
		}
		return res;
	}

	override async subscribe(props: {
		billingEmail: string;
		brandId: string;
		planId: string;
		userName?: string;
		userPhone?: string;
		trialDays?: number;
	}): Promise<{ url?: string | null }> {
		const plan = await this.db.getPlanById(props.planId);
		const brand = await this.db.getBrandById(props.brandId);
		if (!plan || !brand) {
			console.error('[ShopifyPaymentProcessor] Plan or brand not found', {
				brandId: props.brandId,
				planId: props.planId,
				operation: 'SUBSCRIBE',
			});
			throw new Error('Plan or brand not found');
		}

		const subscription = await this.createRecurringSubscription({
			brandId: props.brandId,
			name: plan.name,
			price: plan.price,
			trialDays: props.trialDays,
		});

		if (subscription.errors) {
			console.error('[ShopifyPaymentProcessor] Failed to create subscription', {
				brandId: props.brandId,
				planId: props.planId,
				operation: 'SUBSCRIBE',
				errors: subscription.errors,
			});
		}

		const appSubscription = subscription.data?.appSubscriptionCreate?.appSubscription;
		const confirmationUrl = subscription.data?.appSubscriptionCreate?.confirmationUrl;

		if (!appSubscription || !confirmationUrl) {
			console.error('[ShopifyPaymentProcessor] Missing subscription data from Shopify', {
				brandId: props.brandId,
				planId: props.planId,
				operation: 'SUBSCRIBE',
			});
			throw new Error('Failed to create subscription');
		}

		await this.db.createSubscriptionDb({
			billingEmail: props.billingEmail,
			brandId: brand.id,
			paymentSource: 'shopify',
			customerId: this.#fromShopifyId(appSubscription.id),
			planId: plan.id,
			price: plan.price,
			trialDays: props.trialDays,
			subscriptionId: this.#fromShopifyId(appSubscription.id),
		});

		return {
			url: confirmationUrl,
		};
	}

	async updateSubscription(props: { subscriptionId: string; planId: string }) {
		const subscription = await this.db.getSubscriptionById(props.subscriptionId);
		if (!subscription) {
			console.error('[ShopifyPaymentProcessor] Subscription not found', {
				subscriptionId: props.subscriptionId,
				operation: 'UPDATE_SUBSCRIPTION',
			});
			throw new Error('Subscription not found');
		}

		const plan = await this.db.getPlanById(props.planId);
		if (!plan) {
			console.error('[ShopifyPaymentProcessor] Invalid plan', {
				subscriptionId: props.subscriptionId,
				planId: props.planId,
				operation: 'UPDATE_SUBSCRIPTION',
			});
			throw new Error('Invalid Plan');
		}

		const shopify = await this.createShopifyClient(subscription.brand_id);
		const updateResult = await shopify.request(ShopifyQuery.UPDATE_SUBSCRIPTION, {
			variables: {
				id: this.#toShopifyId(subscription.external_id),
				cappedAmount: {
					amount: plan.price,
					currencyCode: 'USD',
				},
			},
		});

		if (updateResult.errors) {
			console.error('[ShopifyPaymentProcessor] Failed to update subscription in GraphQL', {
				subscriptionId: subscription.external_id,
				brandId: subscription.brand_id,
				planId: plan.id,
				operation: 'UPDATE_SUBSCRIPTION',
				errors: updateResult.errors,
			});
		}

		return null;
	}

	async cancelSubscription(props: { subscriptionId: string }) {
		const subscription = await this.db.getSubscriptionById(props.subscriptionId);
		if (!subscription) {
			console.error('[ShopifyPaymentProcessor] Invalid subscription', {
				subscriptionId: props.subscriptionId,
				operation: 'CANCEL_SUBSCRIPTION',
			});
			throw new Error('Invalid Subscription');
		}

		const shopify = await this.createShopifyClient(subscription.brand_id);
		const cancelResult = await shopify.request(ShopifyQuery.CANCEL_SUBSCRIPTION, {
			variables: {
				id: this.#toShopifyId(subscription.external_id),
			},
		});

		if (cancelResult.errors) {
			console.error('[ShopifyPaymentProcessor] Failed to cancel subscription in GraphQL', {
				subscriptionId: subscription.external_id,
				brandId: subscription.brand_id,
				operation: 'CANCEL_SUBSCRIPTION',
				errors: cancelResult.errors,
			});
		}

		return null;
	}

	override editBillingInfo(props: { brandId: string; returnUrl: string }): Promise<string> {
		throw new Error('Method not implemented.');
	}
}
