'use client';

import * as React from 'react';
import { useFormStatus } from 'react-dom';

import { Button, Text, TextInput } from '@voyage-lab/ui';
import { Util } from '@voyage-lab/ui';

export const FormActionButton = ({
	action,
	...props
}: {
	action?: () => void;
} & React.ComponentProps<typeof Button>) => {
	return (
		<form action={action}>
			<FormButton {...props} />
		</form>
	);
};

export const FormButton: typeof Button = ({ isLoading, disabled, ...props }) => {
	const { pending, data } = useFormStatus();
	return <Button size={'lg'} isLoading={pending || isLoading} disabled={pending || disabled} {...props} />;
};

export const FormTextInput: typeof TextInput = React.forwardRef(({ disabled, ...props }, ref) => {
	const { pending, data } = useFormStatus();
	return <TextInput disabled={pending || disabled} {...props} ref={ref} />;
});

export const FormErrorText = (props: { children?: string }) => {
	return (
		<Text size={'sm'} className={Util.cn('text-red text-center my-1 opacity-0', props.children && 'opacity-100')}>
			{props.children || ' '}
		</Text>
	);
};
