import { z } from 'zod';

import { Helpers } from '../helpers';

/**
 * Recursively walks through an object and its nested properties to capture email addresses and phone numbers.
 *
 * @description
 * This function traverses through all nested objects and arrays to find email addresses and phone numbers.
 * - Email addresses are validated using Zod email schema
 * - Phone numbers are validated and formatted to E.164 format (e.g., +1234567890)
 * - For nested properties, the path is constructed using dot notation (e.g., 'user.email')
 * - For array items, the path includes the index (e.g., 'addresses[0].phone')
 *
 * @example
 * ```typescript
 * const input = {
 *   email: 'user@example.com',
 *   phone: '1234567890',
 *   address: {
 *     email: 'shipping@example.com',
 *     phone: '9876543210'
 *   }
 * };
 *
 * const result = captureContactChannels(input);
 * // {
 * //   email: 'user@example.com',
 * //   phone: '+11234567890',
 * //   'address.email': 'shipping@example.com',
 * //   'address.phone': '+19876543210'
 * // }
 * ```
 *
 * @param obj - The object to search for contact information
 * @param channels - Optional existing channels to merge with the found contacts
 * @returns A record of paths mapped to their corresponding email or phone values
 */
export function capture<T extends object>(obj: T, channels: Record<string, string> = {}): Record<string, string> {
	const contactChannels: Record<string, string> = {};
	Object.entries(obj).forEach(([key, value]) => {
		if (typeof value === 'string') {
			if (isIPAddress(value)) {
				return;
			}
			if (emailSchema.safeParse(value).success) {
				contactChannels[key] = value;
			} else if (isValidPhone(value)) {
				if (Helpers.Phone.isValidInternationalPhone(value)) {
					contactChannels[key] = '+' + value.replace(/\D/g, '');
				} else {
					const phone = Helpers.Phone.e164Format(value);
					if (phone) {
						contactChannels[key] = phone;
					}
				}
			}
		} else if (Array.isArray(value)) {
			value.forEach((item, index) => {
				if (typeof item === 'object' && item !== null) {
					const nestedChannels = capture(item);
					Object.entries(nestedChannels).forEach(([nestedKey, nestedValue]) => {
						contactChannels[`${key}[${index}].${nestedKey}`] = nestedValue;
					});
				}
			});
		} else if (typeof value === 'object' && value !== null) {
			const nestedChannels = capture(value);
			Object.entries(nestedChannels).forEach(([nestedKey, nestedValue]) => {
				contactChannels[`${key}.${nestedKey}`] = nestedValue;
			});
		}
	});

	return { ...channels, ...contactChannels };
}

/**
 * Removes all valid email addresses and phone numbers from an object and its nested properties.
 *
 * @description
 * This function traverses through all nested objects and arrays to find and remove email addresses and phone numbers.
 * - Email addresses are validated using Zod email schema
 * - Phone numbers are validated using both US and international phone number formats
 * - The original object is not modified; a new object is returned
 * - All other properties are preserved
 *
 * @example
 * ```typescript
 * const input = {
 *   email: 'user@example.com',
 *   phone: '+11234567890',
 *   name: 'John Doe',
 *   address: {
 *     email: 'shipping@example.com',
 *     street: '123 Main St'
 *   }
 * };
 *
 * const result = clean(input);
 * // {
 * //   name: 'John Doe',
 * //   address: {
 * //     street: '123 Main St'
 * //   }
 * // }
 * ```
 *
 * @param obj - The object to clean of contact information
 * @returns A new object with all contact information removed
 */
export function clean<T extends object>(obj: T): T {
	const result = structuredClone(obj);

	Object.entries(result).forEach(([key, value]) => {
		if (typeof value === 'string') {
			if (emailSchema.safeParse(value).success || isValidPhone(value)) {
				// @ts-expect-error - we know this is a string
				delete result[key];
			}
		} else if (Array.isArray(value)) {
			// @ts-expect-error - we know this is an array
			result[key as keyof T] = value.map((item) => {
				if (typeof item === 'object' && item !== null) {
					return clean(item);
				}
				return item;
			});
		} else if (typeof value === 'object' && value !== null) {
			// @ts-expect-error - we know this is an object
			result[key as keyof T] = clean(value as object);
		}
	});

	return result;
}

/**
 * Deduplicates contact information by value, keeping only unique email addresses and phone numbers.
 *
 * @description
 * This function removes duplicate contact information (email addresses and phone numbers) from the input object,
 * keeping only the first occurrence of each unique value. It's particularly useful when the same contact
 * information appears multiple times in different paths of a nested object structure.
 *
 * @example
 * ```typescript
 * const input = {
 *   'checkout.email': 'user@example.com',
 *   'checkout.phone': '+11234567890',
 *   'checkout.customer.email': 'user@example.com',
 *   'checkout.billing_address.phone': '+11234567890'
 * };
 *
 * const result = dedupe(input);
 * // {
 * //   'checkout.email': 'user@example.com',
 * //   'checkout.phone': '+11234567890'
 * // }
 * ```
 *
 * @param obj - Record of paths mapped to contact information (emails or phone numbers)
 * @returns A new object with duplicate values removed, keeping the first occurrence
 */
export function dedupe<T extends Record<string, string>>(obj: T): Partial<T> {
	const seen = new Set<string>();
	const result = {} as Partial<T>;

	for (const [key, value] of Object.entries(obj)) {
		if (!seen.has(value)) {
			seen.add(value);
			// @ts-expect-error - we know this is a string
			result[key] = value;
		}
	}

	return result;
}

const emailSchema = z.string().email();
const isValidPhone = (value: string) => {
	const isValidUSPhone = Helpers.Phone.isValidPhone(value);
	const isValidInternationalPhone = Helpers.Phone.isValidInternationalPhone(value);
	return isValidUSPhone || isValidInternationalPhone;
};
const isIPAddress = (value: string) => {
	const ipRegex =
		/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
	return ipRegex.test(value);
};
