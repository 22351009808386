import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/apps/web-admin/style/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmModalProvider"] */ "/home/runner/work/vyg/vyg/libs/ui/components/confirm/confirm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastInitializer"] */ "/home/runner/work/vyg/vyg/libs/ui/components/toast/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/auth/context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastMessageListener"] */ "/home/runner/work/vyg/vyg/libs/web/src/components/toast/listener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryClientProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/react-query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RealtimeContextProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/realtime.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/home/runner/work/vyg/vyg/node_modules/nuqs/dist/adapters/next/app.js");
