import { cn } from '../../utilities';

type Props = {
	className?: string;
	direction?: 'horizontal' | 'vertical';
};

export const Divider = ({ className, direction = 'horizontal' }: Props) => {
	return (
		<div
			className={cn(
				'bg-neutrals-main-border',
				direction === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
				className
			)}
		/>
	);
};
