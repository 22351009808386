// !OVERWRITE
// Generated by ts-to-zod
import { z } from 'zod';

import { ShopifySchema } from '@voyage-lab/shopify-api';

export const workflowActionAgentResponseSchema = z.object({
	reply_with_message: z.boolean().optional(),
	reply_message: z.array(z.any()).optional(),
	contact_support: z.boolean().optional(),
	support_email: z.string().optional().nullable(),
});

const workflowActionDiscountConfigShippingSchema = z.object({
	category: z.literal('shipping'),
	id: z.string().optional(),
	minimum: z.union([z.literal('subtotal'), z.literal('quantity'), z.literal('none')]),
	type: z.union([z.literal('static'), z.literal('dynamic'), z.literal('custom')]),
	code_prefix: z.string().max(14).optional(),
	auto_apply: z.boolean().optional(),
	freeShippingCodeDiscount: ShopifySchema.DiscountCodeFreeShippingInputSchema(),
});

const workflowActionDiscountConfigBasicSchema = z.object({
	id: z.string().optional(),
	category: z.union([z.literal('order'), z.literal('product')]),
	minimum: z.union([z.literal('subtotal'), z.literal('quantity'), z.literal('none')]),
	type: z.union([z.literal('static'), z.literal('dynamic'), z.literal('custom')]),
	code_prefix: z.string().max(14).optional(),
	auto_apply: z.boolean().optional(),
	basicCodeDiscount: ShopifySchema.DiscountCodeBasicInputSchema(),
});

const workflowTypeSchema = z.union([z.literal('shipping'), z.literal('percentage'), z.literal('fixed')]);

export const workflowActionDiscountConfigSchema = z.union([
	workflowActionDiscountConfigBasicSchema,
	workflowActionDiscountConfigShippingSchema,
]);

export const workflowActionDiscountSchema = z
	.object({
		type: workflowTypeSchema,
		value: z.number({ invalid_type_error: '' }).nullable(),
		duration: z.number().min(1).max(365),
		restrict_to_condition: z.boolean().optional().nullable(),
		config: workflowActionDiscountConfigSchema,
	})
	.transform((val, ctx) => {
		const discount = structuredClone(val);

		if (discount) {
			const { config } = discount;

			if (config.category === 'shipping') {
				const freeShippingCodeDiscount = config.freeShippingCodeDiscount;
				discount.value = 0;
				discount.type = 'shipping';

				// Minimum Requirement Transform ========
				if (config.minimum === 'quantity') {
					delete freeShippingCodeDiscount.minimumRequirement?.subtotal;
				} else if (config.minimum === 'subtotal') {
					delete freeShippingCodeDiscount.minimumRequirement?.quantity;
				} else {
					delete freeShippingCodeDiscount.minimumRequirement;
				}

				// Remove appliesOnOneTimePurchase and appliesOnSubscription if set to false, as these are not settable if subscription feature is not enabled on Shopify
				if (!freeShippingCodeDiscount?.appliesOnOneTimePurchase)
					delete freeShippingCodeDiscount.appliesOnOneTimePurchase;

				if (!freeShippingCodeDiscount?.appliesOnSubscription) {
					delete freeShippingCodeDiscount.appliesOnSubscription;
					delete freeShippingCodeDiscount.recurringCycleLimit;
				}
			}

			if (config.category !== 'shipping') {
				const basicCodeDiscount = config.basicCodeDiscount;

				// Discount Value ======== A discount can only have one of percentage, discountOnQuantity or discountAmount
				// =====> Percentage
				if (discount.type === 'percentage') {
					delete basicCodeDiscount.customerGets?.value?.discountAmount;

					if (basicCodeDiscount.customerGets) {
						const inputPercentage = discount.value;
						const calculatedPercentage =
							typeof inputPercentage === 'number' ? inputPercentage / 100 : undefined;
						basicCodeDiscount.customerGets = {
							...basicCodeDiscount.customerGets,
							value: { percentage: calculatedPercentage },
						};

						discount.value = inputPercentage;

						// Remove appliesOnOneTimePurchase and appliesOnSubscription if set to false, as these are not settable if subscription feature is not enabled on Shopify
						if (!basicCodeDiscount.customerGets?.appliesOnOneTimePurchase)
							delete basicCodeDiscount.customerGets.appliesOnOneTimePurchase;

						if (!basicCodeDiscount.customerGets?.appliesOnSubscription) {
							delete basicCodeDiscount.customerGets.appliesOnSubscription;
							delete basicCodeDiscount.recurringCycleLimit;
						}
					}
				}

				// =====> Fixed Amount
				if (discount.type !== 'percentage') {
					if (basicCodeDiscount.customerGets) {
						// Remove appliesOnOneTimePurchase and appliesOnSubscription if set to false, as these are not settable if subscription feature is not enabled on Shopify
						if (!basicCodeDiscount.customerGets?.appliesOnOneTimePurchase)
							delete basicCodeDiscount.customerGets.appliesOnOneTimePurchase;

						if (!basicCodeDiscount.customerGets?.appliesOnSubscription) {
							delete basicCodeDiscount.customerGets.appliesOnSubscription;
							delete basicCodeDiscount.recurringCycleLimit;
						}

						basicCodeDiscount.customerGets = {
							...basicCodeDiscount.customerGets,
							value: {
								discountAmount: {
									appliesOnEachItem: false,
									...(basicCodeDiscount.customerGets?.value?.discountAmount || {}),
									amount: discount.value,
								},
							},
						};
					}
				}

				// Item Selection Transform ========
				// =====> Specific Items, has product or collection, set all to false
				const hasProduct = basicCodeDiscount.customerGets?.items?.products?.productsToAdd?.length;
				const hasCollection = basicCodeDiscount.customerGets?.items?.collections?.add?.length;

				if ((hasProduct || hasCollection) && basicCodeDiscount?.customerGets?.items) {
					basicCodeDiscount.customerGets.items.all = false;
				}

				// =====> Remove product or collection if category is order
				if (config.category === 'order') {
					delete basicCodeDiscount.customerGets?.items?.products;
					delete basicCodeDiscount.customerGets?.items?.collections;

					// Set all to true
					if (basicCodeDiscount?.customerGets?.items) {
						basicCodeDiscount.customerGets.items.all = true;
					}
				}

				// // Item Selection Validation ========
				// // Only products or collections can be selected, not both
				// if (hasProduct && hasCollection) {
				// 	ctx.addIssue({
				// 		code: 'custom',
				// 		message: 'Only products or collections can be selected, not both',
				// 		path: ['action', 'discount', 'config', 'basicCodeDiscount', 'customerGets', 'items'],
				// 	});
				// }

				// Minimum Requirement Transform ========
				if (config.minimum === 'quantity') {
					delete basicCodeDiscount.minimumRequirement?.subtotal;
				} else if (config.minimum === 'subtotal') {
					delete basicCodeDiscount.minimumRequirement?.quantity;
				} else {
					delete basicCodeDiscount.minimumRequirement;
				}
			}
		}

		return { ...discount };
	});

const workflowDraftOrderRuleEventType = z.enum([
	'remove_product',
	'add_product',
	'replace_product',
	'remove_all_products',
	'apply_discount',
]);

const workflowDraftOrderRuleEventParams = z.object({
	product_ids: z.array(z.union([z.string(), z.number()])).optional(),
	product_id: z.union([z.string(), z.number()]).optional(),
	replace_with_product_id: z.union([z.string(), z.number()]).optional(),
	discount_percentage: z.number().optional(),
});

const workflowDraftOrderRuleConditionOperator = z.enum(['contains', 'in', 'containsAny', 'greaterThanInclusive']);

const workflowDraftOrderRuleCondition = z.object({
	operator: workflowDraftOrderRuleConditionOperator,
	path: z.string(),
	fact: z.string(),
	value: z.union([z.array(z.union([z.string(), z.number()])), z.number()]),
});

const workflowDraftOrderRule = z.object({
	event: z.object({
		type: workflowDraftOrderRuleEventType,
		params: workflowDraftOrderRuleEventParams,
	}),
	conditions: z.object({
		any: z.array(workflowDraftOrderRuleCondition),
	}),
});

export const workflowDraftOrderSchema = z.object({
	type: z.enum(['previous_order', 'specific_products', 'url']),
	rules: z.array(workflowDraftOrderRule),
});

export const workflowActionSchema = z
	.object({
		type: z.union([z.literal('skip'), z.literal('message'), z.literal('discount')]),
		draft_order: workflowDraftOrderSchema.optional(),
		discount: workflowActionDiscountSchema.optional(),
		agents_response: workflowActionAgentResponseSchema.optional(),
		greeting_message: z.any(),
	})
	.transform((val, ctx) => {
		if (val.type === 'message') {
			return {
				...val,
				discount: undefined,
			};
		}
		return val;
	});
