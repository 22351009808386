import Stripe from 'stripe';
import { v4 as uuid } from 'uuid';

import type { EmailService } from '@voyage-lab/core-email';
import type { DatabaseEntity, DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/schema';

import { Subscription } from '../../subscription';
import { Payment } from '../base';

const STRIPE_API_VERSION = '2023-08-16';

export class StripePaymentProcessor extends Payment {
	db: Subscription;
	email: EmailService;
	stripe: Stripe;
	returnUrl: string;

	constructor(props: {
		dbClient: PostgrestClientType;
		stripeApiKey: string;
		returnUrl: string;
		email: EmailService;
	}) {
		super();
		this.db = new Subscription(props.dbClient);
		this.email = props.email;
		this.returnUrl = props.returnUrl;
		this.stripe = new Stripe(props.stripeApiKey, {
			apiVersion: STRIPE_API_VERSION,
		});
	}

	#parseActiveStatus(status: Stripe.Subscription.Status) {
		const activeStatuses = {
			active: 'active',
			past_due: 'past_due',
			trialing: 'in_trial',
			unpaid: 'unpaid',
			paused: 'paused',
			incomplete: 'incomplete',
		} as { [x in Stripe.Subscription.Status]: DatabaseEnum['t_subscription_status'] };
		return activeStatuses[status];
	}

	async getRemoteSubscriptionsByCustomerId(customerId: string) {
		const res = await this.stripe.subscriptions.list({ customer: customerId, limit: 100 });
		return res.data;
	}

	getRemoteSubscription(id: string) {
		return this.stripe.subscriptions.retrieve(id);
	}

	async getRemoteCustomer(email: string) {
		const customers = await this.stripe.customers.list({ email });
		if (customers.data.length > 0) {
			return customers.data[0];
		} else {
			return null;
		}
	}

	createRemoteCustomer(props: { email: string; name: string; phone: string; metaData: Record<string, string> }) {
		return this.stripe.customers.create({
			email: props.email,
			name: props.name,
			phone: props.phone,
			metadata: props.metaData,
		});
	}

	async createPrice(props: { plan: DatabaseEntity['plans'] }) {
		const features = (props.plan as TypesT.PlanJson).extra_data.features?.map((feature) => ({
			name: feature.slice(0, 80),
		}));
		const product = await this.stripe.products.create({
			name: props.plan.name,
			description: props.plan.description,
			features: features || [],
			default_price_data: {
				currency: 'usd',
				unit_amount: props.plan.price * 100,
				recurring: { interval: 'month' },
			},
		});
		const price = await this.stripe.prices.create({
			currency: 'usd',
			product: product.id,
			unit_amount: props.plan.price * 100,
			recurring: { interval: 'month' },
		});
		await this.db.createPlanGateway({
			id: uuid(),
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
			gateway: 'stripe',
			plan_id: props.plan.id,
			external_id: price.id,
		});
		return price;
	}

	async createCustomPlan(props: { planId: string; rate: string; price: string }) {
		const plan = await this.db.getPlanById(props.planId);
		if (!plan) {
			throw new Error('Plan not found');
		}
		const customPlanName = `${plan.name} ($${props.price} - ${props.rate}%)`;
		const res = await this.db.createPlan({
			id: uuid(),
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
			description: `[CUSTOM] ${plan.description} ${customPlanName} Plan`,
			name: customPlanName,
			price: parseInt(props.price),
			usage_charge_percentage: parseInt(props.rate),
			extra_data: plan.extra_data,
			status: 'custom',
			frequency: 'MONTHLY',
		});
		if (!res.data) {
			throw new Error(res.error.message);
		}
		await this.createPrice({
			plan: res.data,
		});
		return res.data;
	}

	createCheckoutSession(props: {
		priceId: string;
		quantity: number;
		redirectUrl: string;
		customerId: string;
		trialPeriodDays?: number;
		metaData: Record<string, string>;
	}) {
		return this.stripe.checkout.sessions.create({
			mode: 'subscription',
			line_items: [
				{
					price: props.priceId,
					quantity: props.quantity,
				},
			],
			success_url: this.returnUrl,
			customer: props.customerId,
			subscription_data: {
				metadata: props.metaData,
				...(props.trialPeriodDays
					? {
							trial_period_days: props.trialPeriodDays,
						}
					: {}),
			},
		});
	}

	override async resetSubscriptionCheckout(subscriptionId: string) {
		const subscription = await this.db.getSubscriptionDetailsById(subscriptionId);
		if (!subscription) throw new Error('Subscription not found');

		const customerId = subscription?.subscription_payment_source?.external_id;
		if (!customerId) throw new Error('Customer ID not found');

		const priceId = subscription.plans?.plan_gateways[0].external_id;
		if (!priceId) throw new Error('Price ID not found');

		const extraData = subscription.extra_data as TypesT.SubscriptionJson['extra_data'];

		const trialDays = extraData?.trial_days || undefined;
		if (subscription.trial_start_at && !trialDays) throw new Error('Trial days not found');

		const billingEmail = subscription.billing_email;
		if (!billingEmail) throw new Error('Billing email not found');

		const checkoutSession = await this.createCheckoutSession({
			priceId: priceId,
			quantity: 1,
			redirectUrl: this.returnUrl,
			customerId: customerId,
			trialPeriodDays: trialDays,
			metaData: {
				brand_name: subscription.brands?.name || '',
				brand_id: subscription.brands?.id || '',
				brand_domain: subscription.brands?.domain || '',
				plan_id: subscription.plan_id,
				lr_subscription_id: subscription.id,
			},
		});

		const expiresAt = checkoutSession.expires_at ? new Date(checkoutSession.expires_at * 1000).toISOString() : '';

		await this.db.updateSubscription(subscription.id, {
			extra_data: {
				...(subscription.extra_data || {}),
				checkout_url: checkoutSession.url,
				checkout_expired_at: expiresAt,
			},
		});

		try {
			await this.email.sendEmail(
				this.db.generateEmail({
					email: billingEmail,
					returnUrl: checkoutSession.url || '',
					subscriptionName: subscription.plans?.name || '',
					trialDays,
				})
			);
		} catch (error) {}
		return checkoutSession;
	}

	override async subscribe(props: {
		billingEmail: string;
		brandId: string;
		planId: string;
		userName?: string;
		userPhone?: string;
		trialDays?: number;
	}) {
		const plan = await this.db.getPlanById(props.planId);
		const brand = await this.db.getBrandById(props.brandId);
		if (!plan || !brand) throw new Error('Plan or brand not found');
		if (!props.billingEmail) throw new Error('Billing email not found');

		let priceId = await this.db.getRemotePriceId(plan.id);
		if (!priceId) {
			const newRemotePlan = await this.createPrice({ plan: plan });
			priceId = newRemotePlan.id;
		}

		let customer = await this.getRemoteCustomer(props.billingEmail);
		if (!customer) {
			customer = await this.createRemoteCustomer({
				email: props.billingEmail,
				name: props.userName || brand.name,
				phone: props.userPhone || '',
				metaData: {
					brand_name: brand.name,
					brand_id: brand.id,
					brand_domain: brand.domain,
				},
			});
		}

		const subscription = await this.db.createSubscriptionDb({
			billingEmail: props.billingEmail,
			brandId: brand.id,
			customerId: customer.id,
			paymentSource: 'stripe',
			planId: plan.id,
			price: plan.price,
			trialDays: props.trialDays,
			subscriptionId: '',
		});

		const checkoutSession = await this.createCheckoutSession({
			priceId,
			quantity: 1,
			redirectUrl: this.returnUrl,
			customerId: customer.id,
			trialPeriodDays: props.trialDays,
			metaData: {
				brand_name: brand.name,
				brand_id: brand.id,
				brand_domain: brand.domain,
				plan_id: plan.id,
				lr_subscription_id: subscription.id,
			},
		});

		const subscriptionExtraData = subscription.extra_data || {};

		const expiresAt = checkoutSession.expires_at ? new Date(checkoutSession.expires_at * 1000).toISOString() : '';

		await this.db.updateSubscription(subscription.id, {
			extra_data: {
				...subscriptionExtraData,
				checkout_url: checkoutSession.url,
				checkout_expired_at: expiresAt,
				billing_email: props.billingEmail,
				trial_days: props.trialDays,
			},
		});

		try {
			await this.email.sendEmail(
				this.db.generateEmail({
					email: props.billingEmail,
					returnUrl: checkoutSession.url || '',
					subscriptionName: plan.name,
					trialDays: props.trialDays,
				})
			);
		} catch (error) {}

		return {
			url: checkoutSession.url,
		};
	}

	override async updatePendingSetupSubscription(subscription: DatabaseEntity['subscriptions']) {
		if (subscription.external_id) {
			const subscriptionData = await this.getRemoteSubscription(subscription.external_id);
			const subscriptionStatus = this.#parseActiveStatus(subscriptionData.status);
			if (subscriptionStatus) {
				return this.db.activateSubscription(subscription.id, subscriptionStatus);
			}
		} else {
			const paymentSource = await this.db.getPaymentSourceById(subscription.payment_source_id);
			let customerId = paymentSource?.external_id;
			if (!customerId) {
				if (subscription.billing_email) {
					const remoteCustomer = await this.getRemoteCustomer(subscription.billing_email);
					if (remoteCustomer) {
						customerId = remoteCustomer.id;
					} else {
						throw new Error('Customer not found');
					}
				} else {
					throw new Error('Billing email not found');
				}
			}
			const stripeSubscriptions = await this.getRemoteSubscriptionsByCustomerId(customerId);
			for (const stripeSubscription of stripeSubscriptions) {
				if (stripeSubscription.metadata['lr_subscription_id'] === subscription.id) {
					const subscriptionStatus = this.#parseActiveStatus(stripeSubscription.status);
					if (subscriptionStatus) {
						return this.db.activateSubscription(subscription.id, subscriptionStatus);
					}
				}
			}
		}
		return null;
	}

	async cancelSubscription(props: { subscriptionId: string }) {
		const subscription = await this.db.getSubscriptionById(props.subscriptionId);
		if (!subscription?.external_id) throw new Error('Invalid Subscription');
		await this.stripe.subscriptions.cancel(subscription.external_id);
		return null;
	}

	async updateSubscription(props: { subscriptionId: string; planId: string }) {
		const subscription = await this.db.getSubscriptionById(props.subscriptionId);
		if (!subscription?.external_id) throw new Error('Subscription not found');

		let priceId = await this.db.getRemotePriceId(props.planId);

		if (!priceId) {
			const plan = await this.db.getPlanById(props.planId);
			if (!plan) throw new Error('Plan not found');
			const newRemotePlan = await this.createPrice({ plan: plan });
			priceId = newRemotePlan.id;
		}

		const stripeSubscription = await this.getRemoteSubscription(subscription.external_id);

		await this.stripe.subscriptions.update(subscription.external_id, {
			cancel_at_period_end: false,
			proration_behavior: 'always_invoice',
			items: [
				{ price: priceId, quantity: 1 },
				...stripeSubscription.items.data.map((item) => ({ id: item.id, deleted: true })),
			],
		});

		return null;
	}

	async editBillingInfo(props: { brandId: string; returnUrl: string }): Promise<string> {
		const subscription = await this.db.getActiveSubscriptionByBrandId(props.brandId);
		if (!subscription) throw new Error('No active subscription found');

		const paymentSource = await this.db.getPaymentSourceById(subscription.payment_source_id);
		if (!paymentSource) throw new Error('Payment Source not found.');

		const session = await this.stripe.billingPortal.sessions.create({
			customer: paymentSource.external_id,
			return_url: props.returnUrl,
		});
		return session.url;
	}
}
