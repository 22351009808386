'use client';

import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

import type { TokenEntity } from '@voyage-lab/core-auth';
import { AppError } from '@voyage-lab/core-common';

import type { AuthSession } from '../auth';
import { auth, signOut, updateAuth } from '../auth';

const AUTH_LIFE_TIME = 1000 * 6 * 5; // 5 minutes
const AUTH_REFETCH_INTERVAL = 10 * 1000; // 10 seconds
const AUTH_QUERY_KEY = 'auth';

const AuthContext = createContext<{
	authQuery: UseQueryResult<AuthSession>;
	updateAuthMutation: UseMutationResult<Partial<TokenEntity> | undefined, unknown, Partial<TokenEntity>, unknown>;
	signOutMutation: UseMutationResult<void, unknown, string | undefined, unknown>;
}>(null!);

export const AuthContextProvider = ({ children, app }: { children: React.ReactNode; app?: 'admin' | 'client' }) => {
	const queryClient = useQueryClient();
	const authQuery = useQuery({
		queryKey: [AUTH_QUERY_KEY],
		queryFn: async () => {
			const session = await auth({ optional: true, includeProfile: true, renewToken: true, app });

			if (session === null) throw new AppError('Unauthenticated', 'UnauthenticatedError');

			return session;
		},
		refetchInterval: AUTH_REFETCH_INTERVAL,
		staleTime: AUTH_LIFE_TIME,
		gcTime: AUTH_LIFE_TIME,
		refetchOnWindowFocus: true,
		enabled: typeof document !== 'undefined' && document.visibilityState === 'visible',
	});

	const signOutMutation = useMutation({
		mutationFn: async (redirectUrl?: string) => {
			await signOut({ redirectUrl });
			queryClient.removeQueries({ queryKey: [AUTH_QUERY_KEY] });
		},
	});

	const updateAuthMutation = useMutation({
		mutationFn: async (token: Partial<TokenEntity>) => {
			const updatedAuth = await updateAuth(token);
			await authQuery.refetch();
			return updatedAuth;
		},
	});

	return (
		<AuthContext.Provider
			value={{
				authQuery,
				updateAuthMutation,
				signOutMutation,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export function useAuthContext() {
	const context = useContext(AuthContext);
	if (!context) throw new Error('useAuthContext must be used within a AuthContextProvider');
	return context;
}
