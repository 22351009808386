import React from 'react';

import { cn } from '../../utilities';
import type { LayoutProps, TailwindStyleMapperType } from './layout.types';
import { LayoutStylePrefix } from './layout.util';

export const Layout = React.forwardRef<HTMLDivElement, LayoutProps>((props, ref) => {
	const {
		children,
		className,
		onClick,
		onMouseLeave,
		onMouseDown,
		onMouseOver,
		'data-testid': testId,
		...style
	} = props;
	const customClass = Object.entries(style).reduce((acc, [key, value]) => {
		const baseStyle = LayoutStylePrefix[key as keyof TailwindStyleMapperType];
		if (typeof value === 'object') {
			return `${acc} ${Object.entries(value).reduce(
				(acc, [screen, style]) => `${acc} ${screen === 'base' ? '' : `${screen}:`}${baseStyle}${style}`,
				''
			)}`;
		}
		return `${acc} ${baseStyle}${value}`;
	}, '');

	return (
		<div
			ref={ref}
			onMouseLeave={onMouseLeave}
			onMouseDown={onMouseDown}
			onMouseOver={onMouseOver}
			onClick={onClick}
			data-testid={testId}
			className={cn([customClass, className, !!onClick && 'cursor-pointer'])}
		>
			{children}
		</div>
	);
});
