import {
	addDays,
	addHours,
	addMinutes,
	addMonths,
	addWeeks,
	addYears,
	differenceInDays,
	differenceInMonths,
	differenceInYears,
	endOfMonth,
	endOfYear,
	isSameMonth,
	isSameYear,
	isThisYear,
	isToday,
	isYesterday,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from 'date-fns';

/**
 * ### Get date object from date
 * @param date Date or string to get date object from
 * @param isLocal If `true`, converts date to local time
 * @returns A date object with day, month, year, hour, minute and second
 */
export const getDateObject = (rawDate?: Date | string, isLocal = true) => {
	if (!isValidDate(rawDate)) return null;

	const date = new Date(rawDate);

	// convert to local time if needed
	if (isLocal) {
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	}

	return {
		day: date.getDate(),
		month: date.getMonth() + 1,
		year: date.getFullYear(),
		hour: date.getHours(),
		minute: date.getMinutes(),
		second: date.getSeconds(),
	};
};

/**
 * ### Checks if date is valid and parsable as a Date object
 * @param date Date or string to check
 * @returns `true` if date is valid, `false` otherwise
 */
export const isValidDate = (date?: Date | string | null): date is Date => {
	if (!date) return false;

	if (new Date(date).toString() === 'Invalid Date') return false;

	return true;
};

/**
 * ### Get month name from month number
 * @param month Month number
 * @returns Month name
 * @example getMonthName(1) // Jan
 */
export const getMonthName = (month: number) => {
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	return monthNames[month - 1];
};

export const floorHours = (date: Date) => {
	return new Date(date.setMinutes(0, 0, 0));
};

/**
 * ### Check past time in second
 * @param date seconds
 * @returns `true` if date is past, `false` otherwise
 */
export const isPastTime = (date?: number) => {
	if (!date) return true;
	return date < Date.now();
};

/**
 * ### Check past time in second
 * @param date
 * @returns addCurrentTime("2021-01-01T00:00:00.000Z") // "2021-01-01T04:35:23.635Z"
 */
export const addCurrentTime = (dateTime: Date) => {
	const currentTime = new Date();
	const hour = currentTime.getHours();
	const minute = currentTime.getMinutes();
	const second = currentTime.getSeconds();
	const millisecond = currentTime.getMilliseconds();
	const date = new Date(dateTime);
	date.setHours(hour, minute, second, millisecond);
	return date;
};

/**
 * ### Add current UTC time to date
 * @param date
 */
export const addCurrentUTCTime = (dateTime: Date) => {
	const currentTime = new Date();
	const hour = currentTime.getUTCHours();
	const minute = currentTime.getUTCMinutes();
	const second = currentTime.getUTCSeconds();
	const millisecond = currentTime.getUTCMilliseconds();
	const date = new Date(dateTime);
	date.setUTCHours(hour, minute, second, millisecond);
	return date;
};

export const isStartOfAMonth = (date: Date) => {
	return date.getDate() === startOfMonth(date).getDate();
};

export const isEndOfAMonth = (date: Date) => {
	return date.getDate() === endOfMonth(date).getDate();
};

export const isStartOfYear = (date: Date) => {
	const sameDate = date.getDate() === startOfYear(date).getDate();
	const sameMonth = date.getMonth() === startOfYear(date).getMonth();
	return sameDate && sameMonth;
};

export const isEndOfAYear = (date: Date) => {
	const sameDate = date.getDate() === endOfYear(date).getDate();
	const sameMonth = date.getMonth() === endOfYear(date).getMonth();
	return sameDate && sameMonth;
};

export const matchDate = (date: string, dateToMatch: string) => {
	const date1 = new Date(date);
	const date2 = new Date(dateToMatch);
	const sameDate = date1.getDate() === date2.getDate();
	const sameMonth = date1.getMonth() === date2.getMonth();
	const sameYear = date1.getFullYear() === date2.getFullYear();
	return sameDate && sameMonth && sameYear;
};

export const matchDateTime = (date: string, dateToMatch: string) => {
	const date1 = new Date(date);
	const date2 = new Date(dateToMatch);
	return date1.getTime() === date2.getTime();
};

export const endOfDay = (date: Date) => {
	const result = new Date(date);
	result.setHours(23, 59, 59, 999);
	return result;
};

export const startOfToday = () => {
	return startOfDay(new Date());
};

export const getYesterday = () => {
	return addDays(new Date(), -1);
};

export {
	addHours,
	addMinutes,
	addDays,
	addWeeks,
	addMonths,
	addYears,
	differenceInDays,
	differenceInMonths,
	differenceInYears,
	isToday,
	isYesterday,
	startOfMonth,
	endOfMonth,
	isThisYear,
	isSameMonth,
	startOfWeek,
	startOfYear,
	endOfYear,
	isSameYear,
	startOfDay,
};
