/* eslint-disable */
// @ts-nocheck
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	DateTime: { input: any; output: any };
};

export type AccountPermissionsMembers = {
	__typename?: 'AccountPermissionsMembers';
	action?: Maybe<Scalars['String']['output']>;
	attribute?: Maybe<Scalars['String']['output']>;
	audience_id?: Maybe<Scalars['String']['output']>;
	audience_type?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	state?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type AccountPermissionsOrderByInput = {
	action?: InputMaybe<OrderBy>;
	attribute?: InputMaybe<OrderBy>;
	audience_id?: InputMaybe<OrderBy>;
	audience_type?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	state?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type AccountPermissionsWhereInput = {
	AND?: InputMaybe<Array<AccountPermissionsWhereInput>>;
	OR?: InputMaybe<Array<AccountPermissionsWhereInput>>;
	action?: InputMaybe<StringFilter>;
	attribute?: InputMaybe<StringFilter>;
	audience_id?: InputMaybe<StringFilter>;
	audience_type?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	state?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type BrandAuditLogMembers = {
	__typename?: 'BrandAuditLogMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	description?: Maybe<Scalars['String']['output']>;
	is_compliant?: Maybe<Scalars['String']['output']>;
	issue_type?: Maybe<Scalars['String']['output']>;
	last_checked?: Maybe<TimeDimension>;
	rule_id?: Maybe<Scalars['String']['output']>;
	status?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type BrandAuditLogOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	description?: InputMaybe<OrderBy>;
	is_compliant?: InputMaybe<OrderBy>;
	issue_type?: InputMaybe<OrderBy>;
	last_checked?: InputMaybe<OrderBy>;
	rule_id?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type BrandAuditLogWhereInput = {
	AND?: InputMaybe<Array<BrandAuditLogWhereInput>>;
	OR?: InputMaybe<Array<BrandAuditLogWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	description?: InputMaybe<StringFilter>;
	is_compliant?: InputMaybe<StringFilter>;
	issue_type?: InputMaybe<StringFilter>;
	last_checked?: InputMaybe<DateTimeFilter>;
	rule_id?: InputMaybe<StringFilter>;
	status?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type BrandIntegrationsMembers = {
	__typename?: 'BrandIntegrationsMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	integration_id?: Maybe<Scalars['String']['output']>;
	is_enabled?: Maybe<Scalars['String']['output']>;
	lookup_id?: Maybe<Scalars['String']['output']>;
	settings?: Maybe<Scalars['String']['output']>;
	settings_shopify_id?: Maybe<Scalars['String']['output']>;
	status?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type BrandIntegrationsOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	integration_id?: InputMaybe<OrderBy>;
	is_enabled?: InputMaybe<OrderBy>;
	lookup_id?: InputMaybe<OrderBy>;
	settings?: InputMaybe<OrderBy>;
	settings_shopify_id?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type BrandIntegrationsWhereInput = {
	AND?: InputMaybe<Array<BrandIntegrationsWhereInput>>;
	OR?: InputMaybe<Array<BrandIntegrationsWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	integration_id?: InputMaybe<StringFilter>;
	is_enabled?: InputMaybe<StringFilter>;
	lookup_id?: InputMaybe<StringFilter>;
	settings?: InputMaybe<StringFilter>;
	settings_shopify_id?: InputMaybe<StringFilter>;
	status?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type BrandStatsMembers = {
	__typename?: 'BrandStatsMembers';
	created_at?: Maybe<TimeDimension>;
	recovered_aov?: Maybe<Scalars['Float']['output']>;
	total_channel?: Maybe<Scalars['Float']['output']>;
	total_conversation?: Maybe<Scalars['Float']['output']>;
	total_recovered?: Maybe<Scalars['Float']['output']>;
	total_recovered_amount?: Maybe<Scalars['Float']['output']>;
	total_recovered_orders?: Maybe<Scalars['Float']['output']>;
	total_recurring_charges?: Maybe<Scalars['Float']['output']>;
	total_replied_conversation?: Maybe<Scalars['Float']['output']>;
	total_sales?: Maybe<Scalars['Float']['output']>;
	total_sales_amount?: Maybe<Scalars['Float']['output']>;
	total_subscription_charges?: Maybe<Scalars['Float']['output']>;
	total_unsubscribed_channel?: Maybe<Scalars['Float']['output']>;
	total_usage_charges?: Maybe<Scalars['Float']['output']>;
};

export type BrandStatsOrderByInput = {
	created_at?: InputMaybe<OrderBy>;
	recovered_aov?: InputMaybe<OrderBy>;
	total_channel?: InputMaybe<OrderBy>;
	total_conversation?: InputMaybe<OrderBy>;
	total_recovered?: InputMaybe<OrderBy>;
	total_recovered_amount?: InputMaybe<OrderBy>;
	total_recovered_orders?: InputMaybe<OrderBy>;
	total_recurring_charges?: InputMaybe<OrderBy>;
	total_replied_conversation?: InputMaybe<OrderBy>;
	total_sales?: InputMaybe<OrderBy>;
	total_sales_amount?: InputMaybe<OrderBy>;
	total_subscription_charges?: InputMaybe<OrderBy>;
	total_unsubscribed_channel?: InputMaybe<OrderBy>;
	total_usage_charges?: InputMaybe<OrderBy>;
};

export type BrandStatsWhereInput = {
	AND?: InputMaybe<Array<BrandStatsWhereInput>>;
	OR?: InputMaybe<Array<BrandStatsWhereInput>>;
	created_at?: InputMaybe<DateTimeFilter>;
	recovered_aov?: InputMaybe<FloatFilter>;
	total_channel?: InputMaybe<FloatFilter>;
	total_conversation?: InputMaybe<FloatFilter>;
	total_recovered?: InputMaybe<FloatFilter>;
	total_recovered_amount?: InputMaybe<FloatFilter>;
	total_recovered_orders?: InputMaybe<FloatFilter>;
	total_recurring_charges?: InputMaybe<FloatFilter>;
	total_replied_conversation?: InputMaybe<FloatFilter>;
	total_sales?: InputMaybe<FloatFilter>;
	total_sales_amount?: InputMaybe<FloatFilter>;
	total_subscription_charges?: InputMaybe<FloatFilter>;
	total_unsubscribed_channel?: InputMaybe<FloatFilter>;
	total_usage_charges?: InputMaybe<FloatFilter>;
};

export type BrandSubscriptionMembers = {
	__typename?: 'BrandSubscriptionMembers';
	activated_at?: Maybe<TimeDimension>;
	brand_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	created_at_utc?: Maybe<TimeDimension>;
	external_id?: Maybe<Scalars['String']['output']>;
	gateway?: Maybe<Scalars['String']['output']>;
	gateway_account_id?: Maybe<Scalars['String']['output']>;
	gateway_external_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	last_billed_at?: Maybe<TimeDimension>;
	legacy_id?: Maybe<Scalars['String']['output']>;
	payment_source_id?: Maybe<Scalars['String']['output']>;
	plan_id?: Maybe<Scalars['String']['output']>;
	plan_name?: Maybe<Scalars['String']['output']>;
	started_at?: Maybe<TimeDimension>;
	status?: Maybe<Scalars['String']['output']>;
	total_charges?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
	usage_charge_percentage?: Maybe<Scalars['Float']['output']>;
};

export type BrandSubscriptionOrderByInput = {
	activated_at?: InputMaybe<OrderBy>;
	brand_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	created_at_utc?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	gateway?: InputMaybe<OrderBy>;
	gateway_account_id?: InputMaybe<OrderBy>;
	gateway_external_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	last_billed_at?: InputMaybe<OrderBy>;
	legacy_id?: InputMaybe<OrderBy>;
	payment_source_id?: InputMaybe<OrderBy>;
	plan_id?: InputMaybe<OrderBy>;
	plan_name?: InputMaybe<OrderBy>;
	started_at?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	total_charges?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	usage_charge_percentage?: InputMaybe<OrderBy>;
};

export type BrandSubscriptionWhereInput = {
	AND?: InputMaybe<Array<BrandSubscriptionWhereInput>>;
	OR?: InputMaybe<Array<BrandSubscriptionWhereInput>>;
	activated_at?: InputMaybe<DateTimeFilter>;
	brand_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	created_at_utc?: InputMaybe<DateTimeFilter>;
	external_id?: InputMaybe<StringFilter>;
	gateway?: InputMaybe<StringFilter>;
	gateway_account_id?: InputMaybe<StringFilter>;
	gateway_external_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	last_billed_at?: InputMaybe<DateTimeFilter>;
	legacy_id?: InputMaybe<StringFilter>;
	payment_source_id?: InputMaybe<StringFilter>;
	plan_id?: InputMaybe<StringFilter>;
	plan_name?: InputMaybe<StringFilter>;
	started_at?: InputMaybe<DateTimeFilter>;
	status?: InputMaybe<StringFilter>;
	total_charges?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	usage_charge_percentage?: InputMaybe<FloatFilter>;
};

export type BrandsMembers = {
	__typename?: 'BrandsMembers';
	_domain?: Maybe<Scalars['String']['output']>;
	_route?: Maybe<Scalars['String']['output']>;
	address?: Maybe<Scalars['String']['output']>;
	aov?: Maybe<Scalars['Float']['output']>;
	billing_customer_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	created_at_utc?: Maybe<TimeDimension>;
	domain?: Maybe<Scalars['String']['output']>;
	estimated_earnings?: Maybe<Scalars['Float']['output']>;
	faq_page_url?: Maybe<Scalars['String']['output']>;
	faq_scraped_at?: Maybe<TimeDimension>;
	hubspot_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	industry?: Maybe<Scalars['String']['output']>;
	is_enabled?: Maybe<Scalars['String']['output']>;
	lead_value?: Maybe<Scalars['Float']['output']>;
	legacy_id?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	name?: Maybe<Scalars['String']['output']>;
	privacy_policy_url?: Maybe<Scalars['String']['output']>;
	support_email?: Maybe<Scalars['String']['output']>;
	tenant_id?: Maybe<Scalars['String']['output']>;
	tos_url?: Maybe<Scalars['String']['output']>;
	total_contacts?: Maybe<Scalars['Float']['output']>;
	total_earnings?: Maybe<Scalars['Float']['output']>;
	total_sales?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
	yearly_revenue?: Maybe<Scalars['Float']['output']>;
};

export type BrandsOrderByInput = {
	_domain?: InputMaybe<OrderBy>;
	_route?: InputMaybe<OrderBy>;
	address?: InputMaybe<OrderBy>;
	aov?: InputMaybe<OrderBy>;
	billing_customer_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	created_at_utc?: InputMaybe<OrderBy>;
	domain?: InputMaybe<OrderBy>;
	estimated_earnings?: InputMaybe<OrderBy>;
	faq_page_url?: InputMaybe<OrderBy>;
	faq_scraped_at?: InputMaybe<OrderBy>;
	hubspot_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	industry?: InputMaybe<OrderBy>;
	is_enabled?: InputMaybe<OrderBy>;
	lead_value?: InputMaybe<OrderBy>;
	legacy_id?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	privacy_policy_url?: InputMaybe<OrderBy>;
	support_email?: InputMaybe<OrderBy>;
	tenant_id?: InputMaybe<OrderBy>;
	tos_url?: InputMaybe<OrderBy>;
	total_contacts?: InputMaybe<OrderBy>;
	total_earnings?: InputMaybe<OrderBy>;
	total_sales?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	yearly_revenue?: InputMaybe<OrderBy>;
};

export type BrandsWhereInput = {
	AND?: InputMaybe<Array<BrandsWhereInput>>;
	OR?: InputMaybe<Array<BrandsWhereInput>>;
	_domain?: InputMaybe<StringFilter>;
	_route?: InputMaybe<StringFilter>;
	address?: InputMaybe<StringFilter>;
	aov?: InputMaybe<FloatFilter>;
	billing_customer_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	created_at_utc?: InputMaybe<DateTimeFilter>;
	domain?: InputMaybe<StringFilter>;
	estimated_earnings?: InputMaybe<FloatFilter>;
	faq_page_url?: InputMaybe<StringFilter>;
	faq_scraped_at?: InputMaybe<DateTimeFilter>;
	hubspot_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	industry?: InputMaybe<StringFilter>;
	is_enabled?: InputMaybe<StringFilter>;
	lead_value?: InputMaybe<FloatFilter>;
	legacy_id?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	name?: InputMaybe<StringFilter>;
	privacy_policy_url?: InputMaybe<StringFilter>;
	support_email?: InputMaybe<StringFilter>;
	tenant_id?: InputMaybe<StringFilter>;
	tos_url?: InputMaybe<StringFilter>;
	total_contacts?: InputMaybe<FloatFilter>;
	total_earnings?: InputMaybe<FloatFilter>;
	total_sales?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	yearly_revenue?: InputMaybe<FloatFilter>;
};

export type CartsMembers = {
	__typename?: 'CartsMembers';
	brand_integration_id?: Maybe<Scalars['String']['output']>;
	contact_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	data?: Maybe<Scalars['String']['output']>;
	external_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	state?: Maybe<Scalars['String']['output']>;
	total?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type CartsOrderByInput = {
	brand_integration_id?: InputMaybe<OrderBy>;
	contact_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	data?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	state?: InputMaybe<OrderBy>;
	total?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type CartsWhereInput = {
	AND?: InputMaybe<Array<CartsWhereInput>>;
	OR?: InputMaybe<Array<CartsWhereInput>>;
	brand_integration_id?: InputMaybe<StringFilter>;
	contact_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	data?: InputMaybe<StringFilter>;
	external_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	state?: InputMaybe<StringFilter>;
	total?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type CheckoutsMembers = {
	__typename?: 'CheckoutsMembers';
	abandoned_at?: Maybe<TimeDimension>;
	brand_integration_id?: Maybe<Scalars['String']['output']>;
	checkout_details?: Maybe<Scalars['String']['output']>;
	cleaned_raw_data?: Maybe<Scalars['String']['output']>;
	completed_at?: Maybe<TimeDimension>;
	contact_id?: Maybe<Scalars['String']['output']>;
	/** The count of conversations associated with the checkouts. */
	conversations_count?: Maybe<Scalars['Float']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	currency_code?: Maybe<Scalars['String']['output']>;
	error?: Maybe<Scalars['String']['output']>;
	external_id?: Maybe<Scalars['String']['output']>;
	extra_data?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	recovered_count?: Maybe<Scalars['Float']['output']>;
	shipping_country_code?: Maybe<Scalars['String']['output']>;
	shipping_province?: Maybe<Scalars['String']['output']>;
	shipping_tz?: Maybe<Scalars['String']['output']>;
	skip_reason?: Maybe<Scalars['String']['output']>;
	state?: Maybe<Scalars['String']['output']>;
	total_price?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type CheckoutsOrderByInput = {
	abandoned_at?: InputMaybe<OrderBy>;
	brand_integration_id?: InputMaybe<OrderBy>;
	checkout_details?: InputMaybe<OrderBy>;
	cleaned_raw_data?: InputMaybe<OrderBy>;
	completed_at?: InputMaybe<OrderBy>;
	contact_id?: InputMaybe<OrderBy>;
	conversations_count?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	currency_code?: InputMaybe<OrderBy>;
	error?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	recovered_count?: InputMaybe<OrderBy>;
	shipping_country_code?: InputMaybe<OrderBy>;
	shipping_province?: InputMaybe<OrderBy>;
	shipping_tz?: InputMaybe<OrderBy>;
	skip_reason?: InputMaybe<OrderBy>;
	state?: InputMaybe<OrderBy>;
	total_price?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type CheckoutsWhereInput = {
	AND?: InputMaybe<Array<CheckoutsWhereInput>>;
	OR?: InputMaybe<Array<CheckoutsWhereInput>>;
	abandoned_at?: InputMaybe<DateTimeFilter>;
	brand_integration_id?: InputMaybe<StringFilter>;
	checkout_details?: InputMaybe<StringFilter>;
	cleaned_raw_data?: InputMaybe<StringFilter>;
	completed_at?: InputMaybe<DateTimeFilter>;
	contact_id?: InputMaybe<StringFilter>;
	conversations_count?: InputMaybe<FloatFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	currency_code?: InputMaybe<StringFilter>;
	error?: InputMaybe<StringFilter>;
	external_id?: InputMaybe<StringFilter>;
	extra_data?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	recovered_count?: InputMaybe<FloatFilter>;
	shipping_country_code?: InputMaybe<StringFilter>;
	shipping_province?: InputMaybe<StringFilter>;
	shipping_tz?: InputMaybe<StringFilter>;
	skip_reason?: InputMaybe<StringFilter>;
	state?: InputMaybe<StringFilter>;
	total_price?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type ContactChannelsMembers = {
	__typename?: 'ContactChannelsMembers';
	brand_integration_id?: Maybe<Scalars['String']['output']>;
	compliance_type?: Maybe<Scalars['String']['output']>;
	contact_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	external_id?: Maybe<Scalars['String']['output']>;
	extra_data?: Maybe<Scalars['String']['output']>;
	last_engaged_at?: Maybe<TimeDimension>;
	status?: Maybe<Scalars['String']['output']>;
	unsubscribe_count?: Maybe<Scalars['Float']['output']>;
	unsubscribe_rate?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
	username?: Maybe<Scalars['String']['output']>;
};

export type ContactChannelsOrderByInput = {
	brand_integration_id?: InputMaybe<OrderBy>;
	compliance_type?: InputMaybe<OrderBy>;
	contact_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	last_engaged_at?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	unsubscribe_count?: InputMaybe<OrderBy>;
	unsubscribe_rate?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	username?: InputMaybe<OrderBy>;
};

export type ContactChannelsWhereInput = {
	AND?: InputMaybe<Array<ContactChannelsWhereInput>>;
	OR?: InputMaybe<Array<ContactChannelsWhereInput>>;
	brand_integration_id?: InputMaybe<StringFilter>;
	compliance_type?: InputMaybe<StringFilter>;
	contact_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	external_id?: InputMaybe<StringFilter>;
	extra_data?: InputMaybe<StringFilter>;
	last_engaged_at?: InputMaybe<DateTimeFilter>;
	status?: InputMaybe<StringFilter>;
	unsubscribe_count?: InputMaybe<FloatFilter>;
	unsubscribe_rate?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	username?: InputMaybe<StringFilter>;
};

export type ContactsMembers = {
	__typename?: 'ContactsMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	buyer_accepts_sms_marketing?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	external_id?: Maybe<Scalars['String']['output']>;
	family_name?: Maybe<Scalars['String']['output']>;
	full_name?: Maybe<Scalars['String']['output']>;
	given_name?: Maybe<Scalars['String']['output']>;
	legacy_id?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	source?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type ContactsOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	buyer_accepts_sms_marketing?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	family_name?: InputMaybe<OrderBy>;
	full_name?: InputMaybe<OrderBy>;
	given_name?: InputMaybe<OrderBy>;
	legacy_id?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	source?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type ContactsWhereInput = {
	AND?: InputMaybe<Array<ContactsWhereInput>>;
	OR?: InputMaybe<Array<ContactsWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	buyer_accepts_sms_marketing?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	external_id?: InputMaybe<StringFilter>;
	family_name?: InputMaybe<StringFilter>;
	full_name?: InputMaybe<StringFilter>;
	given_name?: InputMaybe<StringFilter>;
	legacy_id?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	source?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConversationFeedbackMembers = {
	__typename?: 'ConversationFeedbackMembers';
	comment?: Maybe<Scalars['String']['output']>;
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	id?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	message_id?: Maybe<Scalars['String']['output']>;
	rating?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type ConversationFeedbackOrderByInput = {
	comment?: InputMaybe<OrderBy>;
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	message_id?: InputMaybe<OrderBy>;
	rating?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type ConversationFeedbackWhereInput = {
	AND?: InputMaybe<Array<ConversationFeedbackWhereInput>>;
	OR?: InputMaybe<Array<ConversationFeedbackWhereInput>>;
	comment?: InputMaybe<StringFilter>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	message_id?: InputMaybe<StringFilter>;
	rating?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConversationFilesMembers = {
	__typename?: 'ConversationFilesMembers';
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	file_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	message_id?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type ConversationFilesOrderByInput = {
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	file_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	message_id?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type ConversationFilesWhereInput = {
	AND?: InputMaybe<Array<ConversationFilesWhereInput>>;
	OR?: InputMaybe<Array<ConversationFilesWhereInput>>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	file_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	message_id?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConversationMessagesMembers = {
	__typename?: 'ConversationMessagesMembers';
	body?: Maybe<Scalars['String']['output']>;
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	delivery_count?: Maybe<Scalars['Float']['output']>;
	delivery_rate?: Maybe<Scalars['Float']['output']>;
	direction?: Maybe<Scalars['String']['output']>;
	external_id?: Maybe<Scalars['String']['output']>;
	extra_data?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	outgoing_count?: Maybe<Scalars['Float']['output']>;
	reply_count?: Maybe<Scalars['Float']['output']>;
	reply_rate?: Maybe<Scalars['Float']['output']>;
	scheduled_for?: Maybe<TimeDimension>;
	sent_at?: Maybe<TimeDimension>;
	sent_message_count?: Maybe<Scalars['Float']['output']>;
	status?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type ConversationMessagesOrderByInput = {
	body?: InputMaybe<OrderBy>;
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	delivery_count?: InputMaybe<OrderBy>;
	delivery_rate?: InputMaybe<OrderBy>;
	direction?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	outgoing_count?: InputMaybe<OrderBy>;
	reply_count?: InputMaybe<OrderBy>;
	reply_rate?: InputMaybe<OrderBy>;
	scheduled_for?: InputMaybe<OrderBy>;
	sent_at?: InputMaybe<OrderBy>;
	sent_message_count?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type ConversationMessagesWhereInput = {
	AND?: InputMaybe<Array<ConversationMessagesWhereInput>>;
	OR?: InputMaybe<Array<ConversationMessagesWhereInput>>;
	body?: InputMaybe<StringFilter>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	delivery_count?: InputMaybe<FloatFilter>;
	delivery_rate?: InputMaybe<FloatFilter>;
	direction?: InputMaybe<StringFilter>;
	external_id?: InputMaybe<StringFilter>;
	extra_data?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	outgoing_count?: InputMaybe<FloatFilter>;
	reply_count?: InputMaybe<FloatFilter>;
	reply_rate?: InputMaybe<FloatFilter>;
	scheduled_for?: InputMaybe<DateTimeFilter>;
	sent_at?: InputMaybe<DateTimeFilter>;
	sent_message_count?: InputMaybe<FloatFilter>;
	status?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConversationsMembers = {
	__typename?: 'ConversationsMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	cart_id?: Maybe<Scalars['String']['output']>;
	channel_id?: Maybe<Scalars['String']['output']>;
	checkout_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	extra_data?: Maybe<Scalars['String']['output']>;
	front_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	is_hidden?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	reply_count?: Maybe<Scalars['Float']['output']>;
	sent_count?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
	workflow_id?: Maybe<Scalars['String']['output']>;
};

export type ConversationsOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	cart_id?: InputMaybe<OrderBy>;
	channel_id?: InputMaybe<OrderBy>;
	checkout_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	front_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	is_hidden?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	reply_count?: InputMaybe<OrderBy>;
	sent_count?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	workflow_id?: InputMaybe<OrderBy>;
};

export type ConversationsWhereInput = {
	AND?: InputMaybe<Array<ConversationsWhereInput>>;
	OR?: InputMaybe<Array<ConversationsWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	cart_id?: InputMaybe<StringFilter>;
	channel_id?: InputMaybe<StringFilter>;
	checkout_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	extra_data?: InputMaybe<StringFilter>;
	front_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	is_hidden?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	reply_count?: InputMaybe<FloatFilter>;
	sent_count?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	workflow_id?: InputMaybe<StringFilter>;
};

export type DateTimeFilter = {
	afterDate?: InputMaybe<Scalars['String']['input']>;
	afterOrOnDate?: InputMaybe<Scalars['String']['input']>;
	beforeDate?: InputMaybe<Scalars['String']['input']>;
	beforeOrOnDate?: InputMaybe<Scalars['String']['input']>;
	equals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	inDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notEquals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notInDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DiscountCodesMembers = {
	__typename?: 'DiscountCodesMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	code?: Maybe<Scalars['String']['output']>;
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	external_id?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type DiscountCodesOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	code?: InputMaybe<OrderBy>;
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type DiscountCodesWhereInput = {
	AND?: InputMaybe<Array<DiscountCodesWhereInput>>;
	OR?: InputMaybe<Array<DiscountCodesWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	code?: InputMaybe<StringFilter>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	external_id?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type DiscountRulesMembers = {
	__typename?: 'DiscountRulesMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	duration?: Maybe<Scalars['Float']['output']>;
	legacy_discount_code?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	type?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
	value?: Maybe<Scalars['String']['output']>;
};

export type DiscountRulesOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	duration?: InputMaybe<OrderBy>;
	legacy_discount_code?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	type?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	value?: InputMaybe<OrderBy>;
};

export type DiscountRulesWhereInput = {
	AND?: InputMaybe<Array<DiscountRulesWhereInput>>;
	OR?: InputMaybe<Array<DiscountRulesWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	duration?: InputMaybe<FloatFilter>;
	legacy_discount_code?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	type?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	value?: InputMaybe<StringFilter>;
};

export type EarningStatsDailyMembers = {
	__typename?: 'EarningStatsDailyMembers';
	/** The total count of earning records. */
	count?: Maybe<Scalars['Float']['output']>;
	/** The timestamp indicating when the earning statistics were recorded. */
	created_at?: Maybe<TimeDimension>;
	/** The unique identifier for the brand. */
	id?: Maybe<Scalars['String']['output']>;
	/** The total earnings calculated based on orders and subscriptions. */
	total?: Maybe<Scalars['Float']['output']>;
	/** The total earnings aggregated over the specified period. */
	total_earnings?: Maybe<Scalars['Float']['output']>;
};

export type EarningStatsDailyOrderByInput = {
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	total?: InputMaybe<OrderBy>;
	total_earnings?: InputMaybe<OrderBy>;
};

export type EarningStatsDailyWhereInput = {
	AND?: InputMaybe<Array<EarningStatsDailyWhereInput>>;
	OR?: InputMaybe<Array<EarningStatsDailyWhereInput>>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<StringFilter>;
	total?: InputMaybe<FloatFilter>;
	total_earnings?: InputMaybe<FloatFilter>;
};

export type EarningStatsMembers = {
	__typename?: 'EarningStatsMembers';
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	total?: Maybe<Scalars['Float']['output']>;
	total_earnings?: Maybe<Scalars['Float']['output']>;
};

export type EarningStatsOrderByInput = {
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	total?: InputMaybe<OrderBy>;
	total_earnings?: InputMaybe<OrderBy>;
};

export type EarningStatsWhereInput = {
	AND?: InputMaybe<Array<EarningStatsWhereInput>>;
	OR?: InputMaybe<Array<EarningStatsWhereInput>>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	total?: InputMaybe<FloatFilter>;
	total_earnings?: InputMaybe<FloatFilter>;
};

export type FilesMembers = {
	__typename?: 'FilesMembers';
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	id?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	type?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
	url?: Maybe<Scalars['String']['output']>;
};

export type FilesOrderByInput = {
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	type?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	url?: InputMaybe<OrderBy>;
};

export type FilesWhereInput = {
	AND?: InputMaybe<Array<FilesWhereInput>>;
	OR?: InputMaybe<Array<FilesWhereInput>>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<StringFilter>;
	name?: InputMaybe<StringFilter>;
	type?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	url?: InputMaybe<StringFilter>;
};

export type FloatFilter = {
	equals?: InputMaybe<Scalars['Float']['input']>;
	gt?: InputMaybe<Scalars['Float']['input']>;
	gte?: InputMaybe<Scalars['Float']['input']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
	lt?: InputMaybe<Scalars['Float']['input']>;
	lte?: InputMaybe<Scalars['Float']['input']>;
	notEquals?: InputMaybe<Scalars['Float']['input']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
	set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationsMembers = {
	__typename?: 'IntegrationsMembers';
	category?: Maybe<Scalars['String']['output']>;
	class_path?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	description?: Maybe<Scalars['String']['output']>;
	is_published?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	properties?: Maybe<Scalars['String']['output']>;
	summary?: Maybe<Scalars['String']['output']>;
	support_url?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type IntegrationsOrderByInput = {
	category?: InputMaybe<OrderBy>;
	class_path?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	description?: InputMaybe<OrderBy>;
	is_published?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	properties?: InputMaybe<OrderBy>;
	summary?: InputMaybe<OrderBy>;
	support_url?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type IntegrationsWhereInput = {
	AND?: InputMaybe<Array<IntegrationsWhereInput>>;
	OR?: InputMaybe<Array<IntegrationsWhereInput>>;
	category?: InputMaybe<StringFilter>;
	class_path?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	description?: InputMaybe<StringFilter>;
	is_published?: InputMaybe<StringFilter>;
	name?: InputMaybe<StringFilter>;
	properties?: InputMaybe<StringFilter>;
	summary?: InputMaybe<StringFilter>;
	support_url?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type InvoicesMembers = {
	__typename?: 'InvoicesMembers';
	hosted_invoice_url?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	invoice_pdf?: Maybe<Scalars['String']['output']>;
};

export type InvoicesOrderByInput = {
	hosted_invoice_url?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	invoice_pdf?: InputMaybe<OrderBy>;
};

export type InvoicesWhereInput = {
	AND?: InputMaybe<Array<InvoicesWhereInput>>;
	OR?: InputMaybe<Array<InvoicesWhereInput>>;
	hosted_invoice_url?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	invoice_pdf?: InputMaybe<StringFilter>;
};

export type KbCardsMembers = {
	__typename?: 'KbCardsMembers';
	author_id?: Maybe<Scalars['String']['output']>;
	brand_id?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	id?: Maybe<Scalars['String']['output']>;
	legacy_migrated?: Maybe<Scalars['String']['output']>;
	source?: Maybe<Scalars['String']['output']>;
	status?: Maybe<Scalars['String']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
	weight?: Maybe<Scalars['Float']['output']>;
};

export type KbCardsOrderByInput = {
	author_id?: InputMaybe<OrderBy>;
	brand_id?: InputMaybe<OrderBy>;
	content?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	legacy_migrated?: InputMaybe<OrderBy>;
	source?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	title?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	weight?: InputMaybe<OrderBy>;
};

export type KbCardsWhereInput = {
	AND?: InputMaybe<Array<KbCardsWhereInput>>;
	OR?: InputMaybe<Array<KbCardsWhereInput>>;
	author_id?: InputMaybe<StringFilter>;
	brand_id?: InputMaybe<StringFilter>;
	content?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<StringFilter>;
	legacy_migrated?: InputMaybe<StringFilter>;
	source?: InputMaybe<StringFilter>;
	status?: InputMaybe<StringFilter>;
	title?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	weight?: InputMaybe<FloatFilter>;
};

export type LeadsMembers = {
	__typename?: 'LeadsMembers';
	billing_email?: Maybe<Scalars['String']['output']>;
	brand_id?: Maybe<Scalars['String']['output']>;
	company_name?: Maybe<Scalars['String']['output']>;
	company_website?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	email?: Maybe<Scalars['String']['output']>;
	extra_data?: Maybe<Scalars['String']['output']>;
	first_name?: Maybe<Scalars['String']['output']>;
	full_name?: Maybe<Scalars['String']['output']>;
	last_name?: Maybe<Scalars['String']['output']>;
	source_name?: Maybe<Scalars['String']['output']>;
	source_type?: Maybe<Scalars['String']['output']>;
	store_type?: Maybe<Scalars['String']['output']>;
	store_url?: Maybe<Scalars['String']['output']>;
	support_email?: Maybe<Scalars['String']['output']>;
	support_phone?: Maybe<Scalars['String']['output']>;
	tenant_id?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
	user_id?: Maybe<Scalars['String']['output']>;
};

export type LeadsOrderByInput = {
	billing_email?: InputMaybe<OrderBy>;
	brand_id?: InputMaybe<OrderBy>;
	company_name?: InputMaybe<OrderBy>;
	company_website?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	email?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	first_name?: InputMaybe<OrderBy>;
	full_name?: InputMaybe<OrderBy>;
	last_name?: InputMaybe<OrderBy>;
	source_name?: InputMaybe<OrderBy>;
	source_type?: InputMaybe<OrderBy>;
	store_type?: InputMaybe<OrderBy>;
	store_url?: InputMaybe<OrderBy>;
	support_email?: InputMaybe<OrderBy>;
	support_phone?: InputMaybe<OrderBy>;
	tenant_id?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	user_id?: InputMaybe<OrderBy>;
};

export type LeadsWhereInput = {
	AND?: InputMaybe<Array<LeadsWhereInput>>;
	OR?: InputMaybe<Array<LeadsWhereInput>>;
	billing_email?: InputMaybe<StringFilter>;
	brand_id?: InputMaybe<StringFilter>;
	company_name?: InputMaybe<StringFilter>;
	company_website?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	email?: InputMaybe<StringFilter>;
	extra_data?: InputMaybe<StringFilter>;
	first_name?: InputMaybe<StringFilter>;
	full_name?: InputMaybe<StringFilter>;
	last_name?: InputMaybe<StringFilter>;
	source_name?: InputMaybe<StringFilter>;
	source_type?: InputMaybe<StringFilter>;
	store_type?: InputMaybe<StringFilter>;
	store_url?: InputMaybe<StringFilter>;
	support_email?: InputMaybe<StringFilter>;
	support_phone?: InputMaybe<StringFilter>;
	tenant_id?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	user_id?: InputMaybe<StringFilter>;
};

export type OrderBy = 'asc' | 'desc';

export type OrderConversationsMembers = {
	__typename?: 'OrderConversationsMembers';
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	order_id?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type OrderConversationsOrderByInput = {
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	order_id?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type OrderConversationsWhereInput = {
	AND?: InputMaybe<Array<OrderConversationsWhereInput>>;
	OR?: InputMaybe<Array<OrderConversationsWhereInput>>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	order_id?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrdersMembers = {
	__typename?: 'OrdersMembers';
	aov?: Maybe<Scalars['Float']['output']>;
	billing_status?: Maybe<Scalars['String']['output']>;
	brand_integration_id?: Maybe<Scalars['String']['output']>;
	cart_id?: Maybe<Scalars['String']['output']>;
	checkout_id?: Maybe<Scalars['String']['output']>;
	cleaned_raw_data?: Maybe<Scalars['String']['output']>;
	contact_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	currency_code?: Maybe<Scalars['String']['output']>;
	external_id?: Maybe<Scalars['String']['output']>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	number?: Maybe<Scalars['String']['output']>;
	recovered_count?: Maybe<Scalars['Float']['output']>;
	recovered_sales?: Maybe<Scalars['Float']['output']>;
	recovery_rate?: Maybe<Scalars['Float']['output']>;
	state?: Maybe<Scalars['String']['output']>;
	total?: Maybe<Scalars['String']['output']>;
	total_amount?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
	workflow_id?: Maybe<Scalars['String']['output']>;
};

export type OrdersOrderByInput = {
	aov?: InputMaybe<OrderBy>;
	billing_status?: InputMaybe<OrderBy>;
	brand_integration_id?: InputMaybe<OrderBy>;
	cart_id?: InputMaybe<OrderBy>;
	checkout_id?: InputMaybe<OrderBy>;
	cleaned_raw_data?: InputMaybe<OrderBy>;
	contact_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	currency_code?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	number?: InputMaybe<OrderBy>;
	recovered_count?: InputMaybe<OrderBy>;
	recovered_sales?: InputMaybe<OrderBy>;
	recovery_rate?: InputMaybe<OrderBy>;
	state?: InputMaybe<OrderBy>;
	total?: InputMaybe<OrderBy>;
	total_amount?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	workflow_id?: InputMaybe<OrderBy>;
};

export type OrdersWhereInput = {
	AND?: InputMaybe<Array<OrdersWhereInput>>;
	OR?: InputMaybe<Array<OrdersWhereInput>>;
	aov?: InputMaybe<FloatFilter>;
	billing_status?: InputMaybe<StringFilter>;
	brand_integration_id?: InputMaybe<StringFilter>;
	cart_id?: InputMaybe<StringFilter>;
	checkout_id?: InputMaybe<StringFilter>;
	cleaned_raw_data?: InputMaybe<StringFilter>;
	contact_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	currency_code?: InputMaybe<StringFilter>;
	external_id?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	number?: InputMaybe<StringFilter>;
	recovered_count?: InputMaybe<FloatFilter>;
	recovered_sales?: InputMaybe<FloatFilter>;
	recovery_rate?: InputMaybe<FloatFilter>;
	state?: InputMaybe<StringFilter>;
	total?: InputMaybe<StringFilter>;
	total_amount?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	workflow_id?: InputMaybe<StringFilter>;
};

export type PlansMembers = {
	__typename?: 'PlansMembers';
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	description?: Maybe<Scalars['String']['output']>;
	extra_data?: Maybe<Scalars['String']['output']>;
	frequency?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	price?: Maybe<Scalars['String']['output']>;
	status?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
	usage_charge_percentage?: Maybe<Scalars['String']['output']>;
};

export type PlansOrderByInput = {
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	description?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	frequency?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	price?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	usage_charge_percentage?: InputMaybe<OrderBy>;
};

export type PlansWhereInput = {
	AND?: InputMaybe<Array<PlansWhereInput>>;
	OR?: InputMaybe<Array<PlansWhereInput>>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	description?: InputMaybe<StringFilter>;
	extra_data?: InputMaybe<StringFilter>;
	frequency?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	name?: InputMaybe<StringFilter>;
	price?: InputMaybe<StringFilter>;
	status?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	usage_charge_percentage?: InputMaybe<StringFilter>;
};

export type ProductsMembers = {
	__typename?: 'ProductsMembers';
	brand_integration_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	legacy_migrated_at?: Maybe<TimeDimension>;
	original_title?: Maybe<Scalars['String']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type ProductsOrderByInput = {
	brand_integration_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	original_title?: InputMaybe<OrderBy>;
	title?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type ProductsWhereInput = {
	AND?: InputMaybe<Array<ProductsWhereInput>>;
	OR?: InputMaybe<Array<ProductsWhereInput>>;
	brand_integration_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	original_title?: InputMaybe<StringFilter>;
	title?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type Query = {
	__typename?: 'Query';
	cube: Array<Result>;
};

export type QueryCubeArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	renewQuery?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	ungrouped?: InputMaybe<Scalars['Boolean']['input']>;
	where?: InputMaybe<RootWhereInput>;
};

export type Result = {
	__typename?: 'Result';
	account_permissions: AccountPermissionsMembers;
	brand_audit_log: BrandAuditLogMembers;
	brand_integrations: BrandIntegrationsMembers;
	brand_stats: BrandStatsMembers;
	brand_subscription: BrandSubscriptionMembers;
	brands: BrandsMembers;
	carts: CartsMembers;
	checkouts: CheckoutsMembers;
	contact_channels: ContactChannelsMembers;
	contacts: ContactsMembers;
	conversation_feedback: ConversationFeedbackMembers;
	conversation_files: ConversationFilesMembers;
	conversation_messages: ConversationMessagesMembers;
	conversations: ConversationsMembers;
	discount_codes: DiscountCodesMembers;
	discount_rules: DiscountRulesMembers;
	earning_stats: EarningStatsMembers;
	earning_stats_daily: EarningStatsDailyMembers;
	files: FilesMembers;
	integrations: IntegrationsMembers;
	invoices: InvoicesMembers;
	kb_cards: KbCardsMembers;
	leads: LeadsMembers;
	order_conversations: OrderConversationsMembers;
	orders: OrdersMembers;
	plans: PlansMembers;
	products: ProductsMembers;
	subscription_charges: SubscriptionChargesMembers;
	subscription_payment_source: SubscriptionPaymentSourceMembers;
	subscriptions: SubscriptionsMembers;
	tenants: TenantsMembers;
	trusthub_rules: TrusthubRulesMembers;
	users: UsersMembers;
	warehouse_pages: WarehousePagesMembers;
	workflow_goal_state_change_events: WorkflowGoalStateChangeEventsMembers;
	workflow_goals: WorkflowGoalsMembers;
	workflows: WorkflowsMembers;
};

export type ResultAccount_PermissionsArgs = {
	orderBy?: InputMaybe<AccountPermissionsOrderByInput>;
	where?: InputMaybe<AccountPermissionsWhereInput>;
};

export type ResultBrand_Audit_LogArgs = {
	orderBy?: InputMaybe<BrandAuditLogOrderByInput>;
	where?: InputMaybe<BrandAuditLogWhereInput>;
};

export type ResultBrand_IntegrationsArgs = {
	orderBy?: InputMaybe<BrandIntegrationsOrderByInput>;
	where?: InputMaybe<BrandIntegrationsWhereInput>;
};

export type ResultBrand_StatsArgs = {
	orderBy?: InputMaybe<BrandStatsOrderByInput>;
	where?: InputMaybe<BrandStatsWhereInput>;
};

export type ResultBrand_SubscriptionArgs = {
	orderBy?: InputMaybe<BrandSubscriptionOrderByInput>;
	where?: InputMaybe<BrandSubscriptionWhereInput>;
};

export type ResultBrandsArgs = {
	orderBy?: InputMaybe<BrandsOrderByInput>;
	where?: InputMaybe<BrandsWhereInput>;
};

export type ResultCartsArgs = {
	orderBy?: InputMaybe<CartsOrderByInput>;
	where?: InputMaybe<CartsWhereInput>;
};

export type ResultCheckoutsArgs = {
	orderBy?: InputMaybe<CheckoutsOrderByInput>;
	where?: InputMaybe<CheckoutsWhereInput>;
};

export type ResultContact_ChannelsArgs = {
	orderBy?: InputMaybe<ContactChannelsOrderByInput>;
	where?: InputMaybe<ContactChannelsWhereInput>;
};

export type ResultContactsArgs = {
	orderBy?: InputMaybe<ContactsOrderByInput>;
	where?: InputMaybe<ContactsWhereInput>;
};

export type ResultConversation_FeedbackArgs = {
	orderBy?: InputMaybe<ConversationFeedbackOrderByInput>;
	where?: InputMaybe<ConversationFeedbackWhereInput>;
};

export type ResultConversation_FilesArgs = {
	orderBy?: InputMaybe<ConversationFilesOrderByInput>;
	where?: InputMaybe<ConversationFilesWhereInput>;
};

export type ResultConversation_MessagesArgs = {
	orderBy?: InputMaybe<ConversationMessagesOrderByInput>;
	where?: InputMaybe<ConversationMessagesWhereInput>;
};

export type ResultConversationsArgs = {
	orderBy?: InputMaybe<ConversationsOrderByInput>;
	where?: InputMaybe<ConversationsWhereInput>;
};

export type ResultDiscount_CodesArgs = {
	orderBy?: InputMaybe<DiscountCodesOrderByInput>;
	where?: InputMaybe<DiscountCodesWhereInput>;
};

export type ResultDiscount_RulesArgs = {
	orderBy?: InputMaybe<DiscountRulesOrderByInput>;
	where?: InputMaybe<DiscountRulesWhereInput>;
};

export type ResultEarning_StatsArgs = {
	orderBy?: InputMaybe<EarningStatsOrderByInput>;
	where?: InputMaybe<EarningStatsWhereInput>;
};

export type ResultEarning_Stats_DailyArgs = {
	orderBy?: InputMaybe<EarningStatsDailyOrderByInput>;
	where?: InputMaybe<EarningStatsDailyWhereInput>;
};

export type ResultFilesArgs = {
	orderBy?: InputMaybe<FilesOrderByInput>;
	where?: InputMaybe<FilesWhereInput>;
};

export type ResultIntegrationsArgs = {
	orderBy?: InputMaybe<IntegrationsOrderByInput>;
	where?: InputMaybe<IntegrationsWhereInput>;
};

export type ResultInvoicesArgs = {
	orderBy?: InputMaybe<InvoicesOrderByInput>;
	where?: InputMaybe<InvoicesWhereInput>;
};

export type ResultKb_CardsArgs = {
	orderBy?: InputMaybe<KbCardsOrderByInput>;
	where?: InputMaybe<KbCardsWhereInput>;
};

export type ResultLeadsArgs = {
	orderBy?: InputMaybe<LeadsOrderByInput>;
	where?: InputMaybe<LeadsWhereInput>;
};

export type ResultOrder_ConversationsArgs = {
	orderBy?: InputMaybe<OrderConversationsOrderByInput>;
	where?: InputMaybe<OrderConversationsWhereInput>;
};

export type ResultOrdersArgs = {
	orderBy?: InputMaybe<OrdersOrderByInput>;
	where?: InputMaybe<OrdersWhereInput>;
};

export type ResultPlansArgs = {
	orderBy?: InputMaybe<PlansOrderByInput>;
	where?: InputMaybe<PlansWhereInput>;
};

export type ResultProductsArgs = {
	orderBy?: InputMaybe<ProductsOrderByInput>;
	where?: InputMaybe<ProductsWhereInput>;
};

export type ResultSubscription_ChargesArgs = {
	orderBy?: InputMaybe<SubscriptionChargesOrderByInput>;
	where?: InputMaybe<SubscriptionChargesWhereInput>;
};

export type ResultSubscription_Payment_SourceArgs = {
	orderBy?: InputMaybe<SubscriptionPaymentSourceOrderByInput>;
	where?: InputMaybe<SubscriptionPaymentSourceWhereInput>;
};

export type ResultSubscriptionsArgs = {
	orderBy?: InputMaybe<SubscriptionsOrderByInput>;
	where?: InputMaybe<SubscriptionsWhereInput>;
};

export type ResultTenantsArgs = {
	orderBy?: InputMaybe<TenantsOrderByInput>;
	where?: InputMaybe<TenantsWhereInput>;
};

export type ResultTrusthub_RulesArgs = {
	orderBy?: InputMaybe<TrusthubRulesOrderByInput>;
	where?: InputMaybe<TrusthubRulesWhereInput>;
};

export type ResultUsersArgs = {
	orderBy?: InputMaybe<UsersOrderByInput>;
	where?: InputMaybe<UsersWhereInput>;
};

export type ResultWarehouse_PagesArgs = {
	orderBy?: InputMaybe<WarehousePagesOrderByInput>;
	where?: InputMaybe<WarehousePagesWhereInput>;
};

export type ResultWorkflow_Goal_State_Change_EventsArgs = {
	orderBy?: InputMaybe<WorkflowGoalStateChangeEventsOrderByInput>;
	where?: InputMaybe<WorkflowGoalStateChangeEventsWhereInput>;
};

export type ResultWorkflow_GoalsArgs = {
	orderBy?: InputMaybe<WorkflowGoalsOrderByInput>;
	where?: InputMaybe<WorkflowGoalsWhereInput>;
};

export type ResultWorkflowsArgs = {
	orderBy?: InputMaybe<WorkflowsOrderByInput>;
	where?: InputMaybe<WorkflowsWhereInput>;
};

export type RootOrderByInput = {
	account_permissions?: InputMaybe<AccountPermissionsOrderByInput>;
	brand_audit_log?: InputMaybe<BrandAuditLogOrderByInput>;
	brand_integrations?: InputMaybe<BrandIntegrationsOrderByInput>;
	brand_stats?: InputMaybe<BrandStatsOrderByInput>;
	brand_subscription?: InputMaybe<BrandSubscriptionOrderByInput>;
	brands?: InputMaybe<BrandsOrderByInput>;
	carts?: InputMaybe<CartsOrderByInput>;
	checkouts?: InputMaybe<CheckoutsOrderByInput>;
	contact_channels?: InputMaybe<ContactChannelsOrderByInput>;
	contacts?: InputMaybe<ContactsOrderByInput>;
	conversation_feedback?: InputMaybe<ConversationFeedbackOrderByInput>;
	conversation_files?: InputMaybe<ConversationFilesOrderByInput>;
	conversation_messages?: InputMaybe<ConversationMessagesOrderByInput>;
	conversations?: InputMaybe<ConversationsOrderByInput>;
	discount_codes?: InputMaybe<DiscountCodesOrderByInput>;
	discount_rules?: InputMaybe<DiscountRulesOrderByInput>;
	earning_stats?: InputMaybe<EarningStatsOrderByInput>;
	earning_stats_daily?: InputMaybe<EarningStatsDailyOrderByInput>;
	files?: InputMaybe<FilesOrderByInput>;
	integrations?: InputMaybe<IntegrationsOrderByInput>;
	invoices?: InputMaybe<InvoicesOrderByInput>;
	kb_cards?: InputMaybe<KbCardsOrderByInput>;
	leads?: InputMaybe<LeadsOrderByInput>;
	order_conversations?: InputMaybe<OrderConversationsOrderByInput>;
	orders?: InputMaybe<OrdersOrderByInput>;
	plans?: InputMaybe<PlansOrderByInput>;
	products?: InputMaybe<ProductsOrderByInput>;
	subscription_charges?: InputMaybe<SubscriptionChargesOrderByInput>;
	subscription_payment_source?: InputMaybe<SubscriptionPaymentSourceOrderByInput>;
	subscriptions?: InputMaybe<SubscriptionsOrderByInput>;
	tenants?: InputMaybe<TenantsOrderByInput>;
	trusthub_rules?: InputMaybe<TrusthubRulesOrderByInput>;
	users?: InputMaybe<UsersOrderByInput>;
	warehouse_pages?: InputMaybe<WarehousePagesOrderByInput>;
	workflow_goal_state_change_events?: InputMaybe<WorkflowGoalStateChangeEventsOrderByInput>;
	workflow_goals?: InputMaybe<WorkflowGoalsOrderByInput>;
	workflows?: InputMaybe<WorkflowsOrderByInput>;
};

export type RootWhereInput = {
	AND?: InputMaybe<Array<RootWhereInput>>;
	OR?: InputMaybe<Array<RootWhereInput>>;
	account_permissions?: InputMaybe<AccountPermissionsWhereInput>;
	brand_audit_log?: InputMaybe<BrandAuditLogWhereInput>;
	brand_integrations?: InputMaybe<BrandIntegrationsWhereInput>;
	brand_stats?: InputMaybe<BrandStatsWhereInput>;
	brand_subscription?: InputMaybe<BrandSubscriptionWhereInput>;
	brands?: InputMaybe<BrandsWhereInput>;
	carts?: InputMaybe<CartsWhereInput>;
	checkouts?: InputMaybe<CheckoutsWhereInput>;
	contact_channels?: InputMaybe<ContactChannelsWhereInput>;
	contacts?: InputMaybe<ContactsWhereInput>;
	conversation_feedback?: InputMaybe<ConversationFeedbackWhereInput>;
	conversation_files?: InputMaybe<ConversationFilesWhereInput>;
	conversation_messages?: InputMaybe<ConversationMessagesWhereInput>;
	conversations?: InputMaybe<ConversationsWhereInput>;
	discount_codes?: InputMaybe<DiscountCodesWhereInput>;
	discount_rules?: InputMaybe<DiscountRulesWhereInput>;
	earning_stats?: InputMaybe<EarningStatsWhereInput>;
	earning_stats_daily?: InputMaybe<EarningStatsDailyWhereInput>;
	files?: InputMaybe<FilesWhereInput>;
	integrations?: InputMaybe<IntegrationsWhereInput>;
	invoices?: InputMaybe<InvoicesWhereInput>;
	kb_cards?: InputMaybe<KbCardsWhereInput>;
	leads?: InputMaybe<LeadsWhereInput>;
	order_conversations?: InputMaybe<OrderConversationsWhereInput>;
	orders?: InputMaybe<OrdersWhereInput>;
	plans?: InputMaybe<PlansWhereInput>;
	products?: InputMaybe<ProductsWhereInput>;
	subscription_charges?: InputMaybe<SubscriptionChargesWhereInput>;
	subscription_payment_source?: InputMaybe<SubscriptionPaymentSourceWhereInput>;
	subscriptions?: InputMaybe<SubscriptionsWhereInput>;
	tenants?: InputMaybe<TenantsWhereInput>;
	trusthub_rules?: InputMaybe<TrusthubRulesWhereInput>;
	users?: InputMaybe<UsersWhereInput>;
	warehouse_pages?: InputMaybe<WarehousePagesWhereInput>;
	workflow_goal_state_change_events?: InputMaybe<WorkflowGoalStateChangeEventsWhereInput>;
	workflow_goals?: InputMaybe<WorkflowGoalsWhereInput>;
	workflows?: InputMaybe<WorkflowsWhereInput>;
};

export type StringFilter = {
	contains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	endsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	equals?: InputMaybe<Scalars['String']['input']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notContains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notEndsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notEquals?: InputMaybe<Scalars['String']['input']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	notStartsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
	set?: InputMaybe<Scalars['Boolean']['input']>;
	startsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubscriptionChargesMembers = {
	__typename?: 'SubscriptionChargesMembers';
	amount?: Maybe<Scalars['Float']['output']>;
	brand_id?: Maybe<Scalars['String']['output']>;
	charge_collected_at?: Maybe<TimeDimension>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	earnings?: Maybe<Scalars['Float']['output']>;
	external_id?: Maybe<Scalars['String']['output']>;
	extra_data?: Maybe<Scalars['String']['output']>;
	invoice_pdf?: Maybe<Scalars['String']['output']>;
	legacy_id?: Maybe<Scalars['String']['output']>;
	plan_id?: Maybe<Scalars['String']['output']>;
	status?: Maybe<Scalars['String']['output']>;
	subscription_id?: Maybe<Scalars['String']['output']>;
	total_earnings_shopify?: Maybe<Scalars['Float']['output']>;
	total_earnings_stripe?: Maybe<Scalars['Float']['output']>;
	total_recurring_fees?: Maybe<Scalars['Float']['output']>;
	total_usage_fees?: Maybe<Scalars['Float']['output']>;
	type?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type SubscriptionChargesOrderByInput = {
	amount?: InputMaybe<OrderBy>;
	brand_id?: InputMaybe<OrderBy>;
	charge_collected_at?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	earnings?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	extra_data?: InputMaybe<OrderBy>;
	invoice_pdf?: InputMaybe<OrderBy>;
	legacy_id?: InputMaybe<OrderBy>;
	plan_id?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	subscription_id?: InputMaybe<OrderBy>;
	total_earnings_shopify?: InputMaybe<OrderBy>;
	total_earnings_stripe?: InputMaybe<OrderBy>;
	total_recurring_fees?: InputMaybe<OrderBy>;
	total_usage_fees?: InputMaybe<OrderBy>;
	type?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type SubscriptionChargesWhereInput = {
	AND?: InputMaybe<Array<SubscriptionChargesWhereInput>>;
	OR?: InputMaybe<Array<SubscriptionChargesWhereInput>>;
	amount?: InputMaybe<FloatFilter>;
	brand_id?: InputMaybe<StringFilter>;
	charge_collected_at?: InputMaybe<DateTimeFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	earnings?: InputMaybe<FloatFilter>;
	external_id?: InputMaybe<StringFilter>;
	extra_data?: InputMaybe<StringFilter>;
	invoice_pdf?: InputMaybe<StringFilter>;
	legacy_id?: InputMaybe<StringFilter>;
	plan_id?: InputMaybe<StringFilter>;
	status?: InputMaybe<StringFilter>;
	subscription_id?: InputMaybe<StringFilter>;
	total_earnings_shopify?: InputMaybe<FloatFilter>;
	total_earnings_stripe?: InputMaybe<FloatFilter>;
	total_recurring_fees?: InputMaybe<FloatFilter>;
	total_usage_fees?: InputMaybe<FloatFilter>;
	type?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionPaymentSourceMembers = {
	__typename?: 'SubscriptionPaymentSourceMembers';
	brand_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	external_id?: Maybe<Scalars['String']['output']>;
	gateway?: Maybe<Scalars['String']['output']>;
	gateway_account_id?: Maybe<Scalars['String']['output']>;
	legacy_id?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type SubscriptionPaymentSourceOrderByInput = {
	brand_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	gateway?: InputMaybe<OrderBy>;
	gateway_account_id?: InputMaybe<OrderBy>;
	legacy_id?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type SubscriptionPaymentSourceWhereInput = {
	AND?: InputMaybe<Array<SubscriptionPaymentSourceWhereInput>>;
	OR?: InputMaybe<Array<SubscriptionPaymentSourceWhereInput>>;
	brand_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	external_id?: InputMaybe<StringFilter>;
	gateway?: InputMaybe<StringFilter>;
	gateway_account_id?: InputMaybe<StringFilter>;
	legacy_id?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionsMembers = {
	__typename?: 'SubscriptionsMembers';
	activated_at?: Maybe<TimeDimension>;
	brand_id?: Maybe<Scalars['String']['output']>;
	checkout_expired_at?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	external_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	last_billed_at?: Maybe<TimeDimension>;
	legacy_id?: Maybe<Scalars['String']['output']>;
	payment_source_id?: Maybe<Scalars['String']['output']>;
	plan_id?: Maybe<Scalars['String']['output']>;
	started_at?: Maybe<TimeDimension>;
	status?: Maybe<Scalars['String']['output']>;
	total_charges?: Maybe<Scalars['Float']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type SubscriptionsOrderByInput = {
	activated_at?: InputMaybe<OrderBy>;
	brand_id?: InputMaybe<OrderBy>;
	checkout_expired_at?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	external_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	last_billed_at?: InputMaybe<OrderBy>;
	legacy_id?: InputMaybe<OrderBy>;
	payment_source_id?: InputMaybe<OrderBy>;
	plan_id?: InputMaybe<OrderBy>;
	started_at?: InputMaybe<OrderBy>;
	status?: InputMaybe<OrderBy>;
	total_charges?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type SubscriptionsWhereInput = {
	AND?: InputMaybe<Array<SubscriptionsWhereInput>>;
	OR?: InputMaybe<Array<SubscriptionsWhereInput>>;
	activated_at?: InputMaybe<DateTimeFilter>;
	brand_id?: InputMaybe<StringFilter>;
	checkout_expired_at?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	external_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	last_billed_at?: InputMaybe<DateTimeFilter>;
	legacy_id?: InputMaybe<StringFilter>;
	payment_source_id?: InputMaybe<StringFilter>;
	plan_id?: InputMaybe<StringFilter>;
	started_at?: InputMaybe<DateTimeFilter>;
	status?: InputMaybe<StringFilter>;
	total_charges?: InputMaybe<FloatFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type TenantsMembers = {
	__typename?: 'TenantsMembers';
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	email?: Maybe<Scalars['String']['output']>;
	hubspot_id?: Maybe<Scalars['String']['output']>;
	is_enabled?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	phone?: Maybe<Scalars['String']['output']>;
	type?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type TenantsOrderByInput = {
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	email?: InputMaybe<OrderBy>;
	hubspot_id?: InputMaybe<OrderBy>;
	is_enabled?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	phone?: InputMaybe<OrderBy>;
	type?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type TenantsWhereInput = {
	AND?: InputMaybe<Array<TenantsWhereInput>>;
	OR?: InputMaybe<Array<TenantsWhereInput>>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	email?: InputMaybe<StringFilter>;
	hubspot_id?: InputMaybe<StringFilter>;
	is_enabled?: InputMaybe<StringFilter>;
	name?: InputMaybe<StringFilter>;
	phone?: InputMaybe<StringFilter>;
	type?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type TimeDimension = {
	__typename?: 'TimeDimension';
	day: Scalars['DateTime']['output'];
	hour: Scalars['DateTime']['output'];
	minute: Scalars['DateTime']['output'];
	month: Scalars['DateTime']['output'];
	quarter: Scalars['DateTime']['output'];
	second: Scalars['DateTime']['output'];
	value: Scalars['DateTime']['output'];
	week: Scalars['DateTime']['output'];
	year: Scalars['DateTime']['output'];
};

export type TrusthubRulesMembers = {
	__typename?: 'TrusthubRulesMembers';
	brief_description?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	documentation_link?: Maybe<Scalars['String']['output']>;
	how_rule_works?: Maybe<Scalars['String']['output']>;
	locked?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type TrusthubRulesOrderByInput = {
	brief_description?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	documentation_link?: InputMaybe<OrderBy>;
	how_rule_works?: InputMaybe<OrderBy>;
	locked?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	title?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type TrusthubRulesWhereInput = {
	AND?: InputMaybe<Array<TrusthubRulesWhereInput>>;
	OR?: InputMaybe<Array<TrusthubRulesWhereInput>>;
	brief_description?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	documentation_link?: InputMaybe<StringFilter>;
	how_rule_works?: InputMaybe<StringFilter>;
	locked?: InputMaybe<StringFilter>;
	name?: InputMaybe<StringFilter>;
	title?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type UsersMembers = {
	__typename?: 'UsersMembers';
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	email?: Maybe<Scalars['String']['output']>;
	family_name?: Maybe<Scalars['String']['output']>;
	given_name?: Maybe<Scalars['String']['output']>;
	hubspot_id?: Maybe<Scalars['String']['output']>;
	id?: Maybe<Scalars['String']['output']>;
	is_enabled?: Maybe<Scalars['String']['output']>;
	is_staff?: Maybe<Scalars['String']['output']>;
	last_login?: Maybe<TimeDimension>;
	password?: Maybe<Scalars['String']['output']>;
	phone?: Maybe<Scalars['String']['output']>;
	role?: Maybe<Scalars['String']['output']>;
	state?: Maybe<Scalars['String']['output']>;
	tenant_id?: Maybe<Scalars['String']['output']>;
	updated_at?: Maybe<TimeDimension>;
};

export type UsersOrderByInput = {
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	email?: InputMaybe<OrderBy>;
	family_name?: InputMaybe<OrderBy>;
	given_name?: InputMaybe<OrderBy>;
	hubspot_id?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	is_enabled?: InputMaybe<OrderBy>;
	is_staff?: InputMaybe<OrderBy>;
	last_login?: InputMaybe<OrderBy>;
	password?: InputMaybe<OrderBy>;
	phone?: InputMaybe<OrderBy>;
	role?: InputMaybe<OrderBy>;
	state?: InputMaybe<OrderBy>;
	tenant_id?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type UsersWhereInput = {
	AND?: InputMaybe<Array<UsersWhereInput>>;
	OR?: InputMaybe<Array<UsersWhereInput>>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	email?: InputMaybe<StringFilter>;
	family_name?: InputMaybe<StringFilter>;
	given_name?: InputMaybe<StringFilter>;
	hubspot_id?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	is_enabled?: InputMaybe<StringFilter>;
	is_staff?: InputMaybe<StringFilter>;
	last_login?: InputMaybe<DateTimeFilter>;
	password?: InputMaybe<StringFilter>;
	phone?: InputMaybe<StringFilter>;
	role?: InputMaybe<StringFilter>;
	state?: InputMaybe<StringFilter>;
	tenant_id?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type WarehousePagesMembers = {
	__typename?: 'WarehousePagesMembers';
	anonymous_id?: Maybe<Scalars['String']['output']>;
	campaign_content?: Maybe<Scalars['String']['output']>;
	campaign_medium?: Maybe<Scalars['String']['output']>;
	campaign_name?: Maybe<Scalars['String']['output']>;
	campaign_source?: Maybe<Scalars['String']['output']>;
	category?: Maybe<Scalars['String']['output']>;
	context_location?: Maybe<Scalars['String']['output']>;
	context_referrer?: Maybe<Scalars['String']['output']>;
	context_user_agent?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	initial_referrer?: Maybe<Scalars['String']['output']>;
	initial_referring_domain?: Maybe<Scalars['String']['output']>;
	original_timestamp?: Maybe<TimeDimension>;
	path?: Maybe<Scalars['String']['output']>;
	received_at?: Maybe<TimeDimension>;
	referrer?: Maybe<Scalars['String']['output']>;
	referring_domain?: Maybe<Scalars['String']['output']>;
	screen_height?: Maybe<Scalars['Float']['output']>;
	screen_width?: Maybe<Scalars['Float']['output']>;
	sent_at?: Maybe<TimeDimension>;
	timestamp?: Maybe<TimeDimension>;
	title?: Maybe<Scalars['String']['output']>;
	unique_users?: Maybe<Scalars['Float']['output']>;
	unique_visitors?: Maybe<Scalars['Float']['output']>;
	url?: Maybe<Scalars['String']['output']>;
	user_id?: Maybe<Scalars['String']['output']>;
};

export type WarehousePagesOrderByInput = {
	anonymous_id?: InputMaybe<OrderBy>;
	campaign_content?: InputMaybe<OrderBy>;
	campaign_medium?: InputMaybe<OrderBy>;
	campaign_name?: InputMaybe<OrderBy>;
	campaign_source?: InputMaybe<OrderBy>;
	category?: InputMaybe<OrderBy>;
	context_location?: InputMaybe<OrderBy>;
	context_referrer?: InputMaybe<OrderBy>;
	context_user_agent?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	initial_referrer?: InputMaybe<OrderBy>;
	initial_referring_domain?: InputMaybe<OrderBy>;
	original_timestamp?: InputMaybe<OrderBy>;
	path?: InputMaybe<OrderBy>;
	received_at?: InputMaybe<OrderBy>;
	referrer?: InputMaybe<OrderBy>;
	referring_domain?: InputMaybe<OrderBy>;
	screen_height?: InputMaybe<OrderBy>;
	screen_width?: InputMaybe<OrderBy>;
	sent_at?: InputMaybe<OrderBy>;
	timestamp?: InputMaybe<OrderBy>;
	title?: InputMaybe<OrderBy>;
	unique_users?: InputMaybe<OrderBy>;
	unique_visitors?: InputMaybe<OrderBy>;
	url?: InputMaybe<OrderBy>;
	user_id?: InputMaybe<OrderBy>;
};

export type WarehousePagesWhereInput = {
	AND?: InputMaybe<Array<WarehousePagesWhereInput>>;
	OR?: InputMaybe<Array<WarehousePagesWhereInput>>;
	anonymous_id?: InputMaybe<StringFilter>;
	campaign_content?: InputMaybe<StringFilter>;
	campaign_medium?: InputMaybe<StringFilter>;
	campaign_name?: InputMaybe<StringFilter>;
	campaign_source?: InputMaybe<StringFilter>;
	category?: InputMaybe<StringFilter>;
	context_location?: InputMaybe<StringFilter>;
	context_referrer?: InputMaybe<StringFilter>;
	context_user_agent?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	initial_referrer?: InputMaybe<StringFilter>;
	initial_referring_domain?: InputMaybe<StringFilter>;
	original_timestamp?: InputMaybe<DateTimeFilter>;
	path?: InputMaybe<StringFilter>;
	received_at?: InputMaybe<DateTimeFilter>;
	referrer?: InputMaybe<StringFilter>;
	referring_domain?: InputMaybe<StringFilter>;
	screen_height?: InputMaybe<FloatFilter>;
	screen_width?: InputMaybe<FloatFilter>;
	sent_at?: InputMaybe<DateTimeFilter>;
	timestamp?: InputMaybe<DateTimeFilter>;
	title?: InputMaybe<StringFilter>;
	unique_users?: InputMaybe<FloatFilter>;
	unique_visitors?: InputMaybe<FloatFilter>;
	url?: InputMaybe<StringFilter>;
	user_id?: InputMaybe<StringFilter>;
};

export type WorkflowGoalStateChangeEventsMembers = {
	__typename?: 'WorkflowGoalStateChangeEventsMembers';
	checkout_id?: Maybe<Scalars['String']['output']>;
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	id?: Maybe<Scalars['String']['output']>;
	state?: Maybe<Scalars['String']['output']>;
	state_display?: Maybe<Scalars['String']['output']>;
	workflow_id?: Maybe<Scalars['String']['output']>;
};

export type WorkflowGoalStateChangeEventsOrderByInput = {
	checkout_id?: InputMaybe<OrderBy>;
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	state?: InputMaybe<OrderBy>;
	state_display?: InputMaybe<OrderBy>;
	workflow_id?: InputMaybe<OrderBy>;
};

export type WorkflowGoalStateChangeEventsWhereInput = {
	AND?: InputMaybe<Array<WorkflowGoalStateChangeEventsWhereInput>>;
	OR?: InputMaybe<Array<WorkflowGoalStateChangeEventsWhereInput>>;
	checkout_id?: InputMaybe<StringFilter>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<StringFilter>;
	state?: InputMaybe<StringFilter>;
	state_display?: InputMaybe<StringFilter>;
	workflow_id?: InputMaybe<StringFilter>;
};

export type WorkflowGoalsMembers = {
	__typename?: 'WorkflowGoalsMembers';
	attribution?: Maybe<Scalars['String']['output']>;
	brand_id?: Maybe<Scalars['String']['output']>;
	contact_id?: Maybe<Scalars['String']['output']>;
	conversation_id?: Maybe<Scalars['String']['output']>;
	count?: Maybe<Scalars['Float']['output']>;
	created_at?: Maybe<TimeDimension>;
	updated_at?: Maybe<TimeDimension>;
	workflow_id?: Maybe<Scalars['String']['output']>;
};

export type WorkflowGoalsOrderByInput = {
	attribution?: InputMaybe<OrderBy>;
	brand_id?: InputMaybe<OrderBy>;
	contact_id?: InputMaybe<OrderBy>;
	conversation_id?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
	workflow_id?: InputMaybe<OrderBy>;
};

export type WorkflowGoalsWhereInput = {
	AND?: InputMaybe<Array<WorkflowGoalsWhereInput>>;
	OR?: InputMaybe<Array<WorkflowGoalsWhereInput>>;
	attribution?: InputMaybe<StringFilter>;
	brand_id?: InputMaybe<StringFilter>;
	contact_id?: InputMaybe<StringFilter>;
	conversation_id?: InputMaybe<StringFilter>;
	count?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
	workflow_id?: InputMaybe<StringFilter>;
};

export type WorkflowsMembers = {
	__typename?: 'WorkflowsMembers';
	/** The action performed within the workflow. */
	action?: Maybe<Scalars['String']['output']>;
	/** The identifier for the associated brand integration. */
	brand_integration_id?: Maybe<Scalars['String']['output']>;
	/** The count of conversations associated with the workflow. */
	conversations_count?: Maybe<Scalars['Float']['output']>;
	/** The total count of workflows. */
	count?: Maybe<Scalars['Float']['output']>;
	/** The count of active (non-paused) workflows. */
	count_published?: Maybe<Scalars['Float']['output']>;
	/** The timestamp indicating when the workflow was created. */
	created_at?: Maybe<TimeDimension>;
	/** The identifier for the associated discount rule. */
	discount_rule_id?: Maybe<Scalars['String']['output']>;
	/** The timestamp indicating when the workflow ends. */
	ends_at?: Maybe<TimeDimension>;
	/** The overarching goal or objective of the workflow. */
	goal?: Maybe<Scalars['String']['output']>;
	/** The unique identifier for the workflow. */
	id?: Maybe<Scalars['String']['output']>;
	/** Indicates whether the workflow is currently paused. */
	is_paused?: Maybe<Scalars['String']['output']>;
	/** The timestamp indicating when the workflow was migrated to a legacy system. */
	legacy_migrated_at?: Maybe<TimeDimension>;
	/** A descriptive message related to the workflow. */
	message?: Maybe<Scalars['String']['output']>;
	/** The name or label assigned to the workflow. */
	name?: Maybe<Scalars['String']['output']>;
	/** The count of orders associated with the workflow. */
	orders_count?: Maybe<Scalars['Float']['output']>;
	/** The total monetary amount of orders associated with the workflow. */
	orders_total_amount?: Maybe<Scalars['Float']['output']>;
	/** The set of rules associated with the workflow. */
	rules?: Maybe<Scalars['String']['output']>;
	/** The timestamp indicating when the workflow starts. */
	starts_at?: Maybe<TimeDimension>;
	/** The timezone context in which the workflow operates. */
	timezone?: Maybe<Scalars['String']['output']>;
	/** The type or category of the workflow. */
	type?: Maybe<Scalars['String']['output']>;
	/** The timestamp indicating when the workflow was last updated. */
	updated_at?: Maybe<TimeDimension>;
};

export type WorkflowsOrderByInput = {
	action?: InputMaybe<OrderBy>;
	brand_integration_id?: InputMaybe<OrderBy>;
	conversations_count?: InputMaybe<OrderBy>;
	count?: InputMaybe<OrderBy>;
	count_published?: InputMaybe<OrderBy>;
	created_at?: InputMaybe<OrderBy>;
	discount_rule_id?: InputMaybe<OrderBy>;
	ends_at?: InputMaybe<OrderBy>;
	goal?: InputMaybe<OrderBy>;
	id?: InputMaybe<OrderBy>;
	is_paused?: InputMaybe<OrderBy>;
	legacy_migrated_at?: InputMaybe<OrderBy>;
	message?: InputMaybe<OrderBy>;
	name?: InputMaybe<OrderBy>;
	orders_count?: InputMaybe<OrderBy>;
	orders_total_amount?: InputMaybe<OrderBy>;
	rules?: InputMaybe<OrderBy>;
	starts_at?: InputMaybe<OrderBy>;
	timezone?: InputMaybe<OrderBy>;
	type?: InputMaybe<OrderBy>;
	updated_at?: InputMaybe<OrderBy>;
};

export type WorkflowsWhereInput = {
	AND?: InputMaybe<Array<WorkflowsWhereInput>>;
	OR?: InputMaybe<Array<WorkflowsWhereInput>>;
	action?: InputMaybe<StringFilter>;
	brand_integration_id?: InputMaybe<StringFilter>;
	conversations_count?: InputMaybe<FloatFilter>;
	count?: InputMaybe<FloatFilter>;
	count_published?: InputMaybe<FloatFilter>;
	created_at?: InputMaybe<DateTimeFilter>;
	discount_rule_id?: InputMaybe<StringFilter>;
	ends_at?: InputMaybe<DateTimeFilter>;
	goal?: InputMaybe<StringFilter>;
	id?: InputMaybe<StringFilter>;
	is_paused?: InputMaybe<StringFilter>;
	legacy_migrated_at?: InputMaybe<DateTimeFilter>;
	message?: InputMaybe<StringFilter>;
	name?: InputMaybe<StringFilter>;
	orders_count?: InputMaybe<FloatFilter>;
	orders_total_amount?: InputMaybe<FloatFilter>;
	rules?: InputMaybe<StringFilter>;
	starts_at?: InputMaybe<DateTimeFilter>;
	timezone?: InputMaybe<StringFilter>;
	type?: InputMaybe<StringFilter>;
	updated_at?: InputMaybe<DateTimeFilter>;
};

export type GetMemberListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMemberListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		users: {
			__typename?: 'UsersMembers';
			id?: string | null;
			given_name?: string | null;
			family_name?: string | null;
			email?: string | null;
			role?: string | null;
			state?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
	}>;
	meta: Array<{ __typename?: 'Result'; users: { __typename?: 'UsersMembers'; count?: number | null } }>;
};

export type GetPlanListQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlanListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		plans: {
			__typename?: 'PlansMembers';
			id?: string | null;
			name?: string | null;
			status?: string | null;
			price?: string | null;
			usage_charge_percentage?: string | null;
			description?: string | null;
		};
	}>;
};

export type GetActivePlansListQueryVariables = Exact<{ [key: string]: never }>;

export type GetActivePlansListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		plans: {
			__typename?: 'PlansMembers';
			id?: string | null;
			name?: string | null;
			status?: string | null;
			price?: string | null;
			usage_charge_percentage?: string | null;
			description?: string | null;
		};
	}>;
};

export type GetBrandListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		brands: {
			__typename?: 'BrandsMembers';
			id?: string | null;
			name?: string | null;
			domain?: string | null;
			tenant_id?: string | null;
			total_earnings?: number | null;
		};
	}>;
};

export type GetPlanStatsQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPlanStatsQuery = {
	__typename?: 'Query';
	data: Array<{
		__typename?: 'Result';
		brands: { __typename?: 'BrandsMembers'; count?: number | null };
		plans: { __typename?: 'PlansMembers'; id?: string | null; name?: string | null };
	}>;
};

export type GetAllBrandInfoListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	isOrderByStats?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	where?: InputMaybe<RootWhereInput>;
}>;

export type GetAllBrandInfoListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		brands: {
			__typename?: 'BrandsMembers';
			domain?: string | null;
			id?: string | null;
			lead_value?: number | null;
			industry?: string | null;
			is_enabled?: string | null;
			name?: string | null;
			tenant_id?: string | null;
			yearly_revenue?: number | null;
			created_at_utc?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		brand_subscription: {
			__typename?: 'BrandSubscriptionMembers';
			gateway?: string | null;
			plan_name?: string | null;
			status?: string | null;
			usage_charge_percentage?: number | null;
		};
		brand_stats?: {
			__typename?: 'BrandStatsMembers';
			total_channel?: number | null;
			total_conversation?: number | null;
			total_recovered?: number | null;
			total_recovered_amount?: number | null;
			total_recovered_orders?: number | null;
			total_recurring_charges?: number | null;
			total_sales_amount?: number | null;
			total_replied_conversation?: number | null;
			total_subscription_charges?: number | null;
			total_unsubscribed_channel?: number | null;
			total_usage_charges?: number | null;
		};
	}>;
};

export type GetBrandInfoQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
	renewQuery?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetBrandInfoQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		leads: {
			__typename?: 'LeadsMembers';
			source_name?: string | null;
			source_type?: string | null;
			store_type?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		brands: {
			__typename?: 'BrandsMembers';
			domain?: string | null;
			id?: string | null;
			lead_value?: number | null;
			industry?: string | null;
			is_enabled?: string | null;
			name?: string | null;
			tenant_id?: string | null;
			yearly_revenue?: number | null;
			created_at_utc?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		tenants: {
			__typename?: 'TenantsMembers';
			email?: string | null;
			name?: string | null;
			phone?: string | null;
			type?: string | null;
		};
		brand_subscription: {
			__typename?: 'BrandSubscriptionMembers';
			id?: string | null;
			plan_name?: string | null;
			status?: string | null;
			gateway?: string | null;
			usage_charge_percentage?: number | null;
		};
	}>;
};

export type GetAllBrandStatsListQueryVariables = Exact<{
	ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type GetAllBrandStatsListQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		brands: {
			__typename?: 'BrandsMembers';
			id?: string | null;
			aov?: number | null;
			created_at_utc?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		brand_stats: {
			__typename?: 'BrandStatsMembers';
			total_channel?: number | null;
			total_conversation?: number | null;
			total_recovered?: number | null;
			total_recovered_amount?: number | null;
			total_recovered_orders?: number | null;
			total_recurring_charges?: number | null;
			total_sales_amount?: number | null;
			total_replied_conversation?: number | null;
			total_subscription_charges?: number | null;
			total_unsubscribed_channel?: number | null;
			total_usage_charges?: number | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		brands: { __typename?: 'BrandsMembers'; id?: string | null; prev_aov?: number | null };
		brand_stats: {
			__typename?: 'BrandStatsMembers';
			prev_total_conversation?: number | null;
			prev_total_recovered?: number | null;
			prev_total_recovered_amount?: number | null;
			prev_total_replied_conversation?: number | null;
			prev_total_channel?: number | null;
			prev_total_unsubscribed_channel?: number | null;
			prev_total_sales_amount?: number | null;
		};
	}>;
};

export type GetDailyStatListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	where?: InputMaybe<RootWhereInput>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDailyStatListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		brand_stats: {
			__typename?: 'BrandStatsMembers';
			total_subscription_charges?: number | null;
			total_unsubscribed_channel?: number | null;
			total_usage_charges?: number | null;
			total_sales?: number | null;
			total_sales_amount?: number | null;
			total_channel?: number | null;
			total_conversation?: number | null;
			total_recovered?: number | null;
			total_recovered_amount?: number | null;
			total_recovered_orders?: number | null;
			total_recurring_charges?: number | null;
			total_replied_conversation?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetBrandInvoiceListQueryVariables = Exact<{
	brandId?: InputMaybe<Scalars['String']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	invoiceId?: InputMaybe<Scalars['String']['input']>;
	gateway?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandInvoiceListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			earnings?: number | null;
			type?: string | null;
			status?: string | null;
			invoice_pdf?: string | null;
			created_at?: { __typename?: 'TimeDimension'; day: any } | null;
		};
		subscription_payment_source: { __typename?: 'SubscriptionPaymentSourceMembers'; gateway?: string | null };
		plans: { __typename?: 'PlansMembers'; id?: string | null; name?: string | null };
		subscriptions: { __typename?: 'SubscriptionsMembers'; status?: string | null };
	}>;
	meta: Array<{
		__typename?: 'Result';
		subscription_charges: { __typename?: 'SubscriptionChargesMembers'; count?: number | null };
	}>;
};

export type GetBrandInvoiceGroupedListQueryVariables = Exact<{
	brandId?: InputMaybe<Scalars['String']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandInvoiceGroupedListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		plans: { __typename?: 'PlansMembers'; id?: string | null; name?: string | null };
		subscriptions: { __typename?: 'SubscriptionsMembers'; status?: string | null };
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			earnings?: number | null;
			invoice_pdf?: string | null;
			status?: string | null;
			charge_collected_at?: { __typename?: 'TimeDimension'; month: any } | null;
		};
		invoices: { __typename?: 'InvoicesMembers'; id?: string | null };
		subscription_payment_source: { __typename?: 'SubscriptionPaymentSourceMembers'; gateway?: string | null };
	}>;
	meta: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			invoice_pdf?: string | null;
			status?: string | null;
			count?: number | null;
			created_at?: { __typename?: 'TimeDimension'; month: any } | null;
		};
	}>;
};

export type GetBrandCheckoutListQueryVariables = Exact<{
	brandId?: InputMaybe<Scalars['String']['input']>;
	state?: InputMaybe<Scalars['String']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandCheckoutListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		brands: { __typename?: 'BrandsMembers'; id?: string | null; name?: string | null; domain?: string | null };
		checkouts: {
			__typename?: 'CheckoutsMembers';
			state?: string | null;
			skip_reason?: string | null;
			external_id?: string | null;
			total_price?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
			updated_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
	}>;
	meta: Array<{ __typename?: 'Result'; checkouts: { __typename?: 'CheckoutsMembers'; count?: number | null } }>;
};

export type GetCheckoutInfoTestQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCheckoutInfoTestQuery = {
	__typename?: 'Query';
	checkout: Array<{
		__typename?: 'Result';
		checkouts: { __typename?: 'CheckoutsMembers'; checkout_details?: string | null; extra_data?: string | null };
	}>;
};

export type GetCartInfoQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCartInfoQuery = {
	__typename?: 'Query';
	data: Array<{
		__typename?: 'Result';
		carts: { __typename?: 'CartsMembers'; id?: string | null; data?: string | null };
	}>;
};

export type GetConvoListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetConvoListQuery = {
	__typename?: 'Query';
	cube: Array<{ __typename?: 'Result'; conversations: { __typename?: 'ConversationsMembers'; id?: string | null } }>;
};

export type GetConvoCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetConvoCountQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; count?: number | null };
	}>;
};

export type GetConversationFeedbacksQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	orderBy?: InputMaybe<RootOrderByInput>;
	where?: InputMaybe<RootWhereInput>;
}>;

export type GetConversationFeedbacksQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		brands: { __typename?: 'BrandsMembers'; id?: string | null; name?: string | null; domain?: string | null };
		conversation_feedback: {
			__typename?: 'ConversationFeedbackMembers';
			id?: string | null;
			message_id?: string | null;
			rating?: number | null;
			comment?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		conversations: { __typename?: 'ConversationsMembers'; id?: string | null; front_id?: string | null };
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		contact_channels: { __typename?: 'ContactChannelsMembers'; username?: string | null };
	}>;
};

export type GetConvoThreadQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetConvoThreadQuery = {
	__typename?: 'Query';
	messages: Array<{
		__typename?: 'Result';
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			id?: string | null;
			body?: string | null;
			conversation_id?: string | null;
			direction?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
			sent_at?: { __typename?: 'TimeDimension'; value: any } | null;
			scheduled_for?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		conversation_feedback: { __typename?: 'ConversationFeedbackMembers'; id?: string | null };
	}>;
	events: Array<{
		__typename?: 'Result';
		workflow_goal_state_change_events: {
			__typename?: 'WorkflowGoalStateChangeEventsMembers';
			state?: string | null;
			state_display?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
	}>;
	files: Array<{
		__typename?: 'Result';
		conversation_files: { __typename?: 'ConversationFilesMembers'; message_id?: string | null };
		files: {
			__typename?: 'FilesMembers';
			id?: string | null;
			name?: string | null;
			url?: string | null;
			type?: string | null;
		};
	}>;
};

export type GetAllConvoQueryVariables = Exact<{
	brandId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAllConvoQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
			updated_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		contact_channels: { __typename?: 'ContactChannelsMembers'; username?: string | null };
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		brands: { __typename?: 'BrandsMembers'; name?: string | null };
		workflow_goal_state_change_events: {
			__typename?: 'WorkflowGoalStateChangeEventsMembers';
			state_display?: string | null;
		};
	}>;
};

export type GetConvoSingleQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetConvoSingleQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		contact_channels: { __typename?: 'ContactChannelsMembers'; username?: string | null };
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		brands: { __typename?: 'BrandsMembers'; name?: string | null };
		workflow_goal_state_change_events: {
			__typename?: 'WorkflowGoalStateChangeEventsMembers';
			state_display?: string | null;
		};
	}>;
};

export type GetConvoLatestQueryVariables = Exact<{ [key: string]: never }>;

export type GetConvoLatestQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		contact_channels: { __typename?: 'ContactChannelsMembers'; username?: string | null };
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		brands: { __typename?: 'BrandsMembers'; name?: string | null };
		workflow_goal_state_change_events: {
			__typename?: 'WorkflowGoalStateChangeEventsMembers';
			state_display?: string | null;
		};
	}>;
};

export type GetPrevAndNextQueryVariables = Exact<{
	datetime?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPrevAndNextQuery = {
	__typename?: 'Query';
	prev: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
	}>;
	next: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
	}>;
};

export type GetConversationListQueryVariables = Exact<{
	where?: InputMaybe<RootWhereInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetConversationListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			checkout_id?: string | null;
			cart_id?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
			updated_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		checkouts: { __typename?: 'CheckoutsMembers'; state?: string | null };
		contact_channels: { __typename?: 'ContactChannelsMembers'; username?: string | null };
	}>;
};

export type GetConversationMessageCountQueryVariables = Exact<{
	conversationIds?: InputMaybe<
		Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
	>;
}>;

export type GetConversationMessageCountQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			conversation_id?: string | null;
			count?: number | null;
		};
	}>;
};

export type GetConversationIdListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetConversationIdListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; id?: string | null };
		checkouts: { __typename?: 'CheckoutsMembers'; state?: string | null };
		contact_channels: { __typename?: 'ContactChannelsMembers'; username?: string | null };
	}>;
};

export type GetCheckoutInfoQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCheckoutInfoQuery = {
	__typename?: 'Query';
	checkout: Array<{
		__typename?: 'Result';
		checkouts: { __typename?: 'CheckoutsMembers'; checkout_details?: string | null };
	}>;
};

export type GetCartCountQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCartCountQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		checkouts: {
			__typename?: 'CheckoutsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		checkouts: {
			__typename?: 'CheckoutsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetClientFunnelStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetClientFunnelStatsQuery = {
	__typename?: 'Query';
	curr_conv: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; count?: number | null; reply_count?: number | null };
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			outgoing_count?: number | null;
			sent_message_count?: number | null;
			reply_count?: number | null;
			count?: number | null;
		};
	}>;
	prev_conv: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; count?: number | null; reply_count?: number | null };
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			outgoing_count?: number | null;
			sent_message_count?: number | null;
			reply_count?: number | null;
			count?: number | null;
		};
	}>;
	curr_ord: Array<{
		__typename?: 'Result';
		orders: { __typename?: 'OrdersMembers'; recovered_count?: number | null };
	}>;
	prev_ord: Array<{
		__typename?: 'Result';
		orders: { __typename?: 'OrdersMembers'; recovered_count?: number | null };
	}>;
};

export type GetFunnelStatsQueryVariables = Exact<{
	from_date?: InputMaybe<Scalars['String']['input']>;
	to_date?: InputMaybe<Scalars['String']['input']>;
	prev_from_date?: InputMaybe<Scalars['String']['input']>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFunnelStatsQuery = {
	__typename?: 'Query';
	curr_conversations: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; count?: number | null; reply_count?: number | null };
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			reply_count?: number | null;
			delivery_count?: number | null;
			count?: number | null;
		};
	}>;
	curr_orders: Array<{
		__typename?: 'Result';
		orders: { __typename?: 'OrdersMembers'; count?: number | null; recovered_count?: number | null };
		order_conversations: { __typename?: 'OrderConversationsMembers'; count?: number | null };
	}>;
	prev_conversations: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; count?: number | null; reply_count?: number | null };
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			reply_count?: number | null;
			delivery_count?: number | null;
			count?: number | null;
		};
	}>;
	prev_orders: Array<{
		__typename?: 'Result';
		orders: { __typename?: 'OrdersMembers'; count?: number | null; recovered_count?: number | null };
		order_conversations: { __typename?: 'OrderConversationsMembers'; count?: number | null };
	}>;
};

export type GetRevenueChartQueryVariables = Exact<{
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRevenueChartQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			total_amount?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			total_amount?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetEarningChartQueryVariables = Exact<{
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEarningChartQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			total_usage_fees?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			total_usage_fees?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetConversationChartQueryVariables = Exact<{
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	isHidden?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetConversationChartQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetOrderChartQueryVariables = Exact<{
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOrderChartQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetRecoveredSalesChartQueryVariables = Exact<{
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRecoveredSalesChartQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			total_amount?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			total_amount?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetAovChartQueryVariables = Exact<{
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAovChartQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			aov?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			aov?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetWorkflowEventListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkflowEventListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		workflow_goal_state_change_events: {
			__typename?: 'WorkflowGoalStateChangeEventsMembers';
			id?: string | null;
			state?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		workflows: { __typename?: 'WorkflowsMembers'; name?: string | null };
		checkouts: { __typename?: 'CheckoutsMembers'; state?: string | null; total_price?: string | null };
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			reply_count?: number | null;
			sent_count?: number | null;
		};
		conversation_messages: { __typename?: 'ConversationMessagesMembers'; count?: number | null };
	}>;
};

export type GetWorkflowOrderListQueryVariables = Exact<{
	dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkflowOrderListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: { __typename?: 'ConversationsMembers'; id?: string | null };
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		checkouts: { __typename?: 'CheckoutsMembers'; state?: string | null; total_price?: string | null };
	}>;
};

export type GetWorkflowConversationListQueryVariables = Exact<{
	dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkflowConversationListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			id?: string | null;
			updated_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
		contacts: { __typename?: 'ContactsMembers'; full_name?: string | null };
		checkouts: { __typename?: 'CheckoutsMembers'; state?: string | null; total_price?: string | null };
	}>;
};

export type GetEarningStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEarningStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		earning_stats_daily: {
			__typename?: 'EarningStatsDailyMembers';
			total_earnings?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		earning_stats_daily: {
			__typename?: 'EarningStatsDailyMembers';
			total_earnings?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetAovStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAovStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			aov?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			aov?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetSubsRevenueStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetSubsRevenueStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			total_recurring_fees?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			total_recurring_fees?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetTotalRevenueStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTotalRevenueStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			earnings?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		subscription_charges: {
			__typename?: 'SubscriptionChargesMembers';
			earnings?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetRecoveredSalesStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRecoveredSalesStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			recovered_sales?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			recovered_sales?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetTotalSalesStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTotalSalesStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			total_amount?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			total_amount?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetNewCustomerStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetNewCustomerStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		contacts: {
			__typename?: 'ContactsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		contacts: {
			__typename?: 'ContactsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetConversationStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isHidden?: InputMaybe<Scalars['String']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetConversationStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetDeliveryRateStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDeliveryRateStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			count?: number | null;
			delivery_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			count?: number | null;
			delivery_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetReplyCountStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetReplyCountStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			reply_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			reply_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetReplyRateStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetReplyRateStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			reply_count?: number | null;
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			reply_count?: number | null;
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetRecoveredOrderStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRecoveredOrderStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			recovered_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			recovered_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetAbandonedCheckoutStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAbandonedCheckoutStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		checkouts: {
			__typename?: 'CheckoutsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		checkouts: {
			__typename?: 'CheckoutsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetRecoveryRateStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRecoveryRateStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			count?: number | null;
			recovered_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			count?: number | null;
			recovered_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
		conversations: {
			__typename?: 'ConversationsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetUnsubscribeRateStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUnsubscribeRateStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		contact_channels: {
			__typename?: 'ContactChannelsMembers';
			count?: number | null;
			unsubscribe_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		contact_channels: {
			__typename?: 'ContactChannelsMembers';
			count?: number | null;
			unsubscribe_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetP2PSubscribersStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	charts?: InputMaybe<Scalars['Boolean']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetP2PSubscribersStatsQuery = {
	__typename?: 'Query';
	curr: Array<{
		__typename?: 'Result';
		contact_channels: {
			__typename?: 'ContactChannelsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev: Array<{
		__typename?: 'Result';
		contact_channels: {
			__typename?: 'ContactChannelsMembers';
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
};

export type GetIntegrationCoreStatsQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetIntegrationCoreStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		integrations: { __typename?: 'IntegrationsMembers'; is_published?: string | null };
	}>;
};

export type KbCardStatsQueryVariables = Exact<{ [key: string]: never }>;

export type KbCardStatsQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		kb_cards: {
			__typename?: 'KbCardsMembers';
			count?: number | null;
			created_at?: { __typename?: 'TimeDimension'; day: any } | null;
		};
	}>;
};

export type KbListQueryVariables = Exact<{ [key: string]: never }>;

export type KbListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		kb_cards: {
			__typename?: 'KbCardsMembers';
			id?: string | null;
			title?: string | null;
			content?: string | null;
			source?: string | null;
			status?: string | null;
			weight?: number | null;
			count?: number | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
	}>;
};

export type KbItemQueryVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>;
}>;

export type KbItemQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		kb_cards: {
			__typename?: 'KbCardsMembers';
			id?: string | null;
			title?: string | null;
			content?: string | null;
			source?: string | null;
			status?: string | null;
			created_at?: { __typename?: 'TimeDimension'; value: any } | null;
		};
	}>;
};

export type GetWorkflowListQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	brandId?: InputMaybe<Scalars['String']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkflowListQuery = {
	__typename?: 'Query';
	cube: Array<{
		__typename?: 'Result';
		workflows: {
			__typename?: 'WorkflowsMembers';
			id?: string | null;
			name?: string | null;
			is_paused?: string | null;
		};
	}>;
};

export type GetWorkflowStatsQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	workflowId?: InputMaybe<Scalars['String']['input']>;
	timezone?: InputMaybe<Scalars['String']['input']>;
	isHourly?: InputMaybe<Scalars['Boolean']['input']>;
	isDaily?: InputMaybe<Scalars['Boolean']['input']>;
	isWeekly?: InputMaybe<Scalars['Boolean']['input']>;
	isMonthly?: InputMaybe<Scalars['Boolean']['input']>;
	isYearly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetWorkflowStatsQuery = {
	__typename?: 'Query';
	events: Array<{
		__typename?: 'Result';
		workflow_goal_state_change_events: {
			__typename?: 'WorkflowGoalStateChangeEventsMembers';
			state_display?: string | null;
			count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	curr_conversation_messages: Array<{
		__typename?: 'Result';
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			sent_message_count?: number | null;
			reply_count?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev_conversation_messages: Array<{
		__typename?: 'Result';
		conversation_messages: {
			__typename?: 'ConversationMessagesMembers';
			sent_message_count?: number | null;
			reply_count?: number | null;
		};
	}>;
	curr_order: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			recovered_count?: number | null;
			recovered_sales?: number | null;
			created_at?: {
				__typename?: 'TimeDimension';
				hour?: any;
				day?: any;
				week?: any;
				month?: any;
				year?: any;
			} | null;
		};
	}>;
	prev_order: Array<{
		__typename?: 'Result';
		orders: { __typename?: 'OrdersMembers'; recovered_count?: number | null; recovered_sales?: number | null };
	}>;
};

export type GetWorkflowStatsListQueryVariables = Exact<{
	currDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	prevDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	workflowIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
	timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkflowStatsListQuery = {
	__typename?: 'Query';
	curr_conversations: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			workflow_id?: string | null;
			curr_conversation_count?: number | null;
			curr_reply_count?: number | null;
		};
	}>;
	curr_orders: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			workflow_id?: string | null;
			curr_order_count?: number | null;
			curr_aov?: number | null;
			curr_recovered_count?: number | null;
			curr_recovered_sales?: number | null;
		};
	}>;
	prev_conversations: Array<{
		__typename?: 'Result';
		conversations: {
			__typename?: 'ConversationsMembers';
			workflow_id?: string | null;
			prev_conversation_count?: number | null;
			prev_reply_count?: number | null;
		};
	}>;
	prev_orders: Array<{
		__typename?: 'Result';
		orders: {
			__typename?: 'OrdersMembers';
			workflow_id?: string | null;
			prev_order_count?: number | null;
			prev_aov?: number | null;
			prev_recovered_count?: number | null;
			prev_recovered_sales?: number | null;
		};
	}>;
};

export const GetMemberListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetMemberList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'users' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_staff' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'true',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'users' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'asc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'users' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'given_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'family_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'meta' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'users' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_staff' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'true',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'users' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetMemberListQuery, GetMemberListQueryVariables>;
export const GetPlanListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPlanList' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'plans' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'usage_charge_percentage' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetPlanListQuery, GetPlanListQueryVariables>;
export const GetActivePlansListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetActivePlansList' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'plans' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'published',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'price' },
														value: { kind: 'EnumValue', value: 'asc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'usage_charge_percentage' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetActivePlansListQuery, GetActivePlansListQueryVariables>;
export const GetBrandListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetBrandList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'name' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'contains' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'Variable',
																				name: { kind: 'Name', value: 'search' },
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'total_earnings' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'tenant_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_earnings' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetBrandListQuery, GetBrandListQueryVariables>;
export const GetPlanStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPlanStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'data' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscriptions' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'active',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'plans' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetPlanStatsQuery, GetPlanStatsQueryVariables>;
export const GetAllBrandInfoListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAllBrandInfoList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isOrderByStats' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootWhereInput' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'lead_value' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'industry' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'is_enabled' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'tenant_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'yearly_revenue' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at_utc' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brand_subscription' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'gateway' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'plan_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'usage_charge_percentage' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brand_stats' },
									directives: [
										{
											kind: 'Directive',
											name: { kind: 'Name', value: 'include' },
											arguments: [
												{
													kind: 'Argument',
													name: { kind: 'Name', value: 'if' },
													value: {
														kind: 'Variable',
														name: { kind: 'Name', value: 'isOrderByStats' },
													},
												},
											],
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_channel' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_conversation' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered_amount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered_orders' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recurring_charges' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_sales_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_replied_conversation' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_subscription_charges' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_unsubscribed_channel' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'total_usage_charges' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetAllBrandInfoListQuery, GetAllBrandInfoListQueryVariables>;
export const GetBrandInfoDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetBrandInfo' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'renewQuery' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'renewQuery' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'renewQuery' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'leads' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'source_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'source_type' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'store_type' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'lead_value' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'industry' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'is_enabled' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'tenant_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'yearly_revenue' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at_utc' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'tenants' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'phone' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brand_subscription' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'plan_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'gateway' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'usage_charge_percentage' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetBrandInfoQuery, GetBrandInfoQueryVariables>;
export const GetAllBrandStatsListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAllBrandStatsList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'ids' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brand_stats' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at_utc' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'aov' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brand_stats' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_channel' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_conversation' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered_amount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered_orders' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recurring_charges' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_sales_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_replied_conversation' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_subscription_charges' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_unsubscribed_channel' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'total_usage_charges' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'ids' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brand_stats' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_aov' },
												name: { kind: 'Name', value: 'aov' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brand_stats' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_conversation' },
												name: { kind: 'Name', value: 'total_conversation' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_recovered' },
												name: { kind: 'Name', value: 'total_recovered' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_recovered_amount' },
												name: { kind: 'Name', value: 'total_recovered_amount' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_replied_conversation' },
												name: { kind: 'Name', value: 'total_replied_conversation' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_channel' },
												name: { kind: 'Name', value: 'total_channel' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_unsubscribed_channel' },
												name: { kind: 'Name', value: 'total_unsubscribed_channel' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_total_sales_amount' },
												name: { kind: 'Name', value: 'total_sales_amount' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetAllBrandStatsListQuery, GetAllBrandStatsListQueryVariables>;
export const GetDailyStatListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetDailyStatList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootWhereInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brand_stats' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_subscription_charges' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_unsubscribed_channel' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'total_usage_charges' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_sales' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_sales_amount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_channel' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_conversation' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered_amount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recovered_orders' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recurring_charges' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'total_replied_conversation' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetDailyStatListQuery, GetDailyStatListQueryVariables>;
export const GetBrandInvoiceListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetBrandInvoiceList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'gateway' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'brand_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'AND' },
														value: {
															kind: 'ListValue',
															values: [
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'status' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'notEquals',
																						},
																						value: {
																							kind: 'StringValue',
																							value: 'in_review',
																							block: false,
																						},
																					},
																				],
																			},
																		},
																	],
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'invoices' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'invoiceId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_payment_source' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'gateway' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'gateway' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'earnings' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'day' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'invoice_pdf' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_payment_source' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gateway' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'plans' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'meta' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'brand_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'AND' },
														value: {
															kind: 'ListValue',
															values: [
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'status' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'notEquals',
																						},
																						value: {
																							kind: 'StringValue',
																							value: 'in_review',
																							block: false,
																						},
																					},
																				],
																			},
																		},
																	],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetBrandInvoiceListQuery, GetBrandInvoiceListQueryVariables>;
export const GetBrandInvoiceGroupedListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetBrandInvoiceGroupedList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'brand_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'StringValue',
																				value: 'pending',
																				block: false,
																			},
																			{
																				kind: 'StringValue',
																				value: 'complete',
																				block: false,
																			},
																		],
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'AND' },
														value: {
															kind: 'ListValue',
															values: [
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'status' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'notEquals',
																						},
																						value: {
																							kind: 'StringValue',
																							value: 'in_review',
																							block: false,
																						},
																					},
																				],
																			},
																		},
																	],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'plans' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'earnings' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'invoice_pdf' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'charge_collected_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'month' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'invoices' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_payment_source' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gateway' } }],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'meta' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'brand_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'StringValue',
																				value: 'pending',
																				block: false,
																			},
																			{
																				kind: 'StringValue',
																				value: 'complete',
																				block: false,
																			},
																		],
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'AND' },
														value: {
															kind: 'ListValue',
															values: [
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'status' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'notEquals',
																						},
																						value: {
																							kind: 'StringValue',
																							value: 'in_review',
																							block: false,
																						},
																					},
																				],
																			},
																		},
																	],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'invoice_pdf' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'month' } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetBrandInvoiceGroupedListQuery, GetBrandInvoiceGroupedListQueryVariables>;
export const GetBrandCheckoutListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetBrandCheckoutList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'state' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'updated_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'skip_reason' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'external_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'updated_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'meta' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'state' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'updated_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetBrandCheckoutListQuery, GetBrandCheckoutListQueryVariables>;
export const GetCheckoutInfoTestDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetCheckoutInfoTest' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'checkout' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'checkout_details' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'extra_data' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetCheckoutInfoTestQuery, GetCheckoutInfoTestQueryVariables>;
export const GetCartInfoDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetCartInfo' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'data' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'carts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'carts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'data' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetCartInfoQuery, GetCartInfoQueryVariables>;
export const GetConvoListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConvoList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConvoListQuery, GetConvoListQueryVariables>;
export const GetConvoCountDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConvoCount' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConvoCountQuery, GetConvoCountQueryVariables>;
export const GetConversationFeedbacksDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConversationFeedbacks' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootOrderByInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootWhereInput' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_feedback' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'message_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'comment' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'front_id' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'username' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConversationFeedbacksQuery, GetConversationFeedbacksQueryVariables>;
export const GetConvoThreadDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'getConvoThread' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'messages' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'conversation_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'body' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'conversation_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sent_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'scheduled_for' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'direction' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_feedback' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'events' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'conversation_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'state_display' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'files' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_files' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'conversation_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_files' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message_id' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'files' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConvoThreadQuery, GetConvoThreadQueryVariables>;
export const GetAllConvoDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAllConvo' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'brand_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'updated_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'username' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state_display' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetAllConvoQuery, GetAllConvoQueryVariables>;
export const GetConvoSingleDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConvoSingle' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '1' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'username' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state_display' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConvoSingleQuery, GetConvoSingleQueryVariables>;
export const GetConvoLatestDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConvoLatest' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '1' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'username' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'brands' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state_display' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConvoLatestQuery, GetConvoLatestQueryVariables>;
export const GetPrevAndNextDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'getPrevAndNext' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetime' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'AND' },
														value: {
															kind: 'ListValue',
															values: [
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'created_at' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'beforeOrOnDate',
																						},
																						value: {
																							kind: 'Variable',
																							name: {
																								kind: 'Name',
																								value: 'datetime',
																							},
																						},
																					},
																				],
																			},
																		},
																	],
																},
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'id' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'notEquals',
																						},
																						value: {
																							kind: 'Variable',
																							name: {
																								kind: 'Name',
																								value: 'id',
																							},
																						},
																					},
																				],
																			},
																		},
																	],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '1' },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'asc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'asc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'next' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'AND' },
														value: {
															kind: 'ListValue',
															values: [
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'created_at' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'afterOrOnDate',
																						},
																						value: {
																							kind: 'Variable',
																							name: {
																								kind: 'Name',
																								value: 'datetime',
																							},
																						},
																					},
																				],
																			},
																		},
																	],
																},
																{
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: 'id' },
																			value: {
																				kind: 'ObjectValue',
																				fields: [
																					{
																						kind: 'ObjectField',
																						name: {
																							kind: 'Name',
																							value: 'notEquals',
																						},
																						value: {
																							kind: 'Variable',
																							name: {
																								kind: 'Name',
																								value: 'id',
																							},
																						},
																					},
																				],
																			},
																		},
																	],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '1' },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetPrevAndNextQuery, GetPrevAndNextQueryVariables>;
export const GetConversationListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConversationList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RootWhereInput' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'updated_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'updated_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'checkout_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'cart_id' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'username' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConversationListQuery, GetConversationListQueryVariables>;
export const GetConversationMessageCountDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConversationMessageCount' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'conversationIds' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'conversation_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: {
																			kind: 'Name',
																			value: 'conversationIds',
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'conversation_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConversationMessageCountQuery, GetConversationMessageCountQueryVariables>;
export const GetConversationIdListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConversationIDList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'updated_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'username' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConversationIdListQuery, GetConversationIdListQueryVariables>;
export const GetCheckoutInfoDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetCheckoutInfo' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'checkout' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'checkout_details' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetCheckoutInfoQuery, GetCheckoutInfoQueryVariables>;
export const GetCartCountDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetCartCount' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetCartCountQuery, GetCartCountQueryVariables>;
export const GetClientFunnelStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetClientFunnelStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_conv' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'outgoing_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'sent_message_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_conv' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'outgoing_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'sent_message_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_ord' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_ord' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetClientFunnelStatsQuery, GetClientFunnelStatsQueryVariables>;
export const GetFunnelStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetFunnelStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'from_date' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'to_date' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prev_from_date' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_conversations' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'from_date',
																				},
																			},
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'to_date',
																				},
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'delivery_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_orders' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'from_date',
																				},
																			},
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'to_date',
																				},
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_conversations' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'prev_from_date',
																				},
																			},
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'from_date',
																				},
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'delivery_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_orders' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'prev_from_date',
																				},
																			},
																			{
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'from_date',
																				},
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetFunnelStatsQuery, GetFunnelStatsQueryVariables>;
export const GetRevenueChartDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetRevenueChart' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetRevenueChartQuery, GetRevenueChartQueryVariables>;
export const GetEarningChartDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetEarningChart' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_usage_fees' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_usage_fees' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetEarningChartQuery, GetEarningChartQueryVariables>;
export const GetConversationChartDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConversationChart' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHidden' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'false', block: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_hidden' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'isHidden' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_hidden' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'isHidden' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConversationChartQuery, GetConversationChartQueryVariables>;
export const GetOrderChartDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetOrderChart' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetOrderChartQuery, GetOrderChartQueryVariables>;
export const GetRecoveredSalesChartDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetRecoveredSalesChart' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetRecoveredSalesChartQuery, GetRecoveredSalesChartQueryVariables>;
export const GetAovChartDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAOVChart' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'aov' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'aov' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetAovChartQuery, GetAovChartQueryVariables>;
export const GetWorkflowEventListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetWorkflowEventList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'StringValue',
																				value: 'last 3 days',
																				block: false,
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'ListValue',
																		values: [
																			{
																				kind: 'StringValue',
																				value: 'last 3 days',
																				block: false,
																			},
																		],
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'orderBy' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: { kind: 'EnumValue', value: 'desc' },
													},
												],
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflows' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'sent_count' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWorkflowEventListQuery, GetWorkflowEventListQueryVariables>;
export const GetWorkflowOrderListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetWorkflowOrderList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWorkflowOrderListQuery, GetWorkflowOrderListQueryVariables>;
export const GetWorkflowConversationListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetWorkflowConversationList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'dateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'updated_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'full_name' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWorkflowConversationListQuery, GetWorkflowConversationListQueryVariables>;
export const GetEarningStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetEarningStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'earning_stats_daily' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'earning_stats_daily' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_earnings' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'earning_stats_daily' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'earning_stats_daily' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_earnings' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetEarningStatsQuery, GetEarningStatsQueryVariables>;
export const GetAovStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAOVStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'recovered',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'aov' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'recovered',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'aov' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetAovStatsQuery, GetAovStatsQueryVariables>;
export const GetSubsRevenueStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetSubsRevenueStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'complete',
																		block: false,
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recurring_fees' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'complete',
																		block: false,
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_recurring_fees' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetSubsRevenueStatsQuery, GetSubsRevenueStatsQueryVariables>;
export const GetTotalRevenueStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetTotalRevenueStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'complete',
																		block: false,
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'earnings' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'subscription_charges' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'status' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'complete',
																		block: false,
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscription_charges' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'earnings' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetTotalRevenueStatsQuery, GetTotalRevenueStatsQueryVariables>;
export const GetRecoveredSalesStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetRecoveredSalesStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'recovered',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'recovered_sales' },
												name: { kind: 'Name', value: 'total_amount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'recovered',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'recovered_sales' },
												name: { kind: 'Name', value: 'total_amount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetRecoveredSalesStatsQuery, GetRecoveredSalesStatsQueryVariables>;
export const GetTotalSalesStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetTotalSalesStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total_amount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetTotalSalesStatsQuery, GetTotalSalesStatsQueryVariables>;
export const GetNewCustomerStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetNewCustomerStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'contacts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'contacts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contacts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetNewCustomerStatsQuery, GetNewCustomerStatsQueryVariables>;
export const GetConversationStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetConversationStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHidden' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'false', block: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_hidden' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'isHidden' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_hidden' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'isHidden' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetConversationStatsQuery, GetConversationStatsQueryVariables>;
export const GetDeliveryRateStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetDeliveryRateStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'delivery_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'delivery_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetDeliveryRateStatsQuery, GetDeliveryRateStatsQueryVariables>;
export const GetReplyCountStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetReplyCountStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetReplyCountStatsQuery, GetReplyCountStatsQueryVariables>;
export const GetReplyRateStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetReplyRateStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'count' },
												name: { kind: 'Name', value: 'sent_count' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'count' },
												name: { kind: 'Name', value: 'sent_count' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetReplyRateStatsQuery, GetReplyRateStatsQueryVariables>;
export const GetRecoveredOrderStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetRecoveredOrderStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'recovered',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'recovered_count' },
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'state' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'recovered',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'recovered_count' },
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetRecoveredOrderStatsQuery, GetRecoveredOrderStatsQueryVariables>;
export const GetAbandonedCheckoutStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAbandonedCheckoutStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'checkouts' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'checkouts' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetAbandonedCheckoutStatsQuery, GetAbandonedCheckoutStatsQueryVariables>;
export const GetRecoveryRateStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetRecoveryRateStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetRecoveryRateStatsQuery, GetRecoveryRateStatsQueryVariables>;
export const GetUnsubscribeRateStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetUnsubscribeRateStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'contact_channels' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'unsubscribe_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'contact_channels' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'unsubscribe_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetUnsubscribeRateStatsQuery, GetUnsubscribeRateStatsQueryVariables>;
export const GetP2PSubscribersStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetP2PSubscribersStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'charts' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'contact_channels' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'compliance_type' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'p2p',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'contact_channels' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'compliance_type' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'p2p',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'contact_channels' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												directives: [
													{
														kind: 'Directive',
														name: { kind: 'Name', value: 'include' },
														arguments: [
															{
																kind: 'Argument',
																name: { kind: 'Name', value: 'if' },
																value: {
																	kind: 'Variable',
																	name: { kind: 'Name', value: 'charts' },
																},
															},
														],
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetP2PSubscribersStatsQuery, GetP2PSubscribersStatsQueryVariables>;
export const GetIntegrationCoreStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetIntegrationCoreStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'integrations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'is_published' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetIntegrationCoreStatsQuery, GetIntegrationCoreStatsQueryVariables>;
export const KbCardStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'KBCardStats' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'kb_cards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'day' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<KbCardStatsQuery, KbCardStatsQueryVariables>;
export const KbListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'KBList' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '100' },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'orderBy' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'kb_cards' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'weight' },
														value: { kind: 'EnumValue', value: 'asc' },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'kb_cards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'content' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'source' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'weight' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<KbListQuery, KbListQueryVariables>;
export const KbItemDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'KBItem' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '1' },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'kb_cards' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'id' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'kb_cards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'content' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'source' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<KbItemQuery, KbItemQueryVariables>;
export const GetWorkflowListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetWorkflowList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'brandId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'brands' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'brandId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_paused' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'StringValue',
																		value: 'false',
																		block: false,
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflows' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'is_paused' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWorkflowListQuery, GetWorkflowListQueryVariables>;
export const GetWorkflowStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetWorkflowStats' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isHourly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDaily' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWeekly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'isYearly' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'events' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'workflow_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'workflow_goal_state_change_events' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'state_display' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_conversation_messages' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'sent_message_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_conversation_messages' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversation_messages' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversation_messages' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'sent_message_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reply_count' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_order' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'created_at' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hour' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isHourly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'day' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isDaily',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'week' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isWeekly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'month' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isMonthly',
																				},
																			},
																		},
																	],
																},
															],
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'year' },
															directives: [
																{
																	kind: 'Directive',
																	name: { kind: 'Name', value: 'include' },
																	arguments: [
																		{
																			kind: 'Argument',
																			name: { kind: 'Name', value: 'if' },
																			value: {
																				kind: 'Variable',
																				name: {
																					kind: 'Name',
																					value: 'isYearly',
																				},
																			},
																		},
																	],
																},
															],
														},
													],
												},
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_sales' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_order' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'equals' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowId' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_count' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'recovered_sales' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWorkflowStatsQuery, GetWorkflowStatsQueryVariables>;
export const GetWorkflowStatsListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetWorkflowStatsList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'currDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'prevDateRange' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowIds' } },
					type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_conversations' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowIds' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'workflow_id' } },
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'curr_conversation_count' },
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'curr_reply_count' },
												name: { kind: 'Name', value: 'reply_count' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'curr_orders' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowIds' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'currDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'workflow_id' } },
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'curr_order_count' },
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'curr_aov' },
												name: { kind: 'Name', value: 'aov' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'curr_recovered_count' },
												name: { kind: 'Name', value: 'recovered_count' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'curr_recovered_sales' },
												name: { kind: 'Name', value: 'recovered_sales' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_conversations' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowIds' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'conversations' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'conversations' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'workflow_id' } },
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_conversation_count' },
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_reply_count' },
												name: { kind: 'Name', value: 'reply_count' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'prev_orders' },
						name: { kind: 'Name', value: 'cube' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'timezone' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'workflows' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'in' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'workflowIds' },
																	},
																},
															],
														},
													},
												],
											},
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'orders' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'created_at' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'inDateRange' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'prevDateRange' },
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'orders' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'workflow_id' } },
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_order_count' },
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_aov' },
												name: { kind: 'Name', value: 'aov' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_recovered_count' },
												name: { kind: 'Name', value: 'recovered_count' },
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'prev_recovered_sales' },
												name: { kind: 'Name', value: 'recovered_sales' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWorkflowStatsListQuery, GetWorkflowStatsListQueryVariables>;
