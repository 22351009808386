import { random, sample } from 'lodash';

import type { DatabaseEntity } from '@voyage-lab/db';

const CACHE = new Map<string, string>();

export function generateCode(workflow?: DatabaseEntity['workflows']): string {
	let prefix = 'LR-PRE-';

	const discountConfig = workflow?.action?.discount?.config;

	const cacheKey = JSON.stringify({
		a: discountConfig?.code_prefix,
		b: discountConfig?.type,
		c: workflow?.id,
		d: workflow?.name,
	});

	if (CACHE.has(cacheKey)) {
		const cacheValue = CACHE.get(cacheKey);
		if (cacheValue) return cacheValue;
	}

	if (discountConfig?.type === 'custom' && discountConfig?.code_prefix) {
		prefix = `${discountConfig.code_prefix}-`;
	}

	if (discountConfig?.type === 'static' && discountConfig?.code_prefix) {
		prefix = discountConfig?.code_prefix;
		return prefix;
	}

	const chars = [
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'J',
		'K',
		'M',
		'N',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
	];
	let coupon = '';
	for (let i = 0; i < 3; i++) {
		coupon += sample(chars);
	}
	const randInt = random(100, 999, false);
	const value = prefix + coupon + randInt;

	CACHE.set(cacheKey, value);

	return value;
}

export function isIOS(userAgent: string) {
	// Convert the user-agent string to lowercase for case-insensitive matching
	const ua = userAgent.toLowerCase();
	// Check for iOS-specific substrings in the user-agent string
	return /iphone|ipad|ipod/.test(ua);
}

export function generateSmsUrl(phone: string, messageBody: string, isIos: boolean): string {
	const encodedBody = encodeURIComponent(messageBody); // URL-encode the body
	return isIos
		? `sms:${phone}&body=${encodedBody}` // iOS uses `&body=`
		: `sms:${phone}?body=${encodedBody}`; // Non-iOS uses `?body=`
}
