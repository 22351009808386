import type { LucideProps } from 'lucide-react';

export const Shining = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				d="M48.0996 30.7259C40.2477 29.9693 34.0346 23.7592 33.2789 15.9139L32.0039 2.66669L30.7289 15.9139C29.9732 23.7605 23.7601 29.9707 15.9082 30.7259L2.67056 32L15.9082 33.2742C23.7601 34.0307 29.9732 40.2409 30.7289 48.0862L32.0039 61.3334L33.2789 48.0862C34.0346 40.2395 40.2477 34.0294 48.0996 33.2742L61.3372 32L48.0996 30.7259Z"
				fill={color || 'currentColor'}
			/>
		</svg>
	);
};
