const idMapper = {
	order: 'Order',
	app_subscription: 'AppSubscription',
	line_item: 'LineItem',
} as const;

const isShopifyId = (id: string | number) => {
	return id.toString().includes('gid://shopify/');
};

export const toShopifyId = (id: string | number, type: keyof typeof idMapper) => {
	if (isShopifyId(id)) return id;
	return `gid://shopify/${idMapper[type]}/${id}`;
};

export const fromShopifyId = (id: string) => {
	return id.replace(/\D/g, '');
};
